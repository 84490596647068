import React, {FunctionComponent} from "react"
import "./HorecaPage.scss"
import {useTranslation} from "react-i18next"
import {Helmet} from "react-helmet"
import {BASE_URL} from "../_helpers/environment"
import i18next from "i18next"
import LocaleLink from "../_components/localization/LocaleLink"

const HorecaPage: FunctionComponent = () => {
    const {t} = useTranslation("common")
    return (
        <div id="horecaPage">
            <Helmet>
                <title>{t("horeca/title", {"defaultValue": "Matthys Wines: Excellence in wines"})}</title>
                <link rel="canonical" href={`${BASE_URL}/${i18next.language}/horeca`}/>
            </Helmet>
            <div className="horeca-banner">
                <div className="horeca-banner-overlay">
                    <div className="horeca-banner-body">
                        <h1>{t("horeca/title")}</h1>
                        <p>{t("horeca/lead")}</p>
                    </div>
                </div>
            </div>
            <div className="container grid-xl header-margin">
                <div className="columns">
                    <div className="column col-6 horeca-info col-sm-12">
                        <div className="horeca-promo-container bg-gray rounded">
                            <div className="horeca-promo">
                                <h2>{t("horeca/contactFormTitle")}</h2>
                                <p className="mb-2">{t("horeca/contactFormBody")}</p>
                                <LocaleLink to={"/horeca/form"}>{t("horeca/contactFormLink")}</LocaleLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HorecaPage