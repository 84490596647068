import React, {FC} from "react"
import {SchemaPerson} from "../../_types/types"
import FormInput from "../form-inputs/FormInput"
import FormSelectCountry from "../form-inputs/FormSelectCountry"
import {useTranslation} from "react-i18next"
import {replaceStreetName, replaceStreetNumber, splitAddress} from "../../_helpers/address-format"

interface Props {
    setPerson: (mod: (person: SchemaPerson) => SchemaPerson) => void
    person: SchemaPerson
}

const FormBilling: FC<Props> = ({setPerson, person}) => {
    const {t} = useTranslation("form")
    return (
        <div>
            <FormInput
                label={t("label/firstName")}
                type={"text"}
                id={"billingFirstName"}
                value={person.givenName}
                onChange={v => setPerson(s => ({...s, "givenName": v}))}
                required={true}
            />
            <FormInput
                label={t("label/lastName")}
                type={"text"}
                id={"billingLastName"}
                value={person.familyName}
                onChange={v => setPerson(s => ({...s, "familyName": v}))}
                required={true}
            />
            <FormInput
                label={t("label/company")}
                type={"text"}
                id={"billingCompany"}
                value={person.affiliation.name}
                onChange={v => setPerson(s => ({...s, "affiliation": {...s.affiliation, "name": v}}))}
            />
            <FormInput
                label={t("label/vat")}
                type={"text"}
                id={"billingVAT"}
                value={person.affiliation.vatID}
                onChange={v => setPerson(s => ({...s, "affiliation": {...s.affiliation, "vatID": v}}))}
            />
            <FormInput
                label={t("label/telephone")}
                type={"tel"}
                id={"billingTelephone"}
                value={person.telephone}
                onChange={v => setPerson(s => ({...s, "telephone": v}))}
                required={true}
            />
            <div className="columns">
                <div className="column col-8 col-md-12">
                    <FormInput
                        label={t("label/streetName")}
                        type={"text"}
                        id={"billingStreetName"}
                        value={splitAddress(person.address.streetAddress).streetName}
                        onChange={v => setPerson(s => ({
                            ...s,
                            "address": {...s.address, "streetAddress": replaceStreetName(s.address.streetAddress, v)},
                        }))}
                        required={true}
                    />
                </div>
                <div className="column col-4 col-md-12">
                    <FormInput
                        label={t("label/streetNumber")}
                        type={"text"}
                        id={"billingStreetNumber"}
                        value={splitAddress(person.address.streetAddress).streetNumber}
                        onChange={v => setPerson(s => ({
                            ...s,
                            "address": {...s.address, "streetAddress": replaceStreetNumber(s.address.streetAddress, v)},
                        }))}
                        required={true}
                    />
                </div>
            </div>
            <div className="columns">
                <div className="column col-6">
                    <FormInput
                        label={t("label/city")}
                        type={"text"}
                        id={"billingCity"}
                        value={person.address.addressLocality}
                        onChange={v => setPerson(s => ({...s, "address": {...s.address, "addressLocality": v}}))}
                        required={true}
                    />
                </div>
                <div className="column col-6">
                    <FormInput
                        label={t("label/postalCode")}
                        type={"text"}
                        id={"billingPostalCode"}
                        value={person.address.postalCode}
                        onChange={v => setPerson(s => ({...s, "address": {...s.address, "postalCode": v}}))}
                        required={true}
                    />
                </div>
            </div>
            <FormSelectCountry
                required={true}
                id={"billingCountry"}
                label={t("label/country")}
                value={person.address.addressCountry}
                onChange={v => setPerson(s => ({...s, "address": {...s.address, "addressCountry": v}}))}
            />
        </div>
    )
}

export default FormBilling