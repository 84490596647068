import React, {Component} from "react"
import * as Sentry from "@sentry/browser"

class ErrorBoundary extends Component<{ children?: React.ReactNode }, { hasError: boolean, eventId?: string }> {
    constructor(props: {}) {
        super(props)
        this.state = {"hasError": false}
    }

    static getDerivedStateFromError() {
        return {"hasError": true}
    }

    componentDidCatch(error: any, errorInfo: any) {
        Sentry.withScope((scope) => {
            scope.setExtras(errorInfo)
            const eventId = Sentry.captureException(error)
            this.setState({eventId})
        })
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{"display": "flex", "height": "100vh"}}>
                    <div className='container grid-sm' style={{"alignSelf": "center"}}>
                        <div className="h1">Something went wrong.</div>
                        <p>We could not load this page. We have been notified of this issue and will try to fix it
                            as soon as possible. You can help by describing what went wrong with the button below.</p>
                        <button
                            className="btn"
                            onClick={() => Sentry.showReportDialog({"eventId": this.state.eventId})}
                        >Report feedback
                        </button>
                    </div>
                </div>
            )
        }

        return this.props.children
    }
}

export default ErrorBoundary
