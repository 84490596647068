import React, {FC, useEffect, useState} from "react"
import "./Loader.scss"
import {ReactComponent as Bottle} from "./bottle.svg"

interface LoaderProps {
    delay?: number;
}

const Loader: FC<LoaderProps> = ({delay = 2000}) => {
    const [showLoader, setShowLoader] = useState(!delay)

    useEffect(() => {
        if (delay) {
            const timer = setTimeout(() => {
                setShowLoader(true)
            }, delay)
            return () => clearTimeout(timer)
        }
    }, [delay])

    if (!showLoader) {
        return (
            <div className="loader-container">
                <div className="loader-blank"/>
            </div>
        )
    }

    return (
        <div className="loader-container">
            <div className="loader-spinner loader-breathing"/>
            <div className="loader-body">
                <div className="loader-content">
                    <Bottle className={"loader-bottle"}/>
                </div>
            </div>
        </div>
    )
}

export default Loader