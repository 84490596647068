import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import {useAgriculture, useCategories, useCountries} from "../../_hooks/product.hook"
import {SchemaPropertyValue} from "../../_types/types"
import LocaleLink from "../../_components/localization/LocaleLink"

const orderMap: { [key: string]: number } = {
    "biodynamic": 4,
    "organic": 3,
    "organic_nc": 2,
    "conventional": 1,
}

type HomeShortcutProps = {
    count?: { [key: string]: number }
    wait?: boolean
}

export const SectionAgriculture: FC<HomeShortcutProps> = ({wait, count}) => {
    const {t} = useTranslation("common")
    const {list, busy} = useAgriculture()
    let agriculture = [...list]
    const idMap: { [key: string]: string } = {}
    if (count !== undefined) {
        const keyLength = Object.keys(count)[0].length
        agriculture = agriculture.filter(w => {
            const shortId = w["@id"].substring(w["@id"].length - keyLength)
            idMap[w["@id"]] = shortId
            return count[shortId] !== undefined
        })
    }
    agriculture = agriculture ? agriculture.sort((a: SchemaPropertyValue, b: SchemaPropertyValue) => {
        let as = 0
        if (a.identifier && orderMap[a.identifier])
            as = orderMap[a.identifier]
        let bs = 0
        if (b.identifier && orderMap[b.identifier])
            bs = orderMap[b.identifier]
        return (as >= bs) ? -1 : 1
    }) : []
    const fillers = []
    for (let i = 0; i < 4; i++) {
        fillers.push(<div key={i} className="btn-filler-agriculture"/>)
    }
    return (
        <section className="mt-2 home-wine-showcase">
            <div className="section-header">
                <h2>{t("home/agriculture")}</h2>
            </div>
            <div className="home-countries">
                {(!busy && !wait) ? agriculture.map((e) => {
                    const counter = (count !== undefined) ? count[idMap[e["@id"]]] : -1
                    return (
                        <LocaleLink
                            key={e.identifier}
                            to={`/wines?agriculture=${idMap[e["@id"]]}`}
                            className="btn btn-home btn-agriculture"
                        >
                            <span className={`btn-home-icon icon-agriculture-${e.identifier}`}/>
                            <span className="btn-home-text">
                                <span className={"btn-home-name"}>{e.value}</span><br/>
                                <span className={"btn-home-count"}>
                                    {counter} {counter === 1 ? t("home/wine", "wine") : t("home/wines", "wines")}
                                </span>
                            </span>
                        </LocaleLink>
                    )
                }).concat(fillers) : (Array.from(Array(3).keys()).map((key, index) => (
                    <div key={key} className="btn btn-country" style={{"animationDelay": `${0.1 * index}s`}}/>
                )))}
            </div>
        </section>
    )
}

type ShortcutWineStyleProps = { primary: boolean } & HomeShortcutProps
export const SectionWineStyle: FC<ShortcutWineStyleProps> = ({wait, count, primary}) => {
    const {t} = useTranslation("common")
    const {list, busy} = useCategories()
    let categories = [...list]
    const idMap: { [key: string]: string } = {}
    if (count !== undefined) {
        const keyLength = Object.keys(count)[0].length
        categories = categories.filter(w => {
            if (w.identifier === "olive-oil")
                return false
            const shortId = w["@id"].substring(w["@id"].length - keyLength)
            idMap[w["@id"]] = shortId
            return count[shortId] !== undefined
        })
    }
    categories.sort(((a, b) => a.order - b.order))
    const fillers = []
    for (let i = 0; i < 4; i++) {
        fillers.push(<div key={i} className="btn-filler-agriculture"/>)
    }
    return (
        <section className="mt-2 home-wine-showcase">
            <div className="section-header">
                <h2>{t("home/wineStyles")}</h2>
            </div>
            <div className="home-countries">
                {(!busy && !wait) ? categories
                    .map(e => {
                        const counter = (count !== undefined) ? count[idMap[e["@id"]]] : -1
                        return (
                            <LocaleLink
                                key={e["@id"]}
                                to={`/wines?${primary ? "wineTypes" : "wineStyles"}=${idMap[e["@id"]]}`}
                                className="btn btn-home btn-category"
                            >
                                <span
                                    className={`btn-home-icon icon-wine-${primary ? "types" : "categories"}-${e.identifier}`}/>
                                <span className="btn-home-text">
                                    <span className={"btn-home-name"}>{e.name}</span><br/>
                                    <span className={"btn-home-count"}>
                                    {counter} {counter === 1 ? t("home/wine", "wine") : t("home/wines", "wines")}
                                    </span>
                                </span>
                            </LocaleLink>
                        )
                    }).concat(fillers) : (Array.from(Array(36).keys()).map(key => (
                    <div
                        key={key}
                        className="btn btn-country"
                    />
                )))}
            </div>
        </section>
    )
}

export const SectionCountries: FC<HomeShortcutProps> = ({wait, count}) => {
    const {t} = useTranslation("common")
    const {list, busy} = useCountries()
    let countries = [...list]
    const idMap: { [key: string]: string } = {}
    if (count !== undefined) {
        const keyLength = Object.keys(count)[0].length
        countries = countries.filter(w => {
            const shortId = w["@id"].substring(w["@id"].length - keyLength)
            idMap[w["@id"]] = shortId
            return count[shortId] !== undefined
        })
    }
    countries.sort(((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
    const fillerTiles = []
    for (let i = 0; i < 8; i++) {
        fillerTiles.push(<div key={`filler-${i}`} className="btn-filler"/>)
    }
    return (
        <section>
            <div className="section-header">
                <h2>{t("home/countries")}</h2>
            </div>
            <div className="home-countries">
                {(!busy && !wait) ? countries?.map(e => {
                    const counter = (count !== undefined) ? count[idMap[e["@id"]]] : -1
                    return (
                        <LocaleLink
                            key={e.alternateName}
                            to={`/wines?countries=${idMap[e["@id"]]}`}
                            className="btn btn-home btn-country"
                        >
                            <span className={`btn-home-icon icon-countries-${e.identifier}`}/>
                            <span className="btn-home-text">
                                <span className={"btn-home-name"}>{e.name}</span><br/>
                                <span className={"btn-home-count"}>
                                    {counter} {counter === 1 ? t("home/wine", "wine") : t("home/wines", "wines")}
                                </span>
                            </span>
                        </LocaleLink>
                    )
                }).concat(fillerTiles) : (Array.from(Array(36).keys()).map((key, index) => (
                    <div key={key} className={`btn btn-country animation-delay-${index}`}/>
                )))}
            </div>
        </section>
    )
}
