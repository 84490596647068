import {useCallback, useEffect, useState} from "react"
import ky, {Options} from "ky"
import {API_URL} from "../_helpers/environment"
import {SchemaOrder, SchemaPerson, SchemaPostalAddress} from "../_types/types"
import {useAuth0} from "@auth0/auth0-react"
import {customHeader} from "../_helpers/auth-header"
import * as base64 from "base-64"

// Retrieves user billing address which can also be updated
export const useUserBillingAddress = () => {

    const {getAccessTokenSilently, isAuthenticated, isLoading, user} = useAuth0()
    const [error, setError] = useState<any>()
    const [upload, setUpload] = useState<boolean>(false)
    const [busy, setBusy] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [person, setPerson] = useState<SchemaPerson>({
        "address": {
            "@type": "PostalAddress",
            "name": "",
            "alternateName": "",
            "streetAddress": "",
            "addressLocality": "",
            "postalCode": "",
            "addressCountry": "",
        },
        "affiliation": {
            "@type": "Organization",
            "name": "",
            "vatID": "",
        },
        "name": "",
        "email": "",
        "familyName": "",
        "givenName": "",
        "telephone": "",
    })

    const push: () => void = useCallback(() => setUpload(true), [])

    useEffect(() => {
        if (isLoading) return
        if (!isAuthenticated) return
        setBusy(true)
        getAccessTokenSilently()
            .then(t => {
                ky.get(`${API_URL}/accounts/${base64.encode(user?.email || "")}/billing`, {"headers": customHeader(t)})
                    .json<SchemaPerson>()
                    .then(p => setPerson(p))
                    .catch(e => setError(e))
                    .finally(() => setBusy(false))
            })
            .catch(e => {
                setError(e)
                setBusy(false)
            })
    }, [isLoading, isAuthenticated, getAccessTokenSilently, user])

    useEffect(() => {
        if (!upload) return
        setUpload(false)
        setBusy(true)
        getAccessTokenSilently()
            .then(t => {
                ky.put(`${API_URL}/accounts/${base64.encode(user?.email || "")}/billing`, {
                    "headers": customHeader(t),
                    "json": person,
                })
                    .catch(e => setError(e))
                    .finally(() => {
                        setBusy(false)
                        setSuccess(true)
                    })
            })
            .catch(e => {
                setError(e)
                setBusy(false)
            })
    }, [person, upload, user, getAccessTokenSilently])

    // storing return in state prevents nasty render loops
    const [state, setState] = useState({push, error, person, setPerson, busy, success})
    useEffect(() => {
        setState({push, error, person, setPerson, busy, success})
    }, [push, error, person, setPerson, busy, success])

    return state
}

// Retrieves address at slot "index" which can also be updated
export const useUserShippingAddress = (index: number) => {

    const {getAccessTokenSilently, isAuthenticated, isLoading, user} = useAuth0()
    const [error, setError] = useState<any>()
    const [upload, setUpload] = useState<boolean>(false)
    const [busy, setBusy] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [address, setAddress] = useState<SchemaPostalAddress>({
        "@type": "PostalAddress",
        "name": "",
        "alternateName": "",
        "streetAddress": "",
        "addressLocality": "",
        "postalCode": "",
        "addressCountry": "",
    })

    const push = useCallback(() => setUpload(true), [])

    useEffect(() => {
        if (isLoading) return
        if (!isAuthenticated) return
        setBusy(true)
        getAccessTokenSilently()
            .then(token => {
                ky.get(`${API_URL}/accounts/${base64.encode(user?.email || "")}/address/${index}`, {"headers": customHeader(token)})
                    .json<SchemaPostalAddress>()
                    .then(a => setAddress(a))
                    .catch(e => setError(e))
                    .finally(() => setBusy(false))
            })
            .catch(error => {
                setError(error)
                setBusy(false)
            })
    }, [isLoading, index, isAuthenticated, getAccessTokenSilently, user])

    useEffect(() => {
        if (!upload) return
        setUpload(false)
        setBusy(true)
        getAccessTokenSilently()
            .then(token => {
                ky.put(`${API_URL}/accounts/${base64.encode(user?.email || "")}/address/${index}`, {
                    "headers": customHeader(token),
                    "json": address,
                })
                    .catch(error => setError(error))
                    .finally(() => {
                        setBusy(false)
                        setSuccess(true)
                    })
            })
            .catch(error => {
                setError(error)
                setBusy(false)
            })
    }, [address, index, upload, user, getAccessTokenSilently])

    const [state, setState] = useState({push, error, address, setAddress, busy, success})
    useEffect(() => {
        setState({push, error, address, setAddress, busy, success})
    }, [push, error, address, setAddress, busy, success])

    return state
}


// Retrieves options which can also be updated
export const useUserOptions = <T extends { [key: string]: number }>(initial: T) => {

    const {getAccessTokenSilently, isAuthenticated, isLoading, user} = useAuth0()
    const [error, setError] = useState<any>()
    const [upload, setUpload] = useState<boolean>(false)
    const [busy, setBusy] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>(false)
    const [options, setOptions] = useState<T>(initial)

    const push = useCallback(() => setUpload(true), [])

    useEffect(() => {
        if (isLoading) return
        if (!isAuthenticated) return
        setBusy(true)
        getAccessTokenSilently()
            .then(token => {
                ky.get(`${API_URL}/accounts/${base64.encode(user?.email || "")}/options`, {"headers": customHeader(token)})
                    .json<T>()
                    .then(o => setOptions(o))
                    .catch(e => setError(e))
                    .finally(() => setBusy(false))
            })
            .catch(error => {
                setError(error)
                setBusy(false)
            })
    }, [isLoading, isAuthenticated, getAccessTokenSilently, user])

    useEffect(() => {
        if (!upload) return
        setUpload(false)
        setBusy(true)
        getAccessTokenSilently()
            .then(token => {
                ky.put(`${API_URL}/accounts/${base64.encode(user?.email || "")}/options`, {
                    "headers": customHeader(token),
                    "json": options,
                })
                    .catch(error => setError(error))
                    .finally(() => {
                        setBusy(false)
                        setSuccess(true)
                    })
            })
            .catch(error => {
                setError(error)
                setBusy(false)
            })
    }, [options, upload, user, getAccessTokenSilently])

    const [state, setState] = useState({push, error, options, setOptions, busy, success})
    useEffect(() => {
        setState({push, error, options, setOptions, busy, success})
    }, [push, error, options, setOptions, busy, success])

    return state
}

// Get orders list
export const useUserOrders = () => {

    const [busy, setBusy] = useState<boolean>(true)
    const [orders, setOrders] = useState<SchemaOrder[]>([])
    const [error, setError] = useState<any>()
    const {getAccessTokenSilently, user} = useAuth0()

    useEffect(() => {
        getAccessTokenSilently()
            .then(token => {
                const options: Options = {"headers": customHeader(token)}
                ky.get(`${API_URL}/accounts/${base64.encode(user?.email || "")}/orders`, options).json<any>()
                    .then(result => {
                        setOrders(result["@graph"])
                        setBusy(false)
                    })
                    .catch(err => {
                        setError(err)
                        setBusy(false)
                    })
            })
            .catch(err => {
                setError(err)
                setBusy(false)
            })
    }, [getAccessTokenSilently, user])

    const [state, setState] = useState({busy, orders, error})
    useEffect(() => {
        setState({busy, orders, error})
    }, [busy, orders, error])

    return state
}
