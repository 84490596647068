import React, {FC} from "react"
import {Route, Routes} from "react-router-dom"
import NotFoundCatch from "../_components/NotFound/NotFoundCatch"

const EventRoutes: FC = () => (
    <Routes>
        <Route element={<NotFoundCatch/>}/>
    </Routes>
)

export default EventRoutes
