import React, {FC} from "react"
import "./ProductTile.scss"
import LocaleLink from "../localization/LocaleLink"
import noWine from "./no-wine-01.svg"
import {SchemaProduct} from "../../_types/types"

interface Props {
    product: SchemaProduct
    overflow?: boolean
}

const ProductTile: FC<Props> = ({product, overflow}) => {

    let image
    if (product.image) {
        if (typeof product.image !== "string") {
            image = <img alt={product.name} src={product.image.contentUrl}/>
        } else {
            image = <img alt={product.name} src={product.image}/>
        }
    } else {
        image = <img alt={product.name} src={noWine}/>
    }

    return (
        <div className={"product-tile " + (overflow ? "product-tile-overflow" : "")}>
            <LocaleLink to={"/wines/" + product.identifier}>
                <div className="tile-image">
                    {image}
                </div>
                <div className="tile-title">
                    {product.name}
                </div>
                <div className="tile-price">
                    &euro;{product.offers.integerPrice / 100}
                </div>
            </LocaleLink>
        </div>
    )
}

export default ProductTile