import React, {FC, useMemo} from "react"
import {MercuryOptions, useDocument, useManyDocuments} from "../../_services/mercury.service"
import {TypeReference, TypeWine, TypeWineList} from "../../_types/new-types"
import LoaderSection from "../Loader/LoaderSection"
import ErrorToast from "../../Error/ErrorToast"
import LocaleLink from "../localization/LocaleLink"
import noWine from "../products/no-wine-01.svg"
import NotFoundCatch from "../NotFound/NotFoundCatch"


export const WineListShowcaseById: FC<{ id: string, children?: React.ReactNode }> = ({id, children}) => {

    const {
        document,
        isLoading,
        error,
    } = useDocument<TypeWineList>(id)

    if (error) {
        return <ErrorToast error={error}/>
    }
    if (isLoading) {
        return <LoaderSection height={360}/>
    }
    if (!document) {
        return <NotFoundCatch/>
    }

    return <WineShowcase items={document.itemListElement}>{children}</WineShowcase>
}

interface WineListShowcaseProps {
    identifier: string
    children?: React.ReactNode
}

export const WineListShowcase: FC<WineListShowcaseProps> = ({identifier, children}) => {

    const opts = useMemo<MercuryOptions>(() => ({
        "filter": {
            "identifier": identifier,
        },
    }), [identifier])
    const {
        items,
        isLoading,
        error,
    } = useManyDocuments<TypeWineList>("/wine-lists", opts)

    if (error) {
        return <ErrorToast error={error}/>
    }
    if (isLoading) {
        return <LoaderSection height={360}/>
    }
    if (items.length === 0) {
        return null
    }

    return <WineShowcase items={items[0].itemListElement}>{children}</WineShowcase>
}

interface WineShowcaseProps {
    items: TypeReference[]
    excluded?: TypeReference[]
    limit?: number
    list?: boolean
    popup?: boolean
    children?: React.ReactNode
}

export const WineShowcase: FC<WineShowcaseProps> = (props) => {

    const excluded = props.excluded?.map(i => i["@id"])
    const products = (!props.excluded) ? props.items : (props.items.filter(i => !excluded?.includes(i["@id"])))

    const limit = props.limit ? props.limit : 5
    const tiles = []
    for (let i = 0; i < Math.min(limit, products.length); i++) {
        tiles.push(
            <WineTile
                popup={props.popup}
                list={props.list}
                key={products[i]["@id"]}
                url={products[i]["@id"]}
                overflow={i > 5}
            />,
        )
    }

    if (!props.list) {
        for (let i = 0; i < 4; i++) {
            tiles.push(<div key={"filler-" + i + tiles.length}
                            className={(props.list ? "product-row" : "product-tile") + " product-tile-empty"}/>)
        }
    }

    if (tiles.length === 0) {
        return null
    }

    return (
        <div className="product-related">
            {props.children}
            <div className={props.list ? "product-related-list" : "product-related-grid"}>
                {tiles}
            </div>
        </div>
    )
}

interface WineTileProps {
    url: string
    overflow: boolean
    list?: boolean
    popup?: boolean
}

const WineTile: FC<WineTileProps> = (props) => {

    const query = useMemo(() => ["fields=name,image,offers,identifier"], [])
    const {isLoading, error, document} = useDocument<TypeWine>(props.url, query)

    if (error) {
        return <ErrorToast error={error}/>
    }

    if (isLoading) {
        return <LoaderSection/>
    }

    return (
        <div
            className={`${props.list ? "product-row" : "product-tile"} ${props.overflow ? "product-tile-overflow" : ""}`}>
            <LocaleLink
                to={"/wines/" + document?.identifier}
                target={props.popup ? "_blank" : ""}
                rel={props.popup ? "noreferrer" : ""}
            >
                <div className="tile-image">
                    {document?.image ? (
                        <img alt={document?.name} src={document.image.thumbnail.contentUrl}/>
                    ) : (
                        <img alt={document?.name} src={noWine}/>
                    )}
                </div>
                <div className="tile-title">
                    {document?.name}
                </div>
                <div className="tile-price">
                    &euro;{document?.offers ? document.offers.integerPrice / 100 : null}
                </div>
            </LocaleLink>
        </div>
    )
}
