import React, {FC, useEffect, useState} from "react"
import "./Header.scss"
import logo from "./matthys_wines_transparent.svg"
import {useTranslation} from "react-i18next"
import LocaleLink from "../localization/LocaleLink"
import {SpinnerSmall} from "../Loader/SpinnerSmall"
import {useLocation} from "react-router-dom"
import SiteSearch from "../SiteSearch/SiteSearch"
import {useCart} from "../../_contexts/CartContext"
import {useAuth0} from "@auth0/auth0-react"
import {encodeBase64} from "../../_helpers/encoding"
import {SchemaLanguage} from "../../_types/types"
import {getLanguages} from "../../_services/api.public.service"
import ky from "ky"

interface MobileHamburgerProps {
    className?: string
    onClose: () => void
}

const menuItems: { path: string, name: string }[] = [
    {"path": "/wines", "name": "nav/wines"},
    {"path": "/discovery-cases", "name": "nav/discoveryCases"},
    {"path": "/food-match/start", "name": "nav/foodMatch"},
    {"path": "/horeca", "name": "nav/horeca"},
    {"path": "/articles/environment", "name": "nav/environment"},
    {"path": "/articles/events", "name": "nav/events"},
    {"path": "/contact", "name": "nav/contact"},
]

const menuMobileExtra: { path: string, name: string }[] = [
    {"path": "/articles/matthys-wines", "name": "nav/matthysWines"},
    {"path": "/account", "name": "nav/account"},
]

const MobileHamburger = (props: MobileHamburgerProps) => {
    const {t} = useTranslation()

    return (
        <div className={"header-mobile-hamburger " + props.className}>
            <div className={"hamburger-header"}>
                <span className={"hamburger-title"}>Matthys Wines</span>
            </div>
            <ul className="menu" onClick={_ => props.onClose()}>
                {([{"path": "/", "name": "nav/home"}, ...menuItems, ...menuMobileExtra]).map(item => (
                    <li className="menu-item" key={item.name}>
                        {
                            (item.path === "/horeca") ? (<a key={item.name} href="https://horeca.matthys-wines.com"
                                                            className="nav-item">{t(item.name)}</a>) : (
                                <LocaleLink className='nav-item'
                                            to={item.path}>{t(item.name)}</LocaleLink>)
                        }
                    </li>
                ))}
                <li className="menu-item">
                    <button className="button-hamburger-close" type='button' title="Close" // TODO: translate this
                            onClick={_ => props.onClose()}>
                    </button>
                </li>
            </ul>
        </div>
    )
}

const HeaderAccountButton: FC = () => {

    const {loginWithRedirect, isAuthenticated} = useAuth0()
    const {t, i18n} = useTranslation("common")
    const {pathname} = useLocation()

    if (!isAuthenticated)
        return (
            <button className='nav-item expanded-item hide-md'
                    onClick={() => {
                        const pathParts = pathname.split("/")
                        pathParts.splice(0, 2)
                        const pathNoLang = "/" + pathParts.join("/")
                        loginWithRedirect({
                            "ui_locales": i18n.language,
                            "redirectUri": `${window.location.origin}/authorize?lng=${i18n.language}&r=${encodeBase64(pathNoLang)}`,
                        })
                    }}>
                {t("nav/signIn")}
            </button>
        )

    return (
        <LocaleLink className='nav-item expanded-item hide-md' to='/account'>
            {t("nav/account")}
        </LocaleLink>
    )
}

const HeaderCartButton: FC = () => {

    const cart = useCart()

    // Show loading when cart is busy
    if (cart.busy || cart.waiting)
        return <SpinnerSmall light={true}/>

    // Add item counter to cart if any items exist
    let counter = null
    if (cart && cart.items.length > 0) {
        counter = <span className="cart-item-counter">{cart.items.length}</span>
    }

    // TODO: translate
    return (
        <LocaleLink to="/cart" className={"navbar-button btn-cart " + (counter ? "cart-full" : "")} title="Cart">
            {counter}
        </LocaleLink>
    )
}

const Header: FC = () => {

    const [showHamburger, setShowHamburger] = useState<boolean>(false)
    const [country, setCountry] = useState<string>("")
    const [languages, setLanguages] = useState<SchemaLanguage[]>([])
    const {t, i18n} = useTranslation()
    const {pathname} = useLocation()

    useEffect(() => {
        getLanguages().then(languages => setLanguages(languages))
    }, [])

    const getFlag = (code: string): string => {
        if (code === "nl" && country === "BE") {
            return "be"
        }
        return code
    }

    // get country to show correct flag
    useEffect(() => {
        ky.get("https://api.matthys-wines.com/localization/geo").json<{ countryCode: string }>()
            .then(res => setCountry(res.countryCode))
            .catch(err => console.log(err))
    }, [])

    let isTransparent = false
    let pathParts = pathname.split("/")
    let basePath = pathParts.slice(2, pathParts.length).join("/")

    switch (basePath) {
        case "":
            isTransparent = true
    }

    return (
        <header id="topHeader" className={(isTransparent ? "header-banner" : "")}>
            <div className="site-nav">
                <div className="container grid-xl">
                    <div className="navbar">
                        <section className="navbar-section navbar-hamburger show-md">
                            <button
                                className='button-toggle-menu' type='button'
                                onClick={() => setShowHamburger(true)}
                                title="Toggle menu" // TODO: translate this
                            />
                        </section>
                        <section className="navbar-section navbar-section-logo hide-md">
                            <LocaleLink className='navbar-logo' to={"/"}>
                                <img alt="Matthys Wines" src={logo}/>
                            </LocaleLink>
                        </section>
                        <section className="navbar-section navbar-center">
                            <SiteSearch/>
                        </section>
                        <section className="navbar-section">
                            {Array.isArray(i18n.options.supportedLngs) && (
                                <div className="dropdown language-dropdown navbar-language-select">
                                    <a tabIndex={0} href="#" className="btn btn-link dropdown-toggle">
                                        <span className={"language-icon language-icon-" + getFlag(i18n.language)}/>
                                        {i18n.language.toUpperCase()}
                                    </a>
                                    <ul className="menu">
                                        {languages
                                            .filter(l => l.alternateName.length === 2 && l.alternateName !== i18n.language)
                                            .map(lang => {
                                                let code = lang.alternateName
                                                let pathNoLang = "/" + pathname.split("/").slice(2).join("/")
                                                if (pathNoLang === "/") pathNoLang = ""
                                                return (
                                                    <li className="menu-item" key={code}>
                                                        <a href={`/${code}${pathNoLang}`}>
                                                            <span
                                                                className={"language-icon language-icon-" + getFlag(code)}
                                                            />
                                                            {code.toUpperCase()}
                                                        </a>
                                                    </li>
                                                )
                                            })}
                                    </ul>
                                </div>
                            )}
                            <HeaderAccountButton/>
                            <HeaderCartButton/>
                        </section>
                    </div>
                </div>
            </div>
            <div className="chapter-nav container grid-xl hide-md">
                <div className="navbar">
                    <section className="navbar-section show-lg"/>
                    <section className="navbar-section hide-lg">
                        <LocaleLink className='nav-item nav-item-brand' to="/">
                            Matthys Wines
                        </LocaleLink>
                    </section>
                    <section className="navbar-section navbar-center">
                        {menuItems.map(item => {
                            if (item.path === "/horeca") {
                                return <a key={item.name} href="https://horeca.matthys-wines.com"
                                          className="nav-item">{t(item.name)}</a>
                            } else {
                                return (
                                    <LocaleLink key={item.name} className='nav-item' to={item.path}>
                                        {t(item.name)}
                                    </LocaleLink>
                                )
                            }
                        })}
                    </section>
                    <section className="navbar-section">
                    </section>
                </div>
            </div>
            <MobileHamburger
                className={showHamburger ? "" : "hidden"}
                onClose={() => setShowHamburger(false)}
            />
        </header>
    )
}

export default Header
