import React, {FC} from "react"
import EmptyPage from "./EmptyPage"
import {useTranslation} from "react-i18next"
import Loader from "../_components/Loader/Loader"
import LocaleRedirect from "../_components/localization/LocaleRedirect"
import {useCart} from "../_contexts/CartContext"

const CartEmptyPage: FC = () => {

    const {t} = useTranslation("common")

    const cart = useCart()

    if (cart.busy)
        return <Loader/>

    if (cart.items.length > 0)
        return <LocaleRedirect to={"/cart"}/>

    return (
        <EmptyPage
            title={t("empty/cart/title")}
            lead={t("empty/cart/lead")}
            iconClass={"icon-cart"}
            action={"/wines"}
            actionText={t("empty/cart/action")}
        />
    )

}

export default CartEmptyPage