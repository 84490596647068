import React, {FC, useEffect, useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck, faInfoCircle, faMinus, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons"
import {faCartPlus} from "@fortawesome/free-solid-svg-icons/faCartPlus"
import LocaleLink from "../localization/LocaleLink"
import {useTranslation} from "react-i18next"
import "./ProductPriceToCart.scss"
import {useCartDispatch} from "../../_contexts/CartContext"
import {cartAddItem} from "../../_services/cart.service"
import {TypeOffer} from "../../_types/new-types"

const PricingMessage: FC<{ children?: React.ReactNode }> = props => {

    return (
        <div className="product-offer">
            {props.children}
        </div>
    )
}

interface Props {
    defaultQuantity?: number
    productId: string
    offer: TypeOffer
    isArchived: boolean
    hideQuantity?: boolean
    regionBlock?: boolean
}

const ProductPriceToCart: FC<Props> = ({offer, ...props}) => {

    const {t} = useTranslation("store")

    const dispatch = useCartDispatch()

    const [hasAdded, setHasAdded] = useState<boolean>(false)
    const [quantity, setQuantity] = useState<number>(props.defaultQuantity ? props.defaultQuantity : 6)

    // Keep quantity in range
    useEffect(() => {
        if (quantity < 1)
            setQuantity(1)
        else if (quantity > 100)
            setQuantity(100)
    }, [quantity])

    // Added to cart view
    if (hasAdded) {
        return (
            <PricingMessage>
                <div className="offer-cart-group">
                    <div className="offer-title offer-check">
                        {t("addedToCart")}
                        <FontAwesomeIcon icon={faCheck} className="ml-2"/>
                    </div>
                    <div className="offer-action-group">
                        <LocaleLink to={"/cart"} className="btn btn-primary">
                            {t("btn/viewCart", {"ns": "common"})}
                        </LocaleLink>
                        <button onClick={() => setHasAdded(false)} className="btn btn-offer-back">
                            <FontAwesomeIcon icon={faTimes}/>
                        </button>
                    </div>
                </div>
            </PricingMessage>
        )
    }

    // Set offer availability
    let availability: string
    if (!offer || !offer.integerPrice) {
        availability = ""
    } else if (props.regionBlock) {
        availability = "out-of-stock"
    } else {
        if (props.isArchived) {
            availability = "out-of-stock"
        } else {
            availability = offer.availability
        }
    }

    switch (availability) {
        case "in-stock":
            return (
                <PricingMessage>
                    <div className="offer-cart-group">
                        <div className="offer-pricing-group offer-available">
                            <div className="offer-price">&euro;{offer.integerPrice / 100}</div>
                            <div className="offer-availability">{t("status/inStock")}
                                <FontAwesomeIcon icon={faCheck} className="ml-2"/>
                            </div>
                        </div>
                        <div className="offer-action-group">
                            {(props.hideQuantity === undefined || !props.hideQuantity) ? (
                                <div className="offer-quantity">
                                    <button
                                        onClick={() => setQuantity(prev => prev - 1)}
                                        className="offer-cart-quantity-button"
                                    ><FontAwesomeIcon icon={faMinus}/></button>
                                    <input
                                        value={quantity}
                                        onChange={e => {
                                            let q = e.target.value
                                            setQuantity(() => parseInt(q))
                                        }}
                                        min={1}
                                        max={100}
                                        className="offer-cart-quantity"
                                        type="number"
                                    />
                                    <button
                                        onClick={() => setQuantity(prev => prev + 1)}
                                        className="offer-cart-quantity-button"
                                    ><FontAwesomeIcon icon={faPlus}/>
                                    </button>
                                </div>
                            ) : null}
                            <button
                                className='offer-add-cart-button'
                                onClick={() => {
                                    dispatch(cartAddItem({"@id": props.productId}, quantity))
                                    setHasAdded(true)
                                }}
                            >
                                <span className="cart-icon"><FontAwesomeIcon icon={faCartPlus}/></span>
                                {t("btn/addToCart", {"ns": "common"})}
                            </button>
                        </div>
                    </div>
                </PricingMessage>
            )
        case "out-of-stock":
            return (
                <PricingMessage>
                    <div className="offer-cart-group">
                        <div className="offer-pricing-group offer-not-available">
                            <div className="offer-price">&euro;{offer.integerPrice / 100}</div>
                            <div className="offer-availability">
                                {t("status/outOfStock")}
                            </div>
                        </div>
                    </div>
                    <div className="offer-message">
                        {offer.availabilityStarts ? (
                            <span>
                                <FontAwesomeIcon icon={faInfoCircle} className="mr-2"/>
                                {t("status/backInStockOn")} <b>{offer.availabilityStarts}</b>
                            </span>
                        ) : null}
                    </div>
                </PricingMessage>
            )
        case "call-for-availability":
            return (
                <PricingMessage>
                    <div className="offer-cart-group">
                        <div className="offer-pricing-group offer-maybe-available">
                            <div className="offer-price">&euro;{offer.integerPrice / 100}</div>
                            <div className="offer-availability">{t("status/callForAvailability")}</div>
                        </div>
                    </div>
                    <div className="offer-message">
                        <FontAwesomeIcon icon={faInfoCircle} className="mr-2"/>{t("message/callForAvailability")}
                    </div>
                </PricingMessage>
            )
        case "discontinued":
            return (
                <PricingMessage>
                    <div className="offer-title">
                        <FontAwesomeIcon icon={faTimes} className="mr-2"/>
                        {t("status/discontinued")}
                    </div>
                    <div className="offer-message">{t("status/discontinued")}</div>
                </PricingMessage>
            )
        case "sold-out":
            return (
                <PricingMessage>
                    <div className="offer-cart-group">
                        <div className="offer-pricing-group offer-not-available">
                            <div className="offer-price">&euro;{offer.integerPrice / 100}</div>
                            <div className="offer-availability">{t("status/soldOut")}
                                <FontAwesomeIcon icon={faTimes} className="ml-2"/>
                            </div>
                        </div>
                    </div>
                    <div className="offer-message"><FontAwesomeIcon icon={faInfoCircle}
                                                                    className="mr-2"/>{t("status/soldOut")}</div>
                </PricingMessage>
            )
        default:
            return (
                <PricingMessage>
                    <div className="offer-title">
                        <FontAwesomeIcon icon={faTimes} className="mr-2"/>
                        {t("status/unavailable")}
                    </div>
                    <div className="offer-message">{t("status/unavailable")}</div>
                </PricingMessage>
            )
    }
}

export default ProductPriceToCart
