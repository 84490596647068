import React, {FC} from "react"
import "./WinePairing.scss"
import {Route, Routes} from "react-router-dom"
import NotFoundCatch from "../_components/NotFound/NotFoundCatch"
import WinePairingWineStyle from "./WinePairingWineStyle"
import WinePairingIntro from "./WinePairingIntro"
import WinePairingPriceRange from "./WinePairingPriceRange"
import WinePairingFoodStyle from "./WinePairingFoodStyle"
import WinePairingDish from "./WinePairingDish"
import WinePairingCookingMethod from "./WinePairingCookingMethod"
import WinePairingAgriculture from "./WinePairingAgriculture"
import WinePairingResults from "./WinePairingResults"

const WinePairingPage: FC = () => (
    <div id="winePairing" className="text-center">
        <Routes>
            <Route path="/start" element={<WinePairingIntro/>}/>
            <Route path="/*" element={(
                <div className="food-match-quiz">
                    <div className="food-match-container">
                        <Routes>
                            <Route path="/wine-style" element={<WinePairingWineStyle/>}/>
                            <Route path="/price-range" element={<WinePairingPriceRange/>}/>
                            <Route path="/food-style" element={<WinePairingFoodStyle/>}/>
                            <Route path="/dish" element={<WinePairingDish/>}/>
                            <Route path="/cooking-method" element={<WinePairingCookingMethod/>}/>
                            <Route path="/agriculture" element={<WinePairingAgriculture/>}/>
                            <Route path="/results" element={<WinePairingResults/>}/>
                            <Route element={<NotFoundCatch/>}/>
                        </Routes>
                    </div>
                </div>
            )}/>
        </Routes>
    </div>
)

export default WinePairingPage
