import React, {FC, useCallback, useState} from "react"
import FormInput from "../form-inputs/FormInput"
import {useTranslation} from "react-i18next"
import {SchemaReservationPerson} from "../../_types/types"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faAngleRight, faCheck} from "@fortawesome/free-solid-svg-icons"
import ky from "ky"
import {API_URL} from "../../_helpers/environment"
import {localizationHeaders} from "../../_helpers/auth-header"
import * as Sentry from "@sentry/browser"
import ErrorPage from "../../Error/ErrorPage"
import LoaderSection from "../Loader/LoaderSection"

interface Props {
    name: string
}

interface FormData {
    person: SchemaReservationPerson
    groupSize: number
}

// Basic registration form for events
const FormEventRegistration: FC<Props> = ({name}) => {

    const {t} = useTranslation("form")

    // Define states
    const [busy, setBusy] = useState<boolean>(false)
    const [error, setError] = useState<any>()
    const [success, setSuccess] = useState<boolean>(false)
    const [form, setForm] = useState<FormData>({
        "person": {
            "name": "",
            "email": "",
            "telephone": "",
            "address": {
                "@type": "",
                "name": "",
                "addressCountry": "",
                "addressLocality": "",
                "streetAddress": "",
                "alternateName": "",
                "postalCode": "",
            },
        },
        "groupSize": 1,
    })

    // Define submit
    const submit = useCallback(() => {
        setBusy(true)
        ky.post(API_URL + "/forms/registrations", {
            "json": form,
            "headers": localizationHeaders(),
        })
            .then(() => setSuccess(true))
            .catch(error => {
                Sentry.captureException(error)
                Sentry.captureMessage(JSON.stringify(form))
                setError(error)
            })
            .finally(() => setBusy(false))
    }, [form])

    // Show error, busy or success if state has changed
    if (error) return <ErrorPage error={error}/>
    if (busy) return <LoaderSection/>
    if (success) return (
        <div className="toast toast-success">
            <FontAwesomeIcon icon={faCheck} className="mr-2"/>
            {t("registration/success", {"ns": "common"})}
        </div>
    )

    // Show form
    return (
        <form onSubmit={e => {
            e.preventDefault()
            submit()
        }}>
            <FormInput
                label={t("label/name")}
                type={"text"}
                id={"rsvpName"}
                value={form.person.name}
                onChange={v => setForm(s => ({...s, "person": {...s.person, "name": v}}))}
                required={true}
            />
            <FormInput
                label={t("label/email")}
                type={"email"}
                id={"rsvpEmail"}
                value={form.person.email}
                onChange={v => setForm(s => ({...s, "person": {...s.person, "email": v}}))}
                required={true}
            />
            <FormInput
                label={t("label/telephone")}
                type={"tel"}
                id={"rsvpTelephone"}
                value={form.person.telephone}
                onChange={v => setForm(s => ({...s, "person": {...s.person, "telephone": v}}))}
                required={true}
            />
            <FormInput
                label={t("label/street")}
                type={"text"}
                id={"rsvpAddress"}
                value={form.person.address.streetAddress}
                onChange={v => setForm(s => ({
                    ...s,
                    "person": {...s.person, "address": {...s.person.address, "streetAddress": v}},
                }))}
                required={true}
            />
            <div className="columns">
                <div className="column col-6">
                    <FormInput
                        label={t("label/city")}
                        type={"text"}
                        id={"rsvpAddressCity"}
                        value={form.person.address.addressLocality}
                        onChange={v => setForm(s => ({
                            ...s,
                            "person": {...s.person, "address": {...s.person.address, "addressLocality": v}},
                        }))}
                        required={true}
                    />
                </div>
                <div className="column col-6">
                    <FormInput
                        label={t("label/postalCode")}
                        type={"text"}
                        id={"rsvpAddressPostalCode"}
                        value={form.person.address.postalCode}
                        onChange={v => setForm(s => ({
                            ...s,
                            "person": {...s.person, "address": {...s.person.address, "postalCode": v}},
                        }))}
                        required={true}
                    />
                </div>
            </div>
            <div className="columns">
                <div className="column col-6">
                    <FormInput
                        label={t("label/groupSize")}
                        type={"number"}
                        id={"rsvpGroupSize"}
                        value={form.groupSize}
                        onChange={v => setForm(s => ({...s, "groupSize": parseInt(v)}))}
                        required={true}
                    />
                </div>
            </div>
            <div className="text-right pt-2">
                <button type="submit" className="btn btn-primary mt-2">
                    {t("btn/submit", {"ns": "common"})}
                    <FontAwesomeIcon icon={faAngleRight} className="ml-2"/>
                </button>
            </div>
        </form>
    )
}

export default FormEventRegistration
