import React, {FC, useEffect, useState} from "react"
import "./LanguageSuggestion.scss"
import {getBrowserLanguage} from "../../_helpers/language"
import {useTranslation} from "react-i18next"
import {faAngleRight} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {getLanguages} from "../../_services/api.public.service"
import {SchemaLanguage} from "../../_types/types"
import {useLocation} from "react-router-dom"

const LanguageSuggestion: FC = () => {

    const {i18n, t} = useTranslation("common")
    const [hidden, setHidden] = useState<boolean>(false)
    const [languages, setLanguages] = useState<SchemaLanguage[]>([])
    const {pathname} = useLocation()

    useEffect(() => {
        getLanguages().then(languages => setLanguages(languages))
    }, [])

    const suggestion = getBrowserLanguage(languages)
    if ((localStorage && localStorage.getItem("hide_language_suggestion")) || hidden || suggestion === i18n.language) return null

    let pathNoLang = "/" + pathname.split("/").slice(2).join("/")
    if (pathNoLang === "/") pathNoLang = ""

    return (
        <div className="language-suggestion">
            <div className="container grid-md">
                <div className="language-suggestion-text">
                    {t("lng/" + suggestion + "/suggestion")}
                </div>
                <div className="language-suggestion-button">
                    <a className="btn btn-primary" href={`/${suggestion}${pathNoLang}`}>
                        {t("lng/" + suggestion + "/continue")}
                        <FontAwesomeIcon icon={faAngleRight} className="ml-2" style={{"fontStyle": "0.6rem"}}/>
                    </a>
                    <button
                        title="Close"
                        className="btn btn-clear ml-2"
                        onClick={() => {
                            localStorage && localStorage.setItem("hide_language_suggestion", "true")
                            setHidden(true)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default LanguageSuggestion

