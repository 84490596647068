import React, {FC, useState} from "react"
import {SchemaOrderPerson} from "../../../_types/types"
import {useTranslation} from "react-i18next"
import FormInput from "../../../_components/form-inputs/FormInput"
import FormCheckbox from "../../../_components/form-inputs/FormCheckbox"

interface Props {
    person: SchemaOrderPerson,
    onChange: (modifier: (prev: SchemaOrderPerson) => SchemaOrderPerson) => void
    visible: number
    setVisible: (v: number) => void
}

const CheckoutCompanyForm: FC<Props> = ({person, onChange, visible, setVisible}) => {

    const {t} = useTranslation("form")
    const [required, setRequired] = useState<boolean>(false)

    return (
        <div className="card-unit card-mb">
            <h4>{t("label/invoice")}</h4>
            <div className="form-group">
                <label className="form-radio">
                    <input type="radio" name="company" checked={Boolean(!visible)}
                           onChange={e => setVisible(!e.target.checked ? 1 : 0)}/>
                    <i className="form-icon"/> {t("label/privatePurchaseAndNoInvoice")}
                </label>
                <label className={`form-radio ${visible ? "" : "mb-0"}`}>
                    <input type="radio" name="company" checked={Boolean(visible)}
                           onChange={e => setVisible(e.target.checked ? 1 : 0)}/>
                    <i className="form-icon"/> {t("label/companyAndWouldLikeInvoice")}
                </label>
            </div>
            {visible ? (
                <>
                    <FormInput
                        id={"billingVAT"}
                        label={t("label/vat")}
                        placeholder={t("placeholder/vat")}
                        type={"text"}
                        value={person.affiliation.vatID}
                        onChange={v => onChange(prev => ({...prev, "affiliation": {...prev.affiliation, "vatID": v}}))}
                        required={!required}
                    />
                    <FormCheckbox
                        label={t("label/vatFree")}
                        value={required}
                        onChange={v => setRequired(v)}
                    />
                </>
            ) : null}
        </div>
    )

}

export default CheckoutCompanyForm
