import React, {FC, ReactNode, useEffect, useState} from "react"
import LocaleLink from "../_components/localization/LocaleLink"
import goForestLogo from "../Home/goforest.svg"
import {useTranslation} from "react-i18next"
import Snippet from "../_components/annotations/Snippet"
import {Helmet} from "react-helmet"
import {languageReferences} from "../_components/annotations/Languages"
import LazyLoad from "react-lazyload"
import "./Home.scss"
import {WineListShowcase} from "../_components/WineShowcase/WineShowcase"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faAngleRight} from "@fortawesome/free-solid-svg-icons"
import {SectionAgriculture, SectionCountries, SectionWineStyle} from "./_components/HomeShortcuts"
import HomeTile from "./_components/HomeTile"
import ky from "ky"
import {CATALOG_URL} from "../_helpers/environment"
import {CatalogFilterTree} from "../_types/requests"

const OrganizationSnippet = () => <Snippet data={{
    "@context": "https://schema.org",
    "@type": "Organization",
    "@id": "https://www.matthys-wines.com/#organization",
    "name": "Matthys Wines",
    "logo": "https://static-beta.matthys-wines.com/images/identity/logo.svg",
    "url": "https://www.matthys-wines.com/",
    "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+3250386380",
        "email": "frank@matthys-wines.be",
    },
}}/>

const WebPageSnippet = () => <Snippet data={{
    "@context": "https://schema.org",
    "@type": "WebPage",
    "@id": "https://www.matthys-wines.com/#webpage",
    "name": "Matthys Wines",
    "url": "https://www.matthys-wines.com/",
}}/>

const HomeTitle: FC = () => {
    const {t} = useTranslation("common")
    const title = t("home/hero/title")
    const ts = title.split(",")
    const titleComp: ReactNode[] = []
    for (let i = 0; i < ts.length; i++) {
        titleComp.push((
            <span key={`${i}`}>{ts[i]}</span>
        ))
        if (i === ts.length - 1)
            continue
        titleComp.push((
            <span key={`${i},`}>,<br/></span>
        ))
    }
    return (
        <h1 title={title} className="t1">{titleComp}</h1>
    )
}

const Home: FC = () => {

    const {t} = useTranslation("common")

    const hasEvent = t("announcement/title", "") !== ""

    const [pending, setPending] = useState<boolean>(false)
    const [filterOptions, setFilterOptions] = useState<CatalogFilterTree | undefined>(undefined)
    useEffect(() => {
        setPending(true)
        ky.get(`${CATALOG_URL}/filter/wines`)
            .json<CatalogFilterTree>()
            .then(res => setFilterOptions(res))
            .catch(err => console.log(err))
            .finally(() => setPending(false))
    }, [])

    return (
        <div id="homepage">
            <Helmet>
                <meta property="og:site_name" content="matthys-wines.com"/>
                <meta property="og:title" content={t("site/title")}/>
                <meta
                    property="og:description"
                    content={t("site/description")}/>
                <meta
                    property="og:image"
                    content="https://static.matthys-wines.com/images/prod/features/home/homepage-may-12.jpeg"
                />
                {languageReferences("")}
            </Helmet>
            <OrganizationSnippet/>
            <WebPageSnippet/>
            <div className={"home-banner-container"}>
                <div className="home-banner">
                    <div className='banner-content'>
                        <div className="banner-inner">
                            <div className={"banner-text " + ((hasEvent && false) ? "with-event" : "")}>
                                <HomeTitle/>
                                <div className="t2">{t("home/hero/lead")}</div>
                                <LocaleLink to={"/articles/matthys-wines"} className="btn-banner">
                                    {t("btn/learnMore")}
                                    <FontAwesomeIcon className="ml-2" icon={faAngleRight}/>
                                </LocaleLink>
                            </div>
                        </div>
                    </div>
                    <div className="banner-feature-logo">
                        <div className="grid-xl container">
                            <div className="goforest-feature">
                                <a className="goforest-badge" href="https://impact.gofamily.be/en/partners/matthys-wijnen" target="_blank">
                                    <div className="goforest-logo"/>
                                    <div className="goforest-statistic">
                                        {t("home/hero/treesPlanted", "000")}
                                    </div>
                                    <div className="goforest-label">
                                        {t("home/hero/treesPlantedLabel", "trees planted")}
                                    </div>
                                </a>
                                <a className="goforest-badge" href="https://impact.gofamily.be/en/partners/matthys-wijnen" target="_blank">
                                    <div className="goforest-logo"/>
                                    <div className="goforest-statistic">
                                        {t("home/hero/tonnesOfCO2", "000.00")}
                                    </div>
                                    <div className="goforest-label">
                                        {t("home/hero/tonnesOfCO2Label", "tonnes of CO₂")}
                                        <br/>
                                        {t("home/hero/tonnesOfCO2DuringLifeTime", "absorbed during lifetime")}
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <video autoPlay muted loop playsInline
                           poster="https://storage.googleapis.com/static.matthys-wines.com/videos/homepage-banner-11aug2024.jpg">
                        <source
                            src="https://storage.googleapis.com/static.matthys-wines.com/videos/homepage-banner-11aug2024.mp4"
                            type="video/mp4"/>
                    </video>
                </div>
            </div>
            {hasEvent ? (
                <div className="announcement-span">
                    {t("announcement/title")}
                </div>
            ) : null}
            <div className="container grid-xl mb-2 ">
                <section className="home-tiles-wrapper">
                    <div className="home-tiles">
                        <HomeTile
                            title={t("home/wineList/title")}
                            lead={t("home/wineList/lead")}
                            to={"/wines"}
                            type={"wine-list"}
                        />
                        <HomeTile
                            title={t("home/discoveryCases/title")}
                            lead={t("home/discoveryCases/lead")}
                            to={"/discovery-cases"}
                            type={"discovery-cases"}
                        />
                        <HomeTile
                            title={t("home/events/title")}
                            lead={t("home/events/lead")}
                            to={"/articles/events"}
                            type={"events"}
                        />
                    </div>
                </section>
                <LazyLoad once height={110}>
                    <SectionWineStyle
                        primary={true}
                        wait={pending}
                        count={filterOptions !== undefined ? filterOptions.wineTypes : undefined}
                    />
                </LazyLoad>
                <LazyLoad once height={400}>
                    <SectionCountries
                        wait={pending}
                        count={filterOptions !== undefined ? filterOptions.countries : undefined}
                    />
                </LazyLoad>
                <LazyLoad once height={400}>
                    <section className="mt-2 home-wine-showcase">
                        <div className="section-header">
                            <h2>{t("home/newArrivals")}</h2>
                        </div>
                        <WineListShowcase identifier={"newArrivals"}/>
                    </section>
                </LazyLoad>
                <LazyLoad once height={100}>
                    <SectionAgriculture
                        wait={pending}
                        count={filterOptions !== undefined ? filterOptions.agriculture : undefined}
                    />
                </LazyLoad>
                <LazyLoad once height={400}>
                    <SectionWineStyle
                        primary={false}
                        wait={pending}
                        count={filterOptions !== undefined ? filterOptions.wineStyles : undefined}
                    />
                </LazyLoad>
            </div>
        </div>
    )
}

export default Home
