import React, {FC, useCallback} from "react"
import {Auth0Provider} from "@auth0/auth0-react"
import {NavigateFunction, useNavigate} from "react-router-dom"

interface Auth0AppProps {
    callback: (navigate: NavigateFunction) => void
    children?: React.ReactNode
}

const Auth0App: FC<Auth0AppProps> = ({children, callback}) => {
    const navigate = useNavigate()
    const onRedirectCallback = useCallback(() => {
        callback(navigate)
    }, [navigate])
    return (
        <Auth0Provider
            domain="matthys-wines.eu.auth0.com"
            clientId="OmVRsDMxDO0EKRnne6SfGproVqYuxsg4"
            redirectUri={`${window.location.origin}/authorize`}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    )
}
export default Auth0App