import React, {FC} from "react"
import FormHoreca from "../_components/forms/FormHoreca"
import {useTranslation} from "react-i18next"

const HorecaFormPage: FC = () => {
    const {t} = useTranslation("common")
    return (
        <div className="container grid-sm header-margin container-main">
            <h1>{t("horeca/formTitle")}</h1>
            <p>{t("horeca/formLead")}</p>
            <FormHoreca/>
        </div>
    )
}

export default HorecaFormPage