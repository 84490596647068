import React, {FC} from "react"
import {ReactComponent as Bottle} from "./bottle.svg"

const LoaderContent: FC = () => {
    return (
        <div className="loader-content">
            <Bottle className={"loader-bottle"}/>
        </div>
    )
}

export default LoaderContent