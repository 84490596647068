import React, {FC} from "react"
import "./CheckoutSteps.scss"
import {useTranslation} from "react-i18next"
import {faCheck} from "@fortawesome/free-solid-svg-icons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

const CheckoutStep: FC<{ name: string, active: number, step: number }> = ({name, active, step}) => {

    return (
        <div className={"checkout-step " + ((active === step) ? "active" : "")}>
            <span className="checkout-check">
                {step < active ? (
                    <FontAwesomeIcon style={{"color": "#7d765a"}} icon={faCheck}/>
                ) : step}
            </span>
            {name}
        </div>
    )
}

const CheckoutSteps: FC<{ active: number, delivery: boolean }> = ({active, delivery}) => {

    const {t} = useTranslation("checkout")

    if (delivery) {
        return (
            <div className="steps-container">
                <CheckoutStep step={1} name={t("step/select", "Select Wines")} active={active}/>
                <CheckoutStep step={2} name={t("step/order", "Place Order")} active={active}/>
                <CheckoutStep step={3} name={t("step/pay", "Pay Online")} active={active}/>
                <CheckoutStep step={4} name={t("step/delivery", "Delivered")} active={active}/>
            </div>
        )
    } else {
        return (
            <div className="steps-container">
                <CheckoutStep step={1} name={t("step/select", "Select Wines")} active={active}/>
                <CheckoutStep step={2} name={t("step/order", "Place Order")} active={active}/>
                <CheckoutStep step={3} name={t("step/collect", "Collect in Shop")} active={active}/>
            </div>
        )
    }

}

export default CheckoutSteps