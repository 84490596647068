import React, {FC, useEffect, useState} from "react"
import {Route, Routes, useLocation} from "react-router-dom"
import CheckoutCallbackPage from "./CheckoutCallbackPage"
import LocaleRedirect from "../_components/localization/LocaleRedirect"
import queryString from "query-string"
import {SchemaOrder, SchemaOrderDraft} from "../_types/types"
import {serviceOrderFetch} from "../_services/order.service"
import Loader from "../_components/Loader/Loader"
import {useTranslation} from "react-i18next"
import NotFoundCatch from "../_components/NotFound/NotFoundCatch"
import * as Sentry from "@sentry/browser"
import CheckoutPage from "./CheckoutPage"
import CheckoutProcessingPage from "./CheckoutProcessingPage"
import WaitForAuthRoute from "../_components/authentication/WaitForAuthRoute"
import {PaymentMethodOnline} from "./_components/CheckoutPayment/CheckoutPayment"

export interface CheckoutWithOrderProps {
    order: SchemaOrder
    setOrder: (m: ((p: SchemaOrder) => SchemaOrder)) => void
    token: string
}

const CheckoutOrderWrapper: FC = () => {

    const {t} = useTranslation("checkout")

    const {pathname} = useLocation()
    const [error, setError] = useState<any>()
    const [busy, setBusy] = useState<boolean>(true)
    const [lastPath, setLastPath] = useState<string>()
    const [shippingMethod, setShippingMethod] = useState<string>("")
    const [order, setOrder] = useState<SchemaOrder>({} as SchemaOrder)
    const [orderNumber, setOrderNumber] = useState<number>()
    const [token, setToken] = useState<string>()

    useEffect(() => {

        setBusy(true)
        setLastPath(pathname)

        const query = queryString.parse(window.location.search)

        if (typeof query["o"] !== "string" || typeof query["t"] !== "string") {
            setError("Invalid query parameters for checkout.")
            setBusy(false)
        } else {

            // Retrieve query variables
            const queryToken: string = query["t"]
            const queryOrderNumber: number = parseInt(query["o"])
            setToken(queryToken)
            setOrderNumber(queryOrderNumber)

            // Retrieve order
            serviceOrderFetch(queryOrderNumber, queryToken)
                .json<SchemaOrderDraft>()
                .then(order => {
                    const billingAddress = {
                        "@type": "Address",
                        "addressCountry": "",
                        "addressLocality": "",
                        "streetAddress": "",
                        "postalCode": "",
                        "alternateName": "",
                    }
                    const customer = {
                        "affiliation": {
                            "@type": "Organization",
                            "name": "",
                            "vatID": "",
                        },
                        "email": "",
                        "familyName": "",
                        "givenName": "",
                        "telephone": "",
                    }
                    const deliveryAddress = order.orderDelivery.deliveryAddress || {
                        "@type": "PostalAddress",
                        "addressCountry": "",
                        "addressLocality": "",
                        "alternateName": "",
                        "identifier": "",
                        "name": "",
                        "postalCode": "",
                        "streetAddress": "",
                    }
                    setShippingMethod(order.orderDelivery.hasDeliveryMethod["@type"] === "OnSitePickup" ?
                        "PICKUP" : (order.orderDelivery.deliveryAddress?.addressCountry || ""))
                    setOrder({
                        ...order,
                        "isGift": false,
                        billingAddress,
                        "paymentMethod": order.paymentMethod ? order.paymentMethod : PaymentMethodOnline,
                        customer,
                        "orderDelivery": {
                            ...order.orderDelivery,
                            deliveryAddress,
                        },
                    })
                })
                .catch(error => {
                    Sentry.captureException(error)
                    setError(error)
                })
                .finally(() => setBusy(false))
        }
    }, [pathname])

    if (busy || lastPath !== pathname)
        return <Loader/>

    if (error) {
        console.warn(error)
        return <LocaleRedirect to={"/cart"}/>
    }

    if (!orderNumber || !token)
        return <NotFoundCatch/>

    return (
        <Routes>
            <Route
                path="/"
                element={(
                    <WaitForAuthRoute>
                        <CheckoutPage shippingMethod={shippingMethod} order={order} setOrder={setOrder} token={token}/>
                    </WaitForAuthRoute>
                )}
            />
            <Route
                path="/payment"
                element={<CheckoutProcessingPage order={order} setOrder={setOrder} token={token}/>}
            />
            <Route element={<NotFoundCatch/>}/>
        </Routes>
    )
}

const CheckoutRoutes: FC = () => (
    <Routes>
        <Route path="/callback" element={<CheckoutCallbackPage/>}/>
        <Route path="/*" element={<CheckoutOrderWrapper/>}/>
        <Route element={<NotFoundCatch/>}/>
    </Routes>
)

export default CheckoutRoutes
