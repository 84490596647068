import React, {FC} from "react"
import Loader from "../Loader/Loader"
import {useTranslation} from "react-i18next"
import {useAuth0} from "@auth0/auth0-react"
import EmptyPage from "../../Empty/EmptyPage"
import {encodeBase64} from "../../_helpers/encoding"
import {useLocation} from "react-router-dom"

const PrivateRoute: FC<{ title?: string, children?: React.ReactNode }> = ({children, title}) => {

    const {t, i18n} = useTranslation("common")
    const {isAuthenticated, isLoading, loginWithRedirect} = useAuth0()
    const {pathname} = useLocation()

    if (isLoading) {
        return <Loader/>
    } else if (isAuthenticated) {
        return <>{children}</>
    } else {
        return (
            <div>
                <EmptyPage
                    title={title || "Not Authorized"}
                    lead={t("authorize/pleaseSignIn")}
                    iconClass=""
                    actionText={t("btn/login")}
                    onClick={() => {
                        const pathParts = pathname.split("/")
                        pathParts.splice(0, 2)
                        const pathNoLang = "/" + pathParts.join("/")
                        loginWithRedirect({
                            "redirectUri": `${window.location.origin}/authorize?lng=${i18n.language}&r=${encodeBase64(pathNoLang)}`,
                        })
                    }}
                />
            </div>
        )
    }
}

export default PrivateRoute