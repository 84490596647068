import React, {FC, useEffect, useState} from "react"
import {useLocation} from "react-router-dom"
import ky from "ky"
import {useManyDocuments} from "../_services/mercury.service"
import {TypeFoodType} from "../_types/new-types"
import Loader from "../_components/Loader/Loader"
import ErrorPage from "../Error/ErrorPage"
import LocaleRedirect from "../_components/localization/LocaleRedirect"
import LocaleLink from "../_components/localization/LocaleLink"
import WinePairingStepper from "./WinePairingStepper"
import {useTranslation} from "react-i18next"
import {PAIRING_URL} from "../_helpers/environment"

interface CookingMethodsResponse {
    cookingMethods: string[]
    branches: number
}

const WinePairingCookingMethod: FC = () => {

    const {t} = useTranslation("store")
    const {search} = useLocation()
    const [loading, setLoading] = useState<boolean>(true)
    const [valid, setValid] = useState<string[]>([])
    const [branches, setBranches] = useState<number>(-1)
    const [error, setError] = useState<any>()
    let nextLink = "/food-match/agriculture" + search
    if (!search) {
        nextLink += "?"
    }

    useEffect(() => {
        ky.get(`${PAIRING_URL}/cooking-methods${search}`).json<CookingMethodsResponse>()
            .then(result => {
                setValid(result.cookingMethods)
                setBranches(result.branches)
            })
            .catch(err => setError(err))
            .finally(() => setLoading(false))
    }, [search])

    const foodTypes = useManyDocuments<TypeFoodType>("/cooking-methods")

    if (loading || foodTypes.isLoading) {
        return <Loader/>
    }

    if (foodTypes.error || error) {
        return <ErrorPage error={error || foodTypes.error}/>
    }

    if (branches === 0) {
        return <LocaleRedirect to={"/food-match/start"}/>
    }

    if (branches === 1) {
        const url = valid[0]
        return <LocaleRedirect to={nextLink + "&cookingMethod=" + url.split("/").pop()}/>
    }

    return (
        <>
            <div className="header-title">{t("foodMatch/headerCookingMethod")}</div>
            <div className="food-match-button-list">
                {foodTypes.items.filter(ft => {
                    return valid.includes(ft["@id"])
                }).map(ft => (
                    <LocaleLink
                        to={nextLink + "&cookingMethod=" + ft["@id"].split("/").pop()}
                        className="btn-generic"
                        key={ft.identifier}
                    >
                        {ft.name}
                    </LocaleLink>
                ))}
            </div>
            <WinePairingStepper step={4}/>
        </>
    )
}

export default WinePairingCookingMethod
