import React, {FC, useEffect, useState} from "react"
import "./WineCatalogPage.scss"
import {useTranslation} from "react-i18next"
import {Helmet} from "react-helmet"
import {BASE_URL, CATALOG_URL} from "../_helpers/environment"
import i18next from "i18next"
import {languageReferences} from "../_components/annotations/Languages"
import CatalogFilterColumn, {WineListFilter} from "./_components/CatalogFilterColumn"
import {WineListContainer} from "./_components/WineListContainer"
import ky from "ky"
import {CatalogFilterTree} from "../_types/requests"
import Loader from "../_components/Loader/Loader"
import ErrorPage from "../Error/ErrorPage"
import LoaderContent from "../_components/Loader/LoaderContent"
import {useLocation, useNavigate} from "react-router-dom"
import {Mutex} from "async-mutex"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faFilter} from "@fortawesome/free-solid-svg-icons"

let fetchTimeout: any = -1
let isCancelled: boolean = false
let fetchLock = new Mutex()

const stringifyFilters = (filter: WineListFilter): string => {
    let queries: string[] = []
    Object.keys(filter).forEach((k: string) => {
        // @ts-ignore
        if (!filter[k])
            return console.warn(`filter ${k} is invalid`)
        // @ts-ignore
        if (filter[k].length === 0)
            return
        if (k === "price" && (filter[k][0] === undefined && filter[k][1] === undefined))
            return
        // @ts-ignore
        queries.push(`${k}=${filter[k].join(",")}`)
    })
    return queries.join("&")
}

const parseFilters = (xs: string): WineListFilter => {
    const props = xs.split("&")
    const filter: WineListFilter = {
        "grapes": [],
        "wineStyles": [],
        "wineTypes": [],
        "countries": [],
        "agings": [],
        "bottleCaps": [],
        "agriculture": [],
        "price": [undefined, undefined],
        "vintage": [],
        "inStock": [],
    }
    props.forEach((p: string) => {
        const ps = p.split("=")
        if (ps.length !== 2)
            return
        if (ps[0] === "price")
            return
        // @ts-ignore
        filter[ps[0]] = ps[1].split(",")
    })
    return filter
}

const WineCatalogPage: FC = () => {

    const navigate = useNavigate()
    const {search} = useLocation()
    const {t} = useTranslation("store")
    const [initOpts, setInitOpts] = useState<CatalogFilterTree>()
    const [options, setOptions] = useState<CatalogFilterTree>()
    const [busy, setBusy] = useState<boolean>(false)
    const [update, setUpdate] = useState<boolean>(false)
    const [error, setError] = useState<any>()
    const [showFilters, setShowFilters] = useState<boolean>(false)
    const [filter, setFilter] = useState<WineListFilter>({
        "grapes": [],
        "wineStyles": [],
        "wineTypes": [],
        "countries": [],
        "agings": [],
        "bottleCaps": [],
        "agriculture": [],
        "price": [undefined, undefined],
        "vintage": [],
        "inStock": [],
    })

    useEffect(() => {
        if (update)
            navigate("?" + stringifyFilters(filter))
    }, [filter, update])

    useEffect(() => {
        const xs = search.replace("?", "")
        if (xs === stringifyFilters(filter))
            return
        setFilter(parseFilters(xs))
    }, [search])

    useEffect(() => {
        setUpdate(true)
    }, [filter])

    useEffect(() => {
        ky.get(`${CATALOG_URL}/filter/wines`).json<CatalogFilterTree>()
            .then(res => setInitOpts(res))
            .catch(err => setError(err))
    }, [])

    useEffect(() => {
        if (fetchTimeout !== -1)
            clearTimeout(fetchTimeout)
        isCancelled = false
        setBusy(true)
        fetchTimeout = setTimeout(() => {
            fetchLock.acquire()
                .then(release => {
                    if (isCancelled) {
                        release()
                        return
                    }
                    ky.get(`${CATALOG_URL}/filter/wines?${stringifyFilters(filter)}`)
                        .json<CatalogFilterTree>()
                        .then(res => setOptions(res))
                        .catch(err => setError(err))
                        .finally(() => {
                            setBusy(false)
                            release()
                        })
                })
        }, 300)
        return () => {
            isCancelled = true
        }
    }, [filter])

    if (error !== undefined) {
        return <ErrorPage error={error}/>
    }

    if (initOpts === undefined || options === undefined) {
        return <Loader/>
    }

    let numberOfWines = 0
    for (let k in options.wines) {
        numberOfWines += Object.values(options.wines[k]).length
    }

    return (
        <div className="container header-margin" id="wineListPage">

            <Helmet>
                <title>{t("wineList/title")}</title>
                <meta name="description" content={t("wineList/description")}/>
                <link rel="canonical" href={`${BASE_URL}/${i18next.language}/wines`}/>
                {languageReferences("/wines")}
            </Helmet>

            <h1>{t("wineList/title")}</h1>

            <div className="filter-header">
                {showFilters ? (
                    <button type="button" className="btn btn-primary" onClick={() => setShowFilters(p => !p)}>
                        {t("foodMatch/showWines_AMOUNT", "Show %AMOUNT wines").replace("%AMOUNT", `${numberOfWines}`)}
                    </button>
                ) : (
                    <button type="button" className="btn btn-primary" onClick={() => setShowFilters(p => !p)}>
                        {t("wineList/filter", "Filter")}
                        <FontAwesomeIcon icon={faFilter} className="ml-2" style={{"fontSize": "0.7rem"}}/>
                    </button>
                )}
            </div>

            <div className="columns">
                <div className={"column listing-filters col-3 col-xl-4 col-sm-12 " + (showFilters ? "visible" : "")}>
                    <h2>
                        {t("wineList/wineFilters", "Wine Filters")}
                    </h2>
                    <CatalogFilterColumn initial={initOpts} options={options} setFilters={setFilter} filters={filter}/>
                </div>
                <div className={"column listing-products col-9 col-xl-8 col-md-12"}>
                    {busy ? (
                        <LoaderContent/>
                    ) : (
                        <WineListContainer options={options}/>
                    )}
                </div>

            </div>

        </div>
    )
}


export default WineCatalogPage
