import React, {FC} from "react"
import PropertyFilter from "./PropertyFilter"
import {
    useAgings,
    useAgriculture,
    useBottleCaps,
    useCategories,
    useCountries,
    useGrapes,
} from "../../_hooks/product.hook"
import {useTranslation} from "react-i18next"
import ErrorToast from "../../Error/ErrorToast"
import {SchemaCategory, SchemaPropertyValue} from "../../_types/types"
import LoaderSection from "../../_components/Loader/LoaderSection"
import PropertyRangeFilter from "./PropertyRangeFilter"

interface FilterProps<T> {
    values: T
    onChange: (values: T) => void
    show?: { [key: string]: number }
    count?: { [key: string]: number }
}

export const CountryFilter: FC<FilterProps<string[]>> = props => {
    const {t} = useTranslation("store")
    const {list, busy, error} = useCountries()
    if (busy) return <LoaderSection transparent={true} height={240}/>
    if (error) return <ErrorToast error={error}/>
    return (
        <PropertyFilter
            options={list}
            title={t("countries/title")}
            name="countries"
            iconGroup="countries"
            getName={country => country.name}
            search={true}
            {...props}
        />
    )
}


export const InStockFilter: FC<FilterProps<string[]>> = props => {
    const {t} = useTranslation("store")
    return (
        <PropertyFilter
            options={props.show ? Object.keys(props.show) : ["true", "false"]}
            title={t("inStock/title", "Availability")}
            name="inStock"
            excluded={["false"]}
            getName={v => v === "true" ? t("inStock/true", "In Stock") : v}
            {...props}
        />
    )
}

export const AgricultureFilter: FC<FilterProps<string[]>> = props => {
    const {t} = useTranslation("store")
    const {list, busy, error} = useAgriculture()
    if (busy) return <LoaderSection transparent={true} height={240}/>
    if (error) return <ErrorToast error={error}/>
    return (
        <PropertyFilter
            options={list}
            title={t("agriculture/title")}
            name="agriculture"
            iconGroup="agriculture"
            getName={agriculture => agriculture.value}
            comparator={(a: SchemaPropertyValue, b: SchemaPropertyValue) => {
                const map: { [key: string]: number } = {
                    "biodynamic": 4,
                    "organic": 3,
                    "organic_nc": 2,
                    "conventional": 1,
                }
                let as = 0
                if (a.identifier && map[a.identifier])
                    as = map[a.identifier]
                let bs = 0
                if (b.identifier && map[b.identifier])
                    bs = map[b.identifier]
                return bs - as
            }}
            {...props}
        />
    )
}

export const TypeFilter: FC<FilterProps<string[]>> = props => {
    const {t} = useTranslation("store")
    const {list, busy, error} = useCategories()
    if (busy) return <LoaderSection transparent={true} height={240}/>
    if (error) return <ErrorToast error={error}/>
    return (
        <PropertyFilter
            options={list}
            title={t("wineType/title", "Wine Type")}
            comparator={(a: SchemaCategory, b: SchemaCategory) => {
                return a.order - b.order
            }}
            name="categories"
            excluded={["5e9c34675e6d9d79b851ca2f"]}
            iconGroup="wine-types"
            getName={category => category.name}
            {...props}
        />
    )
}


export const StyleFilter: FC<FilterProps<string[]>> = props => {
    const {t} = useTranslation("store")
    const {list, busy, error} = useCategories()
    if (busy) return <LoaderSection transparent={true} height={240}/>
    if (error) return <ErrorToast error={error}/>
    return (
        <PropertyFilter
            options={list}
            title={t("wineStyles/title")}
            comparator={(a: SchemaCategory, b: SchemaCategory) => {
                return a.order - b.order
            }}
            name="categories"
            iconGroup="wine-categories"
            getName={category => category.name}
            {...props}
        />
    )
}

export const GrapesFilter: FC<FilterProps<string[]>> = props => {
    const {t} = useTranslation("store")
    const {list, busy, error} = useGrapes()
    if (busy) return <LoaderSection transparent={true} height={240}/>
    if (error) return <ErrorToast error={error}/>
    return (
        <PropertyFilter
            options={list}
            title={t("grapes/title")}
            name="grapes"
            getName={grape => grape.name.toLowerCase()}
            search={true}
            {...props}
        />
    )
}

export const BottleCapsFilter: FC<FilterProps<string[]>> = props => {
    const {t} = useTranslation("store")
    const {list, busy, error} = useBottleCaps()
    if (busy) return <LoaderSection transparent={true} height={240}/>
    if (error) return <ErrorToast error={error}/>
    return (
        <PropertyFilter
            options={list}
            title={t("bottleClosure/title")}
            name="bottleCaps"
            iconGroup="bottle-caps"
            getName={bottleCap => bottleCap.value}
            {...props}
        />
    )
}

export const AgingsFilter: FC<FilterProps<string[]>> = props => {
    const {t} = useTranslation("store")
    const {list, busy, error} = useAgings()
    if (busy) return <LoaderSection transparent={true} height={240}/>
    if (error) return <ErrorToast error={error}/>
    return (
        <PropertyFilter
            options={list}
            title={t("ageings/title")}
            name="agings"
            getName={agings => agings.value}
            {...props}
        />
    )
}

interface FilterRangeProps {
    values: [number | undefined, number | undefined]
    onChange: (values: [number | undefined, number | undefined]) => void
    bounds: [number, number]
}

export const PriceFilter: FC<FilterRangeProps> = props => {
    const {t} = useTranslation("store")
    return (
        <PropertyRangeFilter
            title={t("priceRange/title")}
            {...props}
        />
    )
}

export const VintageFilter: FC<FilterProps<string[]>> = props => {
    const {t} = useTranslation("store")
    return (
        <PropertyFilter
            options={props.show ? Object.keys(props.show) : []}
            title={t("vintageRange/title")}
            name="agings"
            getName={v => v}
            {...props}
        />
    )
}