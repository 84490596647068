import * as React from "react"
import {FC, useMemo} from "react"
import {useParams} from "react-router-dom"
import ReactMarkdown from "react-markdown"
import "./ArticlePage.scss"
import Loader from "../_components/Loader/Loader"
import {useTranslation} from "react-i18next"
import Snippet from "../_components/annotations/Snippet"
import {Helmet} from "react-helmet"
import {BASE_URL} from "../_helpers/environment"
import i18next from "i18next"
import {languageReferences} from "../_components/annotations/Languages"
import LazyLoad from "react-lazyload"
import ErrorPage from "../Error/ErrorPage"
import ArticleImageBanner from "./_components/ArticleImageBanner"
import {MercuryOptions, useManyDocuments} from "../_services/mercury.service"
import {TypeArticle} from "../_types/new-types"
import NotFoundCatch from "../_components/NotFound/NotFoundCatch"
import {WineListShowcaseById} from "../_components/WineShowcase/WineShowcase"
import remarkGfm from "remark-gfm"
import FormEventRegistration from "../_components/forms/FormEventRegistration"

const ArticlePage: FC = () => {

    const {id} = useParams<{ id: string }>()
    const {t} = useTranslation("common")

    const articleOptions = useMemo<MercuryOptions>(() => ({
        "filter": {"identifier": id},
    }), [id])
    const {
        items,
        "isLoading": busy,
        error,
    } = useManyDocuments<TypeArticle>("/articles", articleOptions)

    if (busy) return <Loader/>
    if (error) return <ErrorPage error={error}/>
    if (items.length === 0) return <NotFoundCatch/>

    const article = items[0]

    let callToAction = null

    let ctaText = article.callToAction
    let matches = ctaText.match(/\[(.+)]\((.+)\)/)
    if (matches && matches.length === 3) {
        callToAction = <a href={matches[2]} className="btn btn-bold">{matches[1]}</a>
    }

    return (
        <div className='blog-article'>
            <Helmet>
                <title>{article.headline || "Matthys Wines: Excellence in wines"}</title>
                <meta name="description" content={article.description || article.articleBody.substring(0, 100)}/>
                <link rel="canonical" href={`${BASE_URL}/${i18next.language}/articles/${article.identifier}`}/>
                <meta name="keywords" content={article.keywords}/>
                {languageReferences(`/articles/${article.identifier}`)}
            </Helmet>
            <Snippet data={{...article, "@context": "http://schema.org", "url": window.location.href.split("?")[0]}}/>
            {(article.image && article.image.contentUrl) ? (
                <ArticleImageBanner
                    title={article.headline}
                    author={article.author.name}
                    image={article.image.contentUrl}
                />
            ) : null}
            <div className='container grid-sm article-body'>
                <ReactMarkdown
                    children={article.articleBody}
                    remarkPlugins={[remarkGfm]}
                />
                {<LazyLoad once>
                    {(article.wineList) ? (
                        <WineListShowcaseById id={article.wineList["@id"]}/>
                    ) : null}
                </LazyLoad>}
                {callToAction}
                {article.name.toLocaleLowerCase().indexOf("event ") !== -1 ? (
                    <div>
                        <h2>{t("registration/title")}</h2>
                        <FormEventRegistration name={article.name}/>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default ArticlePage
