import React, {FC} from "react"
import {SchemaPostalAddress} from "../../_types/types"
import FormInput from "../form-inputs/FormInput"
import FormSelectCountry from "../form-inputs/FormSelectCountry"
import {useTranslation} from "react-i18next"
import {replaceStreetName, replaceStreetNumber, splitAddress} from "../../_helpers/address-format"

interface Props {
    setAddress: (mod: (address: SchemaPostalAddress) => SchemaPostalAddress) => void
    address: SchemaPostalAddress
}

const FormShipping: FC<Props> = ({setAddress, address}) => {
    const {t} = useTranslation("form")
    return (
        <div>
            <FormInput
                label={t("label/addressee")}
                type={"text"}
                id={"shippingAddressee"}
                value={address.name}
                onChange={v => setAddress(s => ({...s, "name": v}))}
            />
            <FormInput
                label={t("label/organization")}
                type={"text"}
                id={"shippingOrganization"}
                value={address.alternateName}
                onChange={v => setAddress(s => ({...s, "alternateName": v}))}
            />
            <div className="columns">
                <div className="column col-8 col-md-12">
                    <FormInput
                        label={t("label/streetName")}
                        type={"text"}
                        id={"shippingStreetName"}
                        value={splitAddress(address.streetAddress).streetName}
                        onChange={v => setAddress(prev => ({
                            ...prev,
                            "streetAddress": replaceStreetName(prev.streetAddress, v),
                        }))}
                        required={true}
                    />
                </div>
                <div className="column col-4 col-md-12">
                    <FormInput
                        label={t("label/streetNumber")}
                        type={"text"}
                        id={"shippingStreetNumber"}
                        value={splitAddress(address.streetAddress).streetNumber}
                        onChange={v => setAddress(prev => ({
                            ...prev,
                            "streetAddress": replaceStreetNumber(prev.streetAddress, v),
                        }))}
                        required={true}
                    />
                </div>
            </div>
            <div className="columns">
                <div className="column col-6">
                    <FormInput
                        label={t("label/city")}
                        type={"text"}
                        id={"billingCity"}
                        value={address.addressLocality}
                        onChange={v => setAddress(s => ({...s, "addressLocality": v}))}
                    />
                </div>
                <div className="column col-6">
                    <FormInput
                        label={t("label/postalCode")}
                        type={"text"}
                        id={"billingPostalCode"}
                        value={address.postalCode}
                        onChange={v => setAddress(s => ({...s, "postalCode": v}))}
                    />
                </div>
            </div>
            <FormSelectCountry
                id={"billingCountry"}
                label={t("label/country")}
                value={address.addressCountry}
                onChange={v => setAddress(s => ({...s, "addressCountry": v}))}
            />
        </div>
    )
}

export default FormShipping