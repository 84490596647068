import React, {FC, Suspense, useEffect, useState} from "react"
import {Helmet} from "react-helmet"
import LanguageSuggestion from "../_components/localization/LanguageSuggestion"
import {Route, Routes} from "react-router-dom"
import Header from "../_components/Header/Header"
import AppRoutes from "./AppRoutes"
import Footer from "../_components/Footer/Footer"
import CookiesDisclaimer from "../_components/CookiesDisclaimer/CookiesDisclaimer"
import {useTranslation} from "react-i18next"
import i18next from "i18next"
import {BASE_URL} from "../_helpers/environment"
import Snippet from "../_components/annotations/Snippet"
import CheckoutRoutes from "../Checkout/CheckoutRoutes"
import Loader from "../_components/Loader/Loader"
import LazyLoad from "react-lazyload"

const SITE_DESCRIPTION_ROOT: any = {
    "en": "The most trendsetting wine merchant in Europe. Buy online from +30 countries. Safe delivery to all EU countries.",
    "nl": "De meest trendsettende wijnhandel in België en Nederland. Online kopen uit +30 landen vanaf €150 gratis verzending of ophalen in wijnwinkel te Brugge.",
    "fr": "Le commerce du vin le plus innovant en Belgique et en France. Achetez en ligne dans +30 pays. A partir de 150 euros livraison gratuits ou ramasser dans nos magasin à Bruges.",
}

const HomeHead: FC = () => {
    const {t} = useTranslation("common")
    const title = t("site/title", {"defaultValue": "Matthys Wines: Excellence in wines"})
    const description = t("site/description", {"defaultValue": SITE_DESCRIPTION_ROOT[i18next.language] || SITE_DESCRIPTION_ROOT["en"]})
    const canonical = `${BASE_URL}/${i18next.language}`
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description}/>
                <link rel="canonical" href={canonical}/>
            </Helmet>
            <Snippet data={{
                "@context": "https://schema.org",
                "@type": "WebSite",
                "@id": "https://www.matthys-wines.com/#website",
                "name": "Matthys Wines",
                "url": "https://www.matthys-wines.com/",
            }}/>
        </>
    )
}

const NonHomeRouter: FC = () => {

    const [footerVisible, setFooterVisible] = useState(false)

    useEffect(() => {
        // Delay the visibility of the footer
        const timer = setTimeout(() => {
            setFooterVisible(true)
        }, 1000) // Adjust the delay as needed

        return () => clearTimeout(timer)
    }, [])

    return (
        <Suspense fallback={<Loader/>}>
            <Routes>
                <Route path="/checkout" element={<CheckoutRoutes/>}/>
                <Route path="/*" element={(
                    <>
                        <Header/>
                        <AppRoutes/>
                        {footerVisible && (
                            <LazyLoad height={340} once>
                                <Footer/>
                            </LazyLoad>
                        )}
                    </>
                )}/>
            </Routes>
        </Suspense>
    )
}

const ClientApp: FC = () => {
    return (
        <div id="app">
            <LanguageSuggestion/>
            <HomeHead/>
            <CookiesDisclaimer/>
            <NonHomeRouter/>
        </div>
    )
}

export default ClientApp
