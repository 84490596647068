import React, {FC} from "react"
import LocaleLink from "../../_components/localization/LocaleLink"

interface HomeTileProps {
    title: string
    lead: string
    to: string
    type: string
}

const HomeTile: FC<HomeTileProps> = props => (
    <LocaleLink to={props.to} className={"home-tile home-tile-" + props.type}>
        <div className="home-tile-content">
            <div className="tile-content-wrapper">
                <div className="home-tile-title">{props.title}</div>
                <div className="home-tile-lead">{props.lead}</div>
            </div>
        </div>
    </LocaleLink>
)

export default HomeTile