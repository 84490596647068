import React, {FC, FunctionComponent} from "react"
import LocaleLink from "../../_components/localization/LocaleLink"
import notFound from "../../Wine/_components/no-wine-01.svg"
import LazyLoad from "react-lazyload"
import {
    EmbeddedImageObject,
    TypeCategory,
    TypeNamedThing,
    TypeOffer,
    TypePropertyValue,
    TypeReference,
    TypeThing,
} from "../../_types/new-types"

interface WineListItemProps {
    wine: TypeWineListItem
    included: { [key: string]: TypeThing }
}

export type TypeWineListItem = {
    agriculture: TypeReference | null
    bottleCap: TypeReference | null
    category: TypeReference | null
    identifier: string
    image: EmbeddedImageObject | null
    offers: TypeOffer
} & TypeNamedThing

const WineListItem: FC<WineListItemProps> = (props => {
    return (
        <div className="single-product">
            <LocaleLink to={"/wines/" + props.wine.identifier}>
                {props.wine.image ? (
                    <LazyLoad once height={240}>
                        <img
                            alt={props.wine.name}
                            className="product-image"
                            src={props.wine.image.contentUrl}
                        />
                    </LazyLoad>
                ) : (
                    <img
                        alt={"Not found"}
                        className="product-image"
                        src={notFound}
                    />
                )}
                <div className="product-title">{props.wine.name}</div>
                <div className="product-chin">
                    <div className="product-features">
                        <WineListItemFeatures wine={props.wine} included={props.included}/>
                    </div>
                    <div className="product-price">&euro;{props.wine.offers.integerPrice / 100}</div>
                </div>
            </LocaleLink>
        </div>
    )
})

interface WineListItemFeaturesProps {
    included: { [key: string]: TypeThing }
    wine: TypeWineListItem
}

const WineListItemFeatures: FunctionComponent<WineListItemFeaturesProps> = ({included, wine}) => {
    const category = wine.category !== null ? (included[wine.category["@id"]] as TypeCategory) : null
    const bottleCap = wine.bottleCap !== null ? (included[wine.bottleCap["@id"]] as TypePropertyValue) : null
    const agriculture = wine.agriculture !== null ? (included[wine.agriculture["@id"]] as TypePropertyValue) : null
    return (
        <>
            {category !== null && category !== undefined ? (
                <span
                    data-tooltip={category.name}
                    className={`title-icon tooltip icon-wine-categories-${category.identifier}`}
                />
            ) : null}
            {bottleCap !== null && bottleCap !== undefined ? (
                <span
                    data-tooltip={bottleCap.name}
                    className={`title-icon tooltip icon-bottle-caps-${bottleCap.identifier}`}
                />
            ) : null}
            {agriculture !== null && agriculture !== undefined ? (
                <span
                    data-tooltip={agriculture.name}
                    className={`title-icon tooltip icon-agriculture-${agriculture.identifier}`}
                />
            ) : null}
        </>
    )
}

export default WineListItem
