import React, {FC, useEffect, useState} from "react"
import {useLocation} from "react-router-dom"
import ky from "ky"
import Loader from "../_components/Loader/Loader"
import ErrorPage from "../Error/ErrorPage"
import LocaleLink from "../_components/localization/LocaleLink"
import {useSingleWine} from "../_hooks/product.hook"
import "./WinePairingResults.scss"
import {findIncludedById, findManyIncludedById} from "../_helpers/included"
import {TypeAppellation, TypeCategory, TypeGrape, TypeManufacturer, TypePropertyValue} from "../_types/new-types"
import {useTranslation} from "react-i18next"
import ProductProperty from "../Wine/_components/ProductProperty"
import LocaleRedirect from "../_components/localization/LocaleRedirect"
import {PAIRING_URL} from "../_helpers/environment"

interface WineResultBlockProps {
    id: string
}

const includes = {
    "": "aging,agriculture,appellation,bottleCap,category,manufacturer,material",
    "appellation": "addressCountry",
}

const WineResultBlock: FC<WineResultBlockProps> = React.memo(({id}) => {

    const {wine, busy, error, included} = useSingleWine(id.split("/").pop() || "", includes)

    const {t} = useTranslation("store")

    if (busy || wine === undefined) {
        return <Loader/>
    }

    if (error) {
        console.log(error)
        return null
    }

    const manufacturer = findIncludedById<TypeManufacturer>(wine.manufacturer, included)
    const appellation = findIncludedById<TypeAppellation>(wine.appellation, included)
    const country = findIncludedById<TypeAppellation>(appellation?.addressCountry, included)
    const agriculture = findIncludedById<TypePropertyValue>(wine.agriculture, included)
    const category = findIncludedById<TypeCategory>(wine.category, included)
    const grapes = findManyIncludedById<TypeGrape>(wine.material, included)

    return (
        <div className="wine-card">
            <div className="product-chips">
                <div className="product-chip product-country">
                    <i style={{"backgroundImage": `url("/icons/countries/${country?.identifier}.svg")`}}/>
                    <span>{country?.name}</span>
                </div>
                {grapes.length === 1 ? (
                    <div className="product-chip">
                        <i className="grapes"/>
                        <span>{grapes.map(grape => grape.name).join(", ")}</span>
                    </div>
                ) : null}
            </div>
            <div className="wine-img" style={{"backgroundImage": `url("${wine.image?.contentUrl}")`}}/>
            <div>
                <LocaleLink
                    to={"/wines/" + wine.identifier}
                    target={"_blank"}
                    rel={"noreferrer"}
                    className="wine-title"
                >
                    {wine.name}
                </LocaleLink>
            </div>
            <div className="card-divider hide-md"/>
            <table className='product-properties'>
                <tbody>
                <ProductProperty title={t("wine/price")}>
                    &euro;{(wine.offers.integerPrice / 100).toFixed(2)}
                </ProductProperty>
                <ProductProperty title={t("wine/manufacturer")}>
                    {manufacturer?.url ? (
                        <a href={manufacturer?.url} rel="noopener noreferrer" target="_blank">
                            {manufacturer?.name}
                        </a>
                    ) : (manufacturer?.name)}
                </ProductProperty>
                <ProductProperty
                    title={t("wine/wineStyle")}
                    icon={"/wine-categories/" + category?.identifier + ".svg"}
                >
                </ProductProperty>
                <ProductProperty title={t("wine/vintage")}>
                    {wine.vintage !== 0 ? wine.vintage : t("wine/noVintage")}
                </ProductProperty>
                <ProductProperty
                    icon={"/agriculture/" + agriculture?.identifier + ".svg"}
                    title={t("wine/agriculture")}
                >
                </ProductProperty>
                <ProductProperty title={t("wine/alcoholByVolume")}>
                    {wine.alcoholByVolume}%
                </ProductProperty>
                </tbody>
            </table>
            {wine.description ? (
                <>
                    <div className="card-divider hide-md"/>
                    <div className="card-description hide-md">
                        {wine.description}
                    </div>
                </>
            ) : null}
            <LocaleLink
                to={"/wines/" + wine.identifier}
                target={"_blank"}
                rel={"noreferrer"}
                className="btn btn-primary"
                style={{"marginTop": "1rem"}}
            > {t("btn/learnMore", {"ns": "common"})}</LocaleLink>
        </div>
    )
})

const WinePairingResults: FC = () => {

    const {t} = useTranslation("store")
    const {search} = useLocation()
    const [loading, setLoading] = useState<boolean>(true)
    const [valid, setValid] = useState<string[]>([])
    const [picks, setPicks] = useState<number[]>([])
    const [error, setError] = useState<any>()
    const [showcase, setShowcase] = useState<string[]>([])
    const [randomize, setRandomize] = useState<boolean>(false)

    useEffect(() => {
        if (randomize) {
            setRandomize(false)
            const indices = []
            for (let i = 0; i < valid.length; i++) {
                indices.push(i)
            }
            setPicks(indices.sort((a, b) => 0.5 - Math.random()))
        }
    }, [valid, randomize])

    useEffect(() => {
        ky.get(`${PAIRING_URL}/wines${search}`).json<{ "wines": string[] }>()
            .then(result => {
                setValid(result.wines)
                setRandomize(true)
            })
            .catch(err => setError(err))
            .finally(() => setLoading(false))
    }, [search])

    useEffect(() => {
        const results = []
        for (let j = 0; j < Math.min(3, picks.length); j++) {
            if (j >= valid.length) {
                break
            }
            results.push(valid[picks[j]])
        }
        setShowcase(results)
    }, [valid, picks])

    if (loading) {
        return <Loader/>
    }

    if (error) {
        return <ErrorPage error={error}/>
    }

    if (valid.length === 0) {
        return <LocaleRedirect to={"/food-match/start"}/>
    }

    return (
        <>
            <div className="header-title">{t("foodMatch/ourRecommendations")}</div>
            <div className="wine-results">
                {showcase.map(id => (
                    <WineResultBlock key={id} id={id}/>
                ))}
            </div>
            <div style={{"marginTop": "2rem", "marginBottom": "2rem"}}>
                <LocaleLink to={"/food-match/start"} className="btn mr-2">{t("btn/restartFoodMatch")}</LocaleLink>
                <button className="btn" onClick={() => setRandomize(true)}>{t("btn/generateNewList")}</button>
            </div>
        </>
    )
}

export default WinePairingResults
