import React, {FC} from "react"
import LocaleLink from "../_components/localization/LocaleLink"
import {useTranslation} from "react-i18next"

const WinePairingIntro: FC = () => {
    const {t} = useTranslation("store")
    return (
        <div className="food-match-banner-container">
            <div className="food-match-banner">
                <div className="banner-cover">
                    <div className="banner-content">
                        <h1>{t("foodMatch/title")}</h1>
                        <p>{t("foodMatch/lead")}</p>
                        <LocaleLink to={"/food-match/wine-style"} className="btn btn-primary">
                            {t("btn/startFoodMatch")}
                        </LocaleLink>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WinePairingIntro
