import {SchemaReference} from "../_types/types"

export const findIncludedById = <T>(ref?: SchemaReference | null, included?: any[]): (T | null) => {
    if (!ref || !included) {
        return null
    }
    for (let i = 0; i < included.length; i++) {
        if (included[i]["@id"] && included[i]["@id"] === ref["@id"]) {
            return included[i]
        }
    }
    return null
}

export const findManyIncludedById = <T>(refs?: SchemaReference[] | null, included?: any[]): T[] => {
    if (!refs || !included) {
        return []
    }
    const results: T[] = []
    refs.forEach(r => {
        const res = findIncludedById<T>(r, included)
        if (res) {
            results.push(res)
        }
    })
    return results
}
