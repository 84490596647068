import React, {FC} from "react"
import {SchemaOrderPerson, SchemaPostalAddress} from "../../../_types/types"
import FormInput from "../../../_components/form-inputs/FormInput"
import FormSelectCountry from "../../../_components/form-inputs/FormSelectCountry"
import {useTranslation} from "react-i18next"
import {getName} from "country-list"
import {replaceStreetName, replaceStreetNumber, splitAddress} from "../../../_helpers/address-format"

interface Props {
    person: SchemaOrderPerson,
    setPerson: (m: (prev: SchemaOrderPerson) => SchemaOrderPerson) => void
    address: SchemaPostalAddress,
    setAddress: (m: (prev: SchemaPostalAddress) => SchemaPostalAddress) => void
    isCompany: boolean
    required: boolean
    lockCountry: boolean
}

const CheckoutBillingForm: FC<Props> = ({person, setPerson, address, setAddress, isCompany, required, lockCountry}) => {

    const {t} = useTranslation("form")

    return (
        <div className="card-unit card-mb checkout-billing-form">
            <h4>{t("form/billing/title")}</h4>
            <div className="columns">
                <div className="column col-6 col-xs-12">
                    <FormInput
                        id={"billingFirstName"}
                        label={t("label/firstName")}
                        type={"text"}
                        value={person.givenName}
                        onChange={v => setPerson(prev => ({...prev, "givenName": v}))}
                        required={true}
                        placeholder={t("placeholder/firstName", "")}
                    />
                </div>
                <div className="column col-6 col-xs-12">
                    <FormInput
                        id={"billingLastName"}
                        label={t("label/lastName")}
                        type={"text"}
                        value={person.familyName}
                        onChange={v => setPerson(prev => ({...prev, "familyName": v}))}
                        required={true}
                        placeholder={t("placeholder/lastName", "")}
                    />
                </div>
            </div>
            <FormInput
                id={"billingCompany"}
                placeholder={t("placeholder/company", "") + (!isCompany ? (` (${t("placeholder/optional")})`) : "")}
                label={t("label/company")}
                required={isCompany}
                type={"text"}
                value={person.affiliation.name}
                onChange={v => setPerson(prev => ({...prev, "affiliation": {...prev.affiliation, "name": v}}))}
            />
            <div className="columns">
                <div className="column col-8 col-md-12">
                    <FormInput
                        label={t("label/streetName")}
                        type={"text"}
                        id={"billingStreetName"}
                        value={splitAddress(address.streetAddress).streetName}
                        onChange={v => setAddress(prev => ({
                            ...prev,
                            "streetAddress": replaceStreetName(prev.streetAddress, v),
                        }))}
                        placeholder={t("placeholder/streetName", "") + (!(isCompany || required) ? (` (${t("placeholder/optional")})`) : "")}
                        required={required || isCompany}
                    />
                </div>
                <div className="column col-4 col-md-12">
                    <FormInput
                        label={t("label/streetNumber")}
                        type={"text"}
                        id={"billingStreetNumber"}
                        value={splitAddress(address.streetAddress).streetNumber}
                        onChange={v => setAddress(prev => ({
                            ...prev,
                            "streetAddress": replaceStreetNumber(prev.streetAddress, v),
                        }))}
                        placeholder={t("placeholder/streetNumber", "") + (!(isCompany || required) ? (` (${t("placeholder/optional")})`) : "")}
                        required={required || isCompany}
                    />
                </div>
            </div>
            <div className="columns">
                <div className="column col-6 col-md-12">
                    <FormInput
                        id={"billingCity"}
                        label={t("label/city")}
                        type={"text"}
                        placeholder={t("placeholder/addressLocality", "") + (!(isCompany || required) ? ` (${t("placeholder/optional")})` : "")}
                        value={address.addressLocality}
                        onChange={v => setAddress(prev => ({...prev, "addressLocality": v}))}
                        required={required || isCompany}
                    />
                </div>
                <div className="column col-6 col-md-12">
                    <FormInput
                        id={"billingPostalCode"}
                        label={t("label/postalCode")}
                        type={"text"}
                        placeholder={t("placeholder/addressLocality", "") + (!(isCompany || required) ? ` (${t("placeholder/optional")})` : "")}
                        value={address.postalCode}
                        onChange={v => setAddress(prev => ({...prev, "postalCode": v}))}
                        required={required || isCompany}
                    />
                </div>
            </div>
            <div className="columns">
                <div className="column col-6 col-md-12">
                    <FormInput
                        id={"billingTelephone"}
                        label={t("label/telephone")}
                        placeholder={t("placeholder/telephone", "")}
                        type={"tel"}
                        value={person.telephone}
                        onChange={v => setPerson(prev => ({...prev, "telephone": v}))}
                        required={true}
                    />
                </div>
                <div className="column col-6 col-md-12">
                    {lockCountry ? (
                        <div className="form-group">
                            <label className="form-label" htmlFor="billingCountry">
                                {t("label/country")}
                            </label>
                            <input
                                className="form-input disabled"
                                type="text"
                                disabled={true}
                                value={getName(address.addressCountry) || ""}
                                id={"billingCountry"}
                            />
                        </div>
                    ) : (
                        <FormSelectCountry
                            id={"billingCountry"}
                            label={t("label/country")}
                            className={lockCountry ? "disabled" : ""}
                            value={address.addressCountry}
                            onChange={v => setAddress(prev => ({...prev, "addressCountry": v}))}
                            required={required || isCompany}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default CheckoutBillingForm