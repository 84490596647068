import React, {FC, useEffect, useMemo} from "react"
import {useSingleDiscoveryCase} from "../_hooks/product.hook"
import Loader from "../_components/Loader/Loader"
import "./WineCasePage.scss"
import LocaleLink from "../_components/localization/LocaleLink"
import Snippet from "../_components/annotations/Snippet"
import {Helmet} from "react-helmet"
import {BASE_URL} from "../_helpers/environment"
import i18next from "i18next"
import {languageReferences} from "../_components/annotations/Languages"
import ErrorPage from "../Error/ErrorPage"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faAngleLeft, faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons"
import {findIncludedById} from "../_helpers/included"
import {TypeAppellation, TypeCategory, TypePropertyValue, TypeReference, TypeWine} from "../_types/new-types"
import {useDocument} from "../_services/mercury.service"
import ProductPriceToCart from "../_components/products/ProductPriceToCart"
import {useParams} from "react-router-dom"

const CaseWineRow: FC<{ reference: TypeReference }> = ({reference}) => {

    let query = useMemo(() => [
        "include=agriculture,appellation,bottleCap,category",
        "include[appellation]=addressCountry",
        "fields=name,agriculture,appellation,bottleCap,category,image,identifier,description",
    ], [])
    const {
        "document": wine,
        "isLoading": busy,
        error,
    } = useDocument<TypeWine & { "@included": any[] }>(reference["@id"], query)

    if (busy || error || !wine) {
        return null
    }

    const included = wine["@included"]
    const appellation = findIncludedById<TypeAppellation>(wine.appellation, included)
    const country = findIncludedById<TypeAppellation>(appellation?.addressCountry, included)
    const agriculture = findIncludedById<TypePropertyValue>(wine.agriculture, included)
    const bottleCap = findIncludedById<TypePropertyValue>(wine.bottleCap, included)
    const category = findIncludedById<TypeCategory>(wine.category, included)

    return (
        <div className="column col-6 col-md-12">
            <LocaleLink to={`/wines/${wine.identifier}`} target="_blank" className="case-wine">
                <div className="case-wine-image"
                     style={{"backgroundImage": `url("${(wine.image?.contentUrl)}")`}}/>
                <div className="case-wine-body">
                    <div className="case-wine-name">
                        <span className={`icon-country icon-countries-${country?.identifier}`}/>
                        {wine.name}
                        <span className="external-icon">
                            <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2"/>
                        </span>
                    </div>
                    {wine.description ? (
                        <div className="case-wine-description">
                            {wine.description}
                        </div>
                    ) : null}
                    <div className="case-wine-features">
                    <span data-tooltip={category?.name?.split("/").pop()}
                          className={`icon-wine-category tooltip icon-wine-categories-${category?.identifier}`}/>
                        <span data-tooltip={bottleCap?.value}
                              className={`icon-wine-category tooltip icon-bottle-caps-${bottleCap?.identifier}`}/>
                        <span data-tooltip={agriculture?.value}
                              className={`icon-wine-category tooltip icon-agriculture-${agriculture?.identifier}`}/>
                    </div>
                </div>
            </LocaleLink>
        </div>
    )
}

const WineCasePage: FC = () => {

    const {id} = useParams<{ id: string }>()
    const {discoveryCase, busy, error} = useSingleDiscoveryCase(id || "") // TODO

    // Log discovery case view
    useEffect(() => {
        if (busy || error || !discoveryCase) return
        // @ts-ignore
        window.dataLayer.push({
            "event": "productDetails",
            "ecommerce": {
                "currencyCode": "EUR",
                "detail": {
                    "products": [{
                        "name": discoveryCase.name,
                        "id": discoveryCase["@id"]?.split("/").pop(),
                        "price": discoveryCase.offers?.integerPrice ? (discoveryCase.offers.integerPrice / 100) : undefined,
                    }],
                },
            },
        })
    }, [busy, discoveryCase, error])

    if (error) return <ErrorPage error={error}/>

    if (busy) return <Loader/>

    let availability = discoveryCase.offers?.availability.split("-").map(v => v.charAt(0).toUpperCase() + v.slice(1)).join("") || "OutOfStock"
    availability = "https://schema.org/" + availability

    return (
        <div className="container grid-xl" id="discoveryCase">
            <Snippet data={{
                "@context": "https://schema.org/",
                "@type": "Product",
                "name": discoveryCase.name,
                "image": discoveryCase.image?.thumbnail,
                "description": discoveryCase.description?.substring(0, 120),
                "sku": discoveryCase["@id"]?.split("/").pop(),
                "brand": {
                    "@type": "Brand",
                    "name": "Matthys Wines",
                },
                "offers": {
                    "@type": "Offer",
                    "url": `${BASE_URL}/${i18next.language}/wines/${discoveryCase["@id"]?.split("/").pop()}`,
                    "priceCurrency": "EUR",
                    "price": discoveryCase.offers.integerPrice ? (discoveryCase.offers.integerPrice / 100).toFixed(2) : "",
                    "availability": availability,
                    "seller": {
                        "@type": "Organization",
                        "name": "Matthys Wines",
                    },
                },
            }}/>
            <Helmet>
                <title>{discoveryCase.name}</title>
                <meta name="description" content={discoveryCase.description?.substring(0, 120)}/>
                <link
                    rel="canonical"
                    href={`${BASE_URL}/${i18next.language}/discovery-cases/${discoveryCase["@id"]?.split("/").pop()}`}
                />
                {languageReferences(`/discovery-cases/${discoveryCase["@id"]?.split("/").pop()}`)}
            </Helmet>

            <div className="header-margin mb-1">
                <LocaleLink to={"/discovery-cases"}>
                    <FontAwesomeIcon icon={faAngleLeft} className="mr-2" style={{"fontSize": "0.6rem"}}/>
                    Back to Discovery Cases
                </LocaleLink>
            </div>

            <div className="columns case-wine-header">
                <div className="column col-7 col-lg-8 col-mx-auto col-md-10 col-sm-12">
                    <div className="case-container">
                        {discoveryCase.image ? (
                            <div
                                className="case-image"
                                style={{"backgroundImage": `url("${(discoveryCase.image.contentUrl)}")`}}
                            />
                        ) : null}
                    </div>
                </div>
                <div className="column col-5 col-lg-8 col-mx-auto col-md-10 col-sm-12">
                    <div className="case-about">
                        <div className="case-about-body">
                            <h1>{discoveryCase.name}</h1>
                            {discoveryCase.description.length > 0 ? (
                                <div className="case-description">
                                    {discoveryCase.description}
                                </div>
                            ) : null}
                            <ProductPriceToCart
                                hideQuantity={true}
                                defaultQuantity={1}
                                productId={discoveryCase["@id"]}
                                isArchived={discoveryCase.isArchived}
                                offer={discoveryCase.offers}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="columns case-wine-list">
                {discoveryCase.wines.map(wine => <CaseWineRow key={wine["@id"]} reference={wine}/>)}
            </div>
        </div>
    )
}

export default WineCasePage
