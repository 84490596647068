import React, {FC, ReactNode, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import ky from "ky"
import {API_URL} from "../../_helpers/environment"
import {encodeBase64} from "../../_helpers/encoding"
import {customHeader} from "../../_helpers/auth-header"
import {useWines} from "../../_hooks/product.hook"
import {useAuth0} from "@auth0/auth0-react"
import ProductTile from "../../_components/products/ProductTile"

interface FavoritesListProps {
    ids: string[]
}

const FavoritesList: FC<FavoritesListProps> = ({ids}) => {

    const {t} = useTranslation("account")
    const {"list": wines} = useWines(200, ids)

    const nodes: ReactNode[] = []
    wines.forEach(wine => {
        nodes.push(
            <ProductTile
                key={wine["@id"]}
                product={wine}
            />,
        )
    })

    for (let i = 0; i < 4; i++) {
        nodes.push(<div key={i} className="product-tile-empty product-tile"/>)
    }

    return (
        <div className="product-related">
            <h2 className="text-center">{t("account/favorites", "Favorites")}</h2>
            <div className="product-related-grid">
                {nodes}
            </div>
        </div>
    )
}

const AccountFavorites: FC = () => {

    const [favorites, setFavorites] = useState<string[]>([])

    const {getAccessTokenSilently, user, isLoading} = useAuth0()

    useEffect(() => {
        if (isLoading) return
        getAccessTokenSilently()
            .then(t => {
                ky.get(`${API_URL}/accounts/${encodeBase64(user?.email || "")}/favorites`,
                    {"headers": customHeader(t)}).json<{ products: string[] }>()
                    .then(result => setFavorites(result.products))
                    .catch(() => null)
            })
    }, [getAccessTokenSilently, isLoading, user])

    if (!favorites.length) return null

    return (
        <FavoritesList ids={favorites}/>
    )
}

export default AccountFavorites
