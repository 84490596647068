import React, {FC, useCallback, useEffect, useState} from "react"
import {CheckoutWithOrderProps} from "./CheckoutRoutes"
import {useTranslation} from "react-i18next"
import {SchemaOrder, SchemaPostalAddress, UserCheckoutOptions} from "../_types/types"
import Loader from "../_components/Loader/Loader"
import CheckoutEmailForm from "./_components/CheckoutAddress/CheckoutEmailForm"
import CheckoutBillingForm from "./_components/CheckoutAddress/CheckoutBillingForm"
import CheckoutShippingForm from "./_components/CheckoutAddress/CheckoutShippingForm"
import CheckoutCompanyForm from "./_components/CheckoutAddress/CheckoutCompanyForm"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faAngleRight, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons"
import CheckoutSteps from "./_components/CheckoutSteps/CheckoutSteps"
import CheckoutPayment from "./_components/CheckoutPayment/CheckoutPayment"
import {useAuth0} from "@auth0/auth0-react"
import CheckoutSummary from "./_components/CheckoutSummary/CheckoutSummary"
import "./CheckoutPage.scss"
import logo from "./logo.svg"
import LocaleLink from "../_components/localization/LocaleLink"
import {OrderRequestResult, serviceOrderConfirm, serviceOrderUpdate} from "../_services/order.service"
import NotFoundCatch from "../_components/NotFound/NotFoundCatch"
import {useUserBillingAddress, useUserOptions, useUserShippingAddress} from "../_hooks/user.hook"
import CheckoutGiftForm from "./_components/CheckoutGift/CheckoutGiftForm"
import {useNavigate} from "react-router-dom"
import {isValidPhoneNumber} from "react-phone-number-input"

const placeOrder = async (order: SchemaOrder, t1: string) => {
    const {"orderToken": t2} = await serviceOrderUpdate(order, t1).json<OrderRequestResult>()
    const {"orderToken": t3, orderNumber} = await serviceOrderConfirm(order, t2).json<OrderRequestResult>()
    return `/checkout/payment?t=${t3}&o=${orderNumber}`
}

type Props = CheckoutWithOrderProps & { shippingMethod: string }

const CheckoutPage: FC<Props> = ({order, token, setOrder, shippingMethod}) => {

    // useCheckoutAnalytics(2); TODO fix analytics

    const navigate = useNavigate()
    const {t, i18n} = useTranslation("checkout")
    const {isAuthenticated, user} = useAuth0()
    const [saveToAccount, setSaveToAccount] = useState<boolean>(false)
    const userBA = useUserBillingAddress()
    const userSA = useUserShippingAddress(0)
    const userOpts = useUserOptions<UserCheckoutOptions>({
        "useBillingAsShipping": 1,
        "useInvoice": 0,
    })
    const [options, setOptions] = useState<UserCheckoutOptions>({
        "useBillingAsShipping": 1,
        "useInvoice": 0,
    })
    const [busy, setBusy] = useState<boolean>(false)
    const [error, setError] = useState<any>()
    const [warning, setWarning] = useState<any>()

    // use email from authentication
    useEffect(() => {
        if (!isAuthenticated) return
        setOrder(p => ({...p, "customer": {...p.customer, "email": user?.email || ""}}))
    }, [setOrder, user, isAuthenticated])

    // load billing and shipping address + user options
    useEffect(() => {
        if (userOpts.busy || userBA.busy || userSA.busy) return
        setOptions(userOpts.options)
        setOrder(p => ({...p, "customer": {...userBA.person, "email": p.customer?.email || userBA.person.email}}))
        setOrder(p => ({...p, "orderDelivery": {...p.orderDelivery, "deliveryAddress": {...userSA.address}}}))
    }, [userOpts, userBA, userSA, setOrder])

    // form submit callback
    const submit = useCallback(() => {
        setWarning(undefined)
        if (!order.customer.telephone) {
            setWarning(t("form/tel/missing"))
            return
        }
        if (!isValidPhoneNumber(order.customer.telephone)) {
            setWarning(t("form/tel/error"))
            return
        }

        // start loading
        setBusy(true)

        // save user details to cloud if requested
        if (saveToAccount && order.customer && order.billingAddress) {

            // save billing address
            userBA.setPerson({...order.customer, "address": order.billingAddress})
            userBA.push()

            // save shipping address and options
            if (!options.useBillingAsShipping && order.orderDelivery.deliveryAddress) {
                userSA.setAddress(order.orderDelivery.deliveryAddress)
                userSA.push()
            }

            // save options
            userOpts.setOptions(options)
            userOpts.push()

        }

        const deliveryAddress = options.useBillingAsShipping ? order.billingAddress : order.orderDelivery.deliveryAddress

        // set shipping type if not a pickup
        const payload: SchemaOrder = {
            ...order,
            "orderDelivery": {
                ...order.orderDelivery,
                "deliveryAddress": (shippingMethod === "PICKUP") ? undefined : deliveryAddress,
            },
        }

        // send order confirmation
        placeOrder(payload, token)
            .then(url => navigate(`/${i18n.language}${url}`))
            .catch(err => setError(err))
            .finally(() => setBusy(false))

    }, [saveToAccount, userBA, userSA, userOpts, token, options, shippingMethod, order])

    // lock countries to match shipping price
    useEffect(() => {
        if (!shippingMethod || shippingMethod === "PICKUP") {
            return
        }
        if (options.useBillingAsShipping) {
            if (order.billingAddress.addressCountry !== shippingMethod) {
                setOrder(p => ({...p, "billingAddress": {...p.billingAddress, "addressCountry": shippingMethod}}))
            }
        } else {
            if (!order.orderDelivery.deliveryAddress) {
                return
            }
            if (order.orderDelivery.deliveryAddress.addressCountry !== shippingMethod) {
                setOrder(p => ({
                    ...p,
                    "orderDelivery": {
                        ...p.orderDelivery,
                        "deliveryAddress": p.orderDelivery.deliveryAddress ? {
                            ...p.orderDelivery.deliveryAddress,
                            "addressCountry": shippingMethod,
                        } : undefined,
                    },
                }))
            }
        }
    }, [setOrder, order, options.useBillingAsShipping, shippingMethod])

    if (order.orderStatus?.["@type"]) return <NotFoundCatch/>

    if (!order.customer) return <Loader/>

    return (
        <div id="checkoutPage">
            {(busy || userBA.busy || userOpts.busy || userSA.busy) ? <Loader/> : null}
            <div className="checkout-logo">
                <div className="logo-img">
                    <img src={logo} alt={"Matthys Wines"} width={120}/>
                </div>
                <div className="logo-text">
                    Matthys Wines
                </div>
            </div>
            <div className="container grid-sm">
                <h1>{t("checkout/title", "Place your order")}</h1>
                <CheckoutSteps delivery={shippingMethod !== "PICKUP"} active={2}/>
                <form onSubmit={e => {
                    e.preventDefault()
                    submit()
                }}>
                    <CheckoutEmailForm
                        disabled={isAuthenticated}
                        person={order.customer}
                        onChange={m => setOrder(p => ({...p, "customer": m(p.customer)}))}
                        orderNumber={order.orderNumber}
                        token={token}
                    />
                    <CheckoutBillingForm
                        person={order.customer}
                        setPerson={m => setOrder(p => ({...p, "customer": m(p.customer)}))}
                        address={order.billingAddress}
                        setAddress={m => setOrder(p => ({...p, "billingAddress": m(p.billingAddress)}))}
                        required={shippingMethod !== "PICKUP"}
                        isCompany={Boolean(options.useInvoice)}
                        lockCountry={Boolean(options.useBillingAsShipping) && shippingMethod !== "PICKUP"}
                    />
                    <CheckoutGiftForm
                        isGift={order.isGift}
                        setGift={v => setOrder(p => ({...p, "isGift": v}))}
                        giftText={order.giftText}
                        setGiftText={v => setOrder(p => ({...p, "giftText": v}))}
                        giftTelephone={order.giftTelephone}
                        setGiftTelephone={v => setOrder(p => ({...p, "giftTelephone": v}))}
                    />
                    {(shippingMethod !== "PICKUP" && order.orderDelivery.deliveryAddress) ? (
                        <CheckoutShippingForm
                            address={order.orderDelivery.deliveryAddress}
                            onChange={m => setOrder(p => ({
                                ...p,
                                "orderDelivery": {
                                    ...p.orderDelivery,
                                    "deliveryAddress": m(p.orderDelivery.deliveryAddress || {} as SchemaPostalAddress),
                                },
                            }))}
                            hidden={options.useBillingAsShipping}
                            setHidden={v => setOptions(prev => ({...prev, "useBillingAsShipping": v}))}
                        />
                    ) : null}
                    <CheckoutCompanyForm
                        person={order.customer}
                        onChange={m => setOrder(p => ({...p, "customer": m(p.customer)}))}
                        visible={options.useInvoice}
                        setVisible={v => setOptions(prev => ({...prev, "useInvoice": v}))}
                    />
                    <CheckoutPayment
                        order={order}
                        setPaymentMethod={v => setOrder(p => ({...p, "paymentMethod": v}))}
                    />
                    <CheckoutSummary order={order}/>
                    {error ? (
                        <div className="toast toast-error my-2">
                            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2"/>
                            {error.toString()}
                        </div>
                    ) : null}
                    {warning ? (
                        <div className="toast toast-warning my-2">
                            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2"/>
                            {warning.toString()}
                        </div>
                    ) : null}
                    {isAuthenticated ? (
                        <div className="card-unit card-mb" style={{"paddingTop": "1rem", "paddingBottom": "1rem"}}>
                            <div className="form-group">
                                <label className="form-checkbox">
                                    <input
                                        type="checkbox"
                                        onChange={e => setSaveToAccount(e.target.checked)}
                                        checked={saveToAccount}
                                    />
                                    <i className="form-icon"/> {t("saveMyInformation", {"ns": "checkout"})}
                                </label>
                            </div>
                        </div>
                    ) : null}
                    <div className="columns">
                        <div className="column col-6">
                            <LocaleLink to={"/cart"}
                                        className="btn btn-link">{t("btn/returnToCart", {"ns": "common"})}</LocaleLink>
                        </div>
                        <div className="column col-6 text-right">
                            <button
                                type="submit"
                                className='btn btn-primary'
                            >
                                {t(order.paymentMethod?.identifier !== "cash" ? "btn/toPayment" : "btn/placeOrder", {"ns": "common"})}
                                <FontAwesomeIcon icon={faAngleRight} className="ml-2" style={{"fontSize": "0.68rem"}}/>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <div className="checkout-copyright">
                <div className="copyright-text">© Copyright {(new Date()).getFullYear()} Matthys Wijnimport nv</div>
                <LocaleLink target="_blank" to={"/articles/disclaimer"}>{t("footer/tc", {"ns": "common"})}</LocaleLink>
                <span className='foot-text-divider'>|</span>
                <LocaleLink target="_blank"
                            to={"/articles/privacy"}>{t("footer/privacy", {"ns": "common"})}</LocaleLink>
            </div>
        </div>
    )
}

export default CheckoutPage
