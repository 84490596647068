import React, {FunctionComponent, useState} from "react"
import {useTranslation} from "react-i18next"
import FormInput from "../form-inputs/FormInput"
import ky from "ky"
import {API_URL} from "../../_helpers/environment"
import {localizationHeaders} from "../../_helpers/auth-header"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck} from "@fortawesome/free-solid-svg-icons"
import * as Sentry from "@sentry/browser"
import Loader from "../Loader/Loader"

const FormHoreca: FunctionComponent = () => {

    const {t} = useTranslation("common")

    const [email, setEmail] = useState<string>("")
    const [restaurantName, setRestaurantName] = useState<string>("")
    const [locality, setLocality] = useState<string>("")
    const [busy, setBusy] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>()
    const [error, setError] = useState<any>()

    if (busy)
        return <Loader/>

    if (success)
        return (
            <div className="mt-2">
                <b>{t("horeca/success/title")}</b><FontAwesomeIcon icon={faCheck} className="ml-2"/><br/>
                {t("horeca/success/lead")}
            </div>
        )

    return (
        <form
            onSubmit={e => {
                e.preventDefault()
                setBusy(true)
                ky.post(API_URL + "/forms/horeca", {
                    "json": {email, locality, restaurantName},
                    "headers": localizationHeaders(),
                })
                    .then(() => setSuccess(true))
                    .catch(error => {
                        Sentry.captureException(error)
                        setError(error)
                    })
                    .finally(() => setBusy(false))
            }}
        >
            <FormInput
                label={t("label/email")}
                type={"email"}
                id={"horecaEmail"}
                value={email}
                required={true}
                onChange={v => setEmail(v)}
            />
            <FormInput
                label={t("label/restaurantName")}
                type={"text"}
                id={"horecaName"}
                value={restaurantName}
                required={true}
                onChange={v => setRestaurantName(v)}
            />
            <FormInput
                label={t("label/locality")}
                type={"text"}
                id={"horecaLocality"}
                value={locality}
                onChange={v => setLocality(v)}
                invalid={!!error}
                required={true}
                hint={error ? t("error/horecaForm", {"ns": "error"}) : undefined}
            />
            <button className="btn btn-primary" typeof="submit">{t("btn/horecaSend")}</button>
        </form>
    )
}

export default FormHoreca