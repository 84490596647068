import React, {FC, useEffect} from "react"
import "./App.scss"
import "./Buttons.scss"
import "./Cards.scss"
import "./Animations.scss"
import {Route, Routes, useLocation, useParams} from "react-router-dom"
import {getI18n} from "react-i18next"
import {Helmet} from "react-helmet"
import ClientApp from "./ClientApp"
import AppContext from "../_contexts/AppContext"
import NotFoundPage from "../Empty/NotFound/NotFoundPage"
import * as Sentry from "@sentry/browser"

const AppLanguage: FC<{ children?: React.ReactNode }> = ({children}) => {

    const i18n = getI18n()

    const params = useParams()
    const language = params["lng"] ? params["lng"] : "en"

    useEffect(() => {
        if (i18n && language !== i18n.language) {
            i18n.changeLanguage(language)
                .catch(err => {
                    Sentry.captureException(err)
                    console.log(err)
                })
        }
    }, [language, i18n])

    return (
        <>
            <Helmet htmlAttributes={{"lang": language}}/>
            {children}
        </>
    )
}

const AnalyticsPageView: FC = () => {

    const {pathname} = useLocation()

    useEffect(() => {
        window.dataLayer.push({
            "event": "pathname_change",
        })
    }, [pathname])

    return null
}

const App: FC = () => {
    return (
        <AppContext>
            <AppLanguage>
                <AnalyticsPageView/>
                <Routes>
                    <Route path="/404" element={<NotFoundPage/>}/>
                    <Route path="/*" element={<ClientApp/>}/>
                </Routes>
            </AppLanguage>
        </AppContext>
    )
}

export default App
