import React, {FunctionComponent} from "react"
import FormBilling from "../_components/forms/FormBilling"
import FormShipping from "../_components/forms/FormShipping"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCheck, faExclamationTriangle} from "@fortawesome/free-solid-svg-icons"
import LocaleLink from "../_components/localization/LocaleLink"
import {UserCheckoutOptions} from "../_types/types"
import Loader from "../_components/Loader/Loader"
import {useTranslation} from "react-i18next"
import FormOptions from "../_components/forms/FormOptions"
import {useUserBillingAddress, useUserOptions, useUserShippingAddress} from "../_hooks/user.hook"

const AccountAddress: FunctionComponent = () => {

    const tf = useTranslation("form").t
    const ta = useTranslation("account").t
    const tc = useTranslation("common").t

    const person = useUserBillingAddress()
    const address = useUserShippingAddress(0)
    const options = useUserOptions<UserCheckoutOptions>({
        "useInvoice": 0,
        "useBillingAsShipping": 1,
    })

    const loading = person.busy || address.busy || options.busy
    const success = person.success && address.success && options.success
    const error = person.error || address.error || options.error

    return (
        <div className="container grid-lg container-main header-margin" id="account">
            {loading ? <Loader/> : null}
            <ul className="breadcrumb m-breadcrumbs">
                <li className="breadcrumb-item">
                    <LocaleLink to="/account">{ta("nav/account")}</LocaleLink>
                </li>
                <li className="breadcrumb-item">
                    <LocaleLink to="/account/billing-and-shipping">{ta("nav/billingAndShipping")}</LocaleLink>
                </li>
            </ul>
            <h1>{ta("billingAndShipping/title")}</h1>
            <div className="columns">
                <div className="column col-6 col-md-12">
                    <div className="card-unit card-mb">
                        <h2>{ta("label/billing")}</h2>
                        <FormBilling setPerson={person.setPerson} person={person.person}/>
                    </div>
                </div>
                <div className="column col-6 col-md-12">
                    <div className="card-unit card-mb">
                        <h2>{ta("label/preferences")}</h2>
                        <FormOptions setOptions={options.setOptions} options={options.options}/>
                    </div>
                    <div
                        style={{"display": !options.options.useBillingAsShipping ? "block" : "none"}}
                        className="card-unit card-mb"
                    >
                        <h2>{ta("label/shipping")}</h2>
                        <FormShipping setAddress={address.setAddress} address={address.address}/>
                    </div>
                </div>
            </div>
            <div className="column col-6 col-md-12">
                {error ? (
                    <div className="toast toast-error my-2">
                        {error.toString()}
                        <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2"/>
                    </div>
                ) : null}
                {success ? (
                    <div className="toast toast-success my-2">
                        {tf("preferencesUpdated")}
                        <FontAwesomeIcon icon={faCheck} className="ml-2"/>
                    </div>
                ) : null}
                <button
                    className="btn btn-primary mt-2"
                    onClick={() => {
                        person.push()
                        address.push()
                        options.push()
                    }}
                >
                    {tc("btn/saveAll")} <FontAwesomeIcon icon={faCheck} className="ml-1"/>
                </button>
            </div>
        </div>
    )
}

export default AccountAddress
