import React, {FC} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faAngleRight} from "@fortawesome/free-solid-svg-icons"
import ReactMarkdown from "react-markdown"
import {useTranslation} from "react-i18next"
import LocaleLink from "../../_components/localization/LocaleLink"
import {TypeManufacturer, TypeWine} from "../../_types/new-types"
import LazyLoad from "react-lazyload"
import {API_URL} from "../../_helpers/environment"
import {encodeBase64} from "../../_helpers/encoding"
import {RelatedWines} from "../WineProductPage"

const EXPAND_KEY = "[Expand]"
const ORIGINAL_KEY = "[Original]"

const StoryVideo: FC<Props> = ({manufacturer}) => {

    if (!manufacturer.video)
        return null

    return (
        <div className="product-video">
            <iframe
                title="Wine Story Video"
                id="ytplayer"
                src={`https://www.youtube.com/embed/${manufacturer.video}?autoplay=0&origin=${process.env.REACT_APP_CANONICAL_URL}`}
                frameBorder="0"
            />
        </div>
    )
}

const StoryText: FC<Props> = ({manufacturer}) => {

    const {t} = useTranslation("store")

    const body: string = manufacturer.description

    let lead = ""
    let more = ""
    let origLead = ""
    let origMore = ""

    const expandIndex = body.indexOf(EXPAND_KEY)
    if (expandIndex !== -1) {
        lead = body.substring(0, expandIndex)
        more = body.substring(expandIndex + EXPAND_KEY.length)
    } else {
        more = body
    }

    const originalIndex = more.indexOf(ORIGINAL_KEY)
    if (originalIndex !== -1) {
        origLead = more.substring(originalIndex + ORIGINAL_KEY.length)
        const origExpandIndex = origLead.indexOf(EXPAND_KEY)
        if (origExpandIndex !== -1) {
            origMore = origLead.substring(origExpandIndex + EXPAND_KEY.length)
            origLead = origLead.substring(0, origExpandIndex)
        }
    }

    if (lead === "") {
        lead = more
    }

    const wineStoryPage = `/wine-stories/${manufacturer.identifier}`

    return (
        <div className='wine-story-text'>
            <h2>{t("wineStory/title")}</h2>
            <ReactMarkdown children={lead}/>
            {expandIndex !== -1 ? (
                <LocaleLink className="wine-story-link" to={wineStoryPage + "#original"}>
                    {t("btn/readMore", "Continue reading Wine Story")}
                    <FontAwesomeIcon icon={faAngleRight} className="ml-2" style={{"fontSize": "0.7rem"}}/>
                </LocaleLink>
            ) : null}
            {origLead !== "" ? (
                <>
                    <ReactMarkdown children={origLead}/>
                    {origMore !== "" ? (
                        <LocaleLink to={wineStoryPage + "#original"} className="wine-story-link">
                            {t("btn/readOriginal", "Read in original language")}
                            <FontAwesomeIcon icon={faAngleRight} className="ml-2" style={{"fontSize": "0.7rem"}}/>
                        </LocaleLink>
                    ) : null}
                </>
            ) : null}
            {manufacturer.founder?.image ? (
                <div className="wine-story-signature">
                    <img
                        src={manufacturer?.founder?.image?.contentUrl}
                        alt={manufacturer?.founder?.name}
                    />
                </div>
            ) : null}
            <div className="wine-story-signature-name">{manufacturer?.founder?.name}</div>
        </div>
    )
}

interface Props {
    manufacturer: TypeManufacturer
}

interface PropsStory {
    manufacturer: TypeManufacturer
    wine: TypeWine
}

export const WineProductStoryVertical: FC<PropsStory> = ({manufacturer, wine}) => {
    const {t} = useTranslation("store")
    return (
        <>
            <section className="columns">
                <div className="column col-sm-12 col-lg-8 col-mx-auto show-lg" style={{"marginBottom": "1rem"}}>
                    <StoryText manufacturer={manufacturer}/>
                    <StoryVideo manufacturer={manufacturer}/>
                </div>
                <div className="column col-6 col-lg-8 col-sm-12 col-mx-auto">
                    {manufacturer.image ? (
                        <section
                            className="wine-story-banner banner-vertical"
                            style={{"backgroundImage": `url("${manufacturer?.image?.contentUrl}")`}}
                        />
                    ) : null}
                    <LazyLoad once height={400}>
                        <RelatedWines
                            url={`${API_URL}/suggestions/manufacturers/${encodeBase64(manufacturer?.["@id"] || "")}`}
                            exclude={wine["@id"]}
                            list={true}
                        >
                            <div className="product-related-head">
                                <div className="product-related-title">
                                    {t("showcase/moreFrom/title_MANUFACTURER").replace("%MANUFACTURER", manufacturer?.name || "")}
                                </div>
                                <div className="product-related-lead">{t("showcase/moreFrom/lead")}</div>
                            </div>
                        </RelatedWines>
                    </LazyLoad>
                </div>
                <div className="column col-6 col-lg-8 col-sm-12 col-mx-auto hide-lg" style={{"marginBottom": "1rem"}}>
                    <StoryText manufacturer={manufacturer}/>
                </div>
            </section>
            <LazyLoad once height={400}>
                <RelatedWines url={`${API_URL}/suggestions/wines/${wine["@id"].split("/").pop()}`}
                              exclude={wine["@id"]}>
                    <div className="product-related-head">
                        <div className="product-related-title">{t("showcase/related/title")}</div>
                        <div className="product-related-lead">{t("showcase/related/lead")}</div>
                    </div>
                </RelatedWines>
            </LazyLoad>
        </>
    )
}

const WineProductStory: FC<PropsStory> = ({manufacturer, wine}) => {

    const {t} = useTranslation("store")

    return (
        <>
            {manufacturer.image ? (
                <section
                    className="wine-story-banner"
                    style={{"backgroundImage": `url("${manufacturer?.image?.contentUrl}")`}}
                >
                    <div className="banner-content">
                        {manufacturer?.name}
                    </div>
                </section>
            ) : null}
            <section className="columns">
                <div className="column col-6 col-lg-8 col-sm-12 col-mx-auto" style={{"marginBottom": "1rem"}}>
                    <StoryText manufacturer={manufacturer}/>
                    <StoryVideo manufacturer={manufacturer}/>
                </div>
                <div className="column col-6 col-lg-8 col-sm-12 col-mx-auto">
                    <LazyLoad once height={400}>
                        <RelatedWines
                            url={`${API_URL}/suggestions/manufacturers/${encodeBase64(manufacturer?.["@id"] || "")}`}
                            exclude={wine["@id"]}
                            list={true}
                        >
                            <div className="product-related-head">
                                <div className="product-related-title">
                                    {t("showcase/moreFrom/title_MANUFACTURER").replace("%MANUFACTURER", manufacturer?.name || "")}
                                </div>
                                <div className="product-related-lead">{t("showcase/moreFrom/lead")}</div>
                            </div>
                        </RelatedWines>
                    </LazyLoad>
                    <LazyLoad once height={400}>
                        <RelatedWines list={true} url={`${API_URL}/suggestions/wines/${wine["@id"].split("/").pop()}`}
                                      exclude={wine["@id"]}>
                            <div className="product-related-head">
                                <div className="product-related-title">{t("showcase/related/title")}</div>
                                <div className="product-related-lead">{t("showcase/related/lead")}</div>
                            </div>
                        </RelatedWines>
                    </LazyLoad>
                </div>
            </section>
        </>
    )
}

export default WineProductStory
