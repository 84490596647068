import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import "./WineCaseListPage.scss"
import {useCategories, useDiscoveryCases} from "../_hooks/product.hook"
import Loader from "../_components/Loader/Loader"
import LocaleLink from "../_components/localization/LocaleLink"
import {Helmet} from "react-helmet"
import {BASE_URL} from "../_helpers/environment"
import i18next from "i18next"
import {TypeDiscoveryCase, TypeOffer} from "../_types/new-types"

interface DiscoveryCaseTileProps {
    id: string
    name: string
    description: string
    imageURL: string
    wineCount: number
    offer: TypeOffer
}

const DiscoveryCaseTile: FC<DiscoveryCaseTileProps> = props => {

    const {t} = useTranslation("common")

    return (
        <LocaleLink to={`/discovery-cases/${props.id}`} className="case-tile">
            <div className="case-tile-image" style={{"backgroundImage": `url("${props.imageURL}")`}}/>
            <div className="case-tile-body hide-sm">
                <div className="case-tile-name">{props.name}</div>
                <div className="case-tile-description">{props.description}</div>
                <div className="case-tile-footer">
                    <div className="case-tile-properties">{props.wineCount} {t("wines")}</div>
                    <div className="case-tile-price">&euro;{(props.offer.integerPrice / 100).toFixed()}</div>
                </div>
            </div>
            <div className="case-tile-body show-sm">
                <div className="case-tile-mobile-header">
                    <div className="case-tile-name">{props.name}</div>
                    <div className="case-tile-price">&euro;{(props.offer.integerPrice / 100).toFixed()}</div>
                </div>
            </div>
        </LocaleLink>
    )
}

const WineCaseListPage: FC = () => {

    const {t} = useTranslation("store")

    const {"list": cases, "busy": l1} = useDiscoveryCases()
    const {"map": categoryMap, "busy": l2} = useCategories()

    if (l1 || l2) return <Loader/>

    // Categorize cases by their category
    const caseByCategory: { [key: string]: TypeDiscoveryCase[] } = {}
    cases.forEach(c => {
        let categoryId = ""
        if (c.isArchived) {
            return
        }
        if (c.category && c.category["@id"])
            categoryId = c.category["@id"]
        if (!caseByCategory[categoryId]) {
            caseByCategory[categoryId] = [c]
        } else {
            caseByCategory[categoryId].push(c)
        }
    })

    const categoryIdList = Array.from(Object.keys(caseByCategory))
    categoryIdList.sort((a, b) => {
        if (!categoryMap[a]) return -1
        if (!categoryMap[b]) return 1
        return categoryMap[a].order - categoryMap[b].order
    })

    return (
        <div className="container grid-xl header-margin" id="discoveryCases">
            <Helmet>
                <title>{t("case/title")}</title>
                <meta name="description" content={t("case/description")}/>
                <link rel="canonical" href={`${BASE_URL}/${i18next.language}/discovery-cases`}/>
                <link
                    rel="canonical"
                    href={`${BASE_URL}/${i18next.language}/discovery-cases`}
                />
            </Helmet>
            <h1>{t("case/title")}</h1>
            {categoryIdList.map(categoryId => {
                const category = categoryMap[categoryId]
                return (
                    <section key={"category-" + categoryId}>
                        {category ? <h2>{category.name}</h2> : null}
                        <div className="case-tile-container">
                            {caseByCategory[categoryId].sort((a, b) => {
                                if (!a.offers?.integerPrice) return -1
                                if (!b.offers?.integerPrice) return 1
                                return a.offers.integerPrice - b.offers.integerPrice
                            }).map(dCase => {
                                return (
                                    <DiscoveryCaseTile
                                        key={dCase["@id"]}
                                        id={(dCase["@id"] && dCase["@id"]?.split("/").pop()) || ""}
                                        name={dCase.name || "[no name]"}
                                        description={dCase.lead || ""}
                                        wineCount={dCase.wines.length}
                                        offer={dCase.offers}
                                        imageURL={dCase.image?.contentUrl || ""}
                                    />
                                )
                            })}
                            {[0, 1, 2, 3].map(i => <div key={i} className="case-tile-filler"/>)}
                        </div>
                    </section>
                )
            })}
        </div>
    )
}

export default WineCaseListPage
