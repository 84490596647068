import React, {FC, useEffect} from "react"
import {useTranslation} from "react-i18next"
import Loader from "../Loader/Loader"
import {useLocation} from "react-router-dom"
import ExpectedError from "../../Empty/ExpectedError"

const NotFoundCatch: FC = () => {
    const location = useLocation()
    const {i18n} = useTranslation("error")
    useEffect(() => {
        if (process.env.NODE_ENV !== "development") {
            window.location.replace(`/${i18n.language}/404`)
        }
    }, [location, i18n])
    if (process.env.NODE_ENV === "development") {
        return (
            <ExpectedError title={"Not found"} description={"Production would redirect to a 404"}/>
        )
    } else {
        return <Loader/>
    }
}

export default NotFoundCatch
