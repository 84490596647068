import React, {FC, ReactNode, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {CatalogFilterTree} from "../../_types/requests"
import {useCountries} from "../../_hooks/product.hook"
import {TypeCategory, TypeCountry, TypeThing} from "../../_types/new-types"
import {API_URL} from "../../_helpers/environment"
import {mercuryGet} from "../../_services/api.public.service"
import LoaderContent from "../../_components/Loader/LoaderContent"
import ErrorToast from "../../Error/ErrorToast"
import WineListItem, {TypeWineListItem} from "./WineListItem"
import {useNavigate} from "react-router-dom"

interface WineListContainerProps {
    options: CatalogFilterTree
}

export const WineListContainer: FC<WineListContainerProps> = ({options}) => {
    const {t} = useTranslation("store")

    const navigate = useNavigate()
    const {"list": countries, busy, error} = useCountries()
    const [listTree, setListTree] = useState<[TypeCountry, string[]][][]>([])
    const [visibleBlocks, setVisibleBlocks] = useState<number>(1)

    const results = options.wines
    const countryContainers: ReactNode[] = []

    if (error) return <ErrorToast error={error}/>

    useEffect(() => {

        setListTree([])
        setVisibleBlocks(1)

        if (busy || Object.keys(results).length === 0) return

        const keyLength = Object.keys(results)[0].length

        const countryEntries: TypeCountry[] = [...countries]
        countryEntries.sort((a: TypeCountry, b: TypeCountry) => {
            return a.name < b.name ? -1 : 1
        })

        const countryRefMap: string[] = []
        countryEntries.forEach((country, index) => {
            countryRefMap[index] = country["@id"].substring(country["@id"].length - keyLength)
        })

        const listPlan: [TypeCountry, string[]][][] = [[]]
        let lastBlock = 0
        let blockItems = 0
        for (let i = 0; i < countries.length; i++) {
            const shortId = countryRefMap[i]
            const country = countryEntries[i]
            const items: string[] | undefined = results[shortId]
            if (items === undefined)
                continue
            if (blockItems > 50) {
                lastBlock++
                listPlan.push([])
                blockItems = 0
            }
            blockItems += items.length
            listPlan[lastBlock].push([country, items])
        }

        setListTree(listPlan)

    }, [results, busy])

    // redirect to no filters when result returns no wines
    useEffect(() => {
        if (busy)
            return
        if (listTree.length === 0)
            navigate("?", {"replace": true})
    }, [listTree, navigate])

    if (busy) {
        return <LoaderContent/>
    }

    for (let ib = 0; ib < Math.min(visibleBlocks, listTree.length); ib++) {
        countryContainers.push((
            <WineListBlock key={`${ib}`} entries={listTree[ib]}/>
        ))
    }

    return (
        <div className="product-grid">
            {countryContainers}
            {visibleBlocks < listTree.length ? (
                <div className="expand-list-section">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => setVisibleBlocks(p => p + 1)}
                    >{t("btn/showMore", {"ns": "common"})}</button>
                </div>
            ) : null}
        </div>
    )
}

interface WineListBlockProps {
    entries: [TypeCountry, string[]][]
}

export const WineListBlock: FC<WineListBlockProps> = ({entries}) => {

    const [wines, setWines] = useState<{ [key: string]: TypeWineListItem }>({})
    const [included, setIncluded] = useState<{ [key: string]: TypeThing }>({})
    const [error, setError] = useState<any>()
    const [busy, setBusy] = useState<boolean>(false)

    useEffect(() => {
        let allItems: string[] = []
        entries.forEach(xs => {
            allItems = [...allItems, ...xs[1]]
        })
        setWines({})
        setIncluded({})
        let url = `${API_URL}/documents/wines`
        url += `?filter[@id]=/${allItems.map(s => `${s}$`).join("|")}/`
        url += "&include=agriculture,bottleCap,category"
        url += "&fields=agriculture,bottleCap,category,identifier,image,name,offers"
        mercuryGet<TypeWineListItem>(url)
            .then(result => {
                const wineMap = {}
                result["@graph"].forEach(w => {
                    // @ts-ignore
                    wineMap[w["@id"].substring(w["@id"].length - allItems[0].length)] = w
                })
                const includedMap = {}
                result["@included"].forEach(inc => {
                    // @ts-ignore
                    includedMap[inc["@id"]] = inc
                })
                setIncluded(includedMap)
                setWines(wineMap)
            })
            .catch(err => setError(err))
            .finally(() => setBusy(false))
    }, [entries])

    if (busy) return null

    if (error) return <ErrorToast error={error}/>

    if (Object.keys(wines).length === 0) return null

    const spacers: ReactNode[] = []
    for (let i = 0; i < 4; i++) {
        spacers.push(<div className="single-product single-product-spacer" key={"spacer-" + i}/>)
    }

    return (
        <>
            {entries.map(([country, items]) => {
                items.sort((a: string, b: string) => {
                    const wineA = wines[a]
                    if (wineA === undefined)
                        return -1
                    const wineB = wines[b]
                    if (wineB === undefined)
                        return 1
                    if (wineA.category === null)
                        return -1
                    if (wineB.category === null)
                        return 1
                    const categoryA = included[wineA.category["@id"]] as TypeCategory
                    if (categoryA === undefined) {
                        return -1
                    }
                    const categoryB = included[wineB.category["@id"]] as TypeCategory
                    if (categoryB === undefined) {
                        return -1
                    }
                    const delta = categoryA.order - categoryB.order
                    if (delta === 0) {
                        if (!wineA.offers.integerPrice) {
                            return -1
                        } else if (!wineB.offers.integerPrice) {
                            return 1
                        } else {
                            return wineA.offers.integerPrice - wineB.offers.integerPrice
                        }
                    } else {
                        return delta
                    }
                })

                return (
                    <div key={country["@id"]}>
                        <div className="product-segment-header">
                            <div className="segment-header-wrapper">
                                <span className={`segment-header-icon icon-countries-${country.identifier}`}/>
                                <span className="segment-header-title">{country.name}</span>
                            </div>
                        </div>
                        <div className="product-segment-items">
                            {items.map(id => {
                                const wine = wines[id]
                                if (wine === undefined) {
                                    console.warn("could not load wine \"" + id + "\"")
                                    return null
                                }
                                return (
                                    <WineListItem key={wine.identifier} wine={wine} included={included}/>
                                )
                            })}
                            {spacers}
                        </div>
                    </div>
                )
            })}
        </>
    )
}

