import ky from "ky"
import {SchemaCart, SchemaCartItem, SchemaReference} from "../_types/types"
import {serviceGetPromise} from "./api.public.service"
import {ModifierType} from "../_contexts/dispatch"
import {CartState, defaultCartState} from "../_contexts/CartContext"
import {API_URL} from "../_helpers/environment"
import {TypeReference} from "../_types/new-types"
import {Mutex} from "async-mutex"

const getCartId = () => {
    return localStorage.getItem("cart_v2")
}

const clearCartId = () => {
    return localStorage.removeItem("cart_v2")
}

let pendingCartItem: SchemaCartItem | null = null
let pendingRequestTimeout: any
const cartMutex = new Mutex()

const submitCartChangeToAnalytics = (id: string, quantity: number) => {
    if (quantity === 0) return
    serviceGetPromise(id)
        .then((product: any) => {
            window.dataLayer.push({
                "event": quantity > 0 ? "addToCart" : "removeFromCart",
                "ecommerce": {
                    "currencyCode": "EUR",
                    "add": {
                        "products": [{
                            "name": product.name,
                            "id": product["@id"]?.split("/").pop(),
                            "category": product.category?.name,
                            "brand": product.manufacturer?.name,
                            "price": (product.offers?.integerPrice) ? (product.offers.integerPrice / 100) : undefined,
                            "quantity": quantity > 0 ? quantity : -quantity,
                        }],
                    },
                },
            })
        })
        .catch(() => console.warn("Could not report cart change."))
}

// Should only be used when no other upload event is pending
const serverPush = async (id: string) => {
    if (pendingCartItem === null) return
    await ky.post(`${id}/items`, {"json": pendingCartItem})
    submitCartChangeToAnalytics(id, pendingCartItem ? pendingCartItem.quantity : 0)
    pendingCartItem = null
}

// Makes sure cart is not being modified before pushing cart item
const serverSafePush = async (id: string) => {
    const release = await cartMutex.acquire()
    await serverPush(id)
    release()
}

export const serviceCartUploadItem = async (id: string, cartItem: SchemaCartItem) => {

    // Wait if cart is currently being submitted
    const release = await cartMutex.acquire()

    if (pendingCartItem === null) {
        pendingCartItem = cartItem
    } else if (pendingCartItem.product["@id"] === cartItem.product["@id"]) {
        pendingCartItem.quantity += cartItem.quantity
    } else {
        await serverPush(id)
        pendingCartItem = cartItem
    }

    clearTimeout(pendingRequestTimeout)
    pendingRequestTimeout = setTimeout(() => serverSafePush(id), 500)

    release()
}

// Fetches cart items from serves if an ID is present in storage
export const serviceCartFetchItems = async (): Promise<SchemaCartItem[]> => {

    // Try to retrieve previous cart id
    const id = getCartId()

    if (id !== null) {
        try {
            const cart = await ky.get(id).json<SchemaCart>()
            return cart.items
        } catch (e) {
            console.warn("Could not retrieve previous cart")
            clearCartId()
        }
    }

    return []
}

// Removes an item from the cart state
export const cartRemoveItem = (product: TypeReference): ModifierType<CartState> => {
    return s => {

        // Retrieve existing count of item
        let qty = 0
        for (let i = 0; i < s.items.length; i++) {
            if (s.items[i].product["@id"] === product["@id"]) {
                qty += s.items[i].quantity
                break
            }
        }

        // Pass state change to cart add item
        return cartAddItem(product, -qty)(s)
    }
}

// Resets cart state and clears stored ID
export const cartClear = (): ModifierType<CartState> => {
    clearCartId()
    return () => ({...defaultCartState})
}

// Retrieve cart id or fetch a new one if one is not present in store
export const serviceCartGetId = async (): Promise<string> => {

    // Get cart from store if exists
    let id = getCartId()

    // Retrieve a new cart instance from server
    if (!id) {
        const result = await ky.post(`${API_URL}/carts`).json<SchemaCart>()
        id = result["@id"] ? result["@id"] : null
        if (id !== null) {
            localStorage.setItem("cart_v2", id)
        }
    }

    // Check if cart id retrieval was successful
    if (id === null) {
        throw Error("Cart ID does not exist")
    }

    return id
}

// Adds a cart instance to the cart state, will update quantity if item is already present
export const cartAddItem = (product: TypeReference, qty: number): ModifierType<CartState> => {

    // Check if product id is not empty
    if (!product["@id"]) {
        return s => ({...s, "error": "Product ID is not set."})
    }

    // Create cart item
    const cartItem: SchemaCartItem = {
        "@type": "CartItem",
        "product": {"@id": product["@id"]} as SchemaReference,
        "quantity": qty,
    }

    // Add cart item to cart state
    return state => {

        // Create copy of items list and find current product
        const items = [...state.items]
        let exists = false
        for (let i = 0; i < items.length; i++) {
            const item = items[i]
            if (item.product["@id"] === cartItem.product["@id"]) {

                // Remove cart item if qty is 0
                if (item.quantity + qty <= 0) {
                    items.splice(i, 1)
                } else {
                    item.quantity += qty
                }

                exists = true
                break
            }
        }

        // Add new cart item if it does not exist
        if (!exists) {
            if (cartItem.quantity <= 0)
                return state
            items.push(cartItem)
        }

        // Add operation to upload queue
        const queue = [...state.queue, cartItem]

        // Return updated cart state
        return {...state, items, queue}
    }
}
