import React, {FC, useCallback, useEffect, useMemo, useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import "./WineProductPage.scss"
import {faAngleDoubleRight, faBox, faLeaf, faLink, faPrint, faStar} from "@fortawesome/free-solid-svg-icons"
import {useTranslation} from "react-i18next"
import Loader from "../_components/Loader/Loader"
import LocaleLink from "../_components/localization/LocaleLink"
import Snippet from "../_components/annotations/Snippet"
import {Helmet} from "react-helmet"
import {API_URL, BASE_URL} from "../_helpers/environment"
import i18next from "i18next"
import {languageReferences} from "../_components/annotations/Languages"
import logoMatthysWines from "./_icons/matthys-wines-logo.svg"
import logoCO2 from "./_icons/co2-logo.jpg"
import {useAuth0} from "@auth0/auth0-react"
import ky from "ky"
import {encodeBase64} from "../_helpers/encoding"
import {customHeader} from "../_helpers/auth-header"
import ErrorPage from "../Error/ErrorPage"
import WineProductStory, {WineProductStoryVertical} from "./_components/WineProductStory"
import {MercuryOptions, useManyDocuments} from "../_services/mercury.service"
import NotFoundCatch from "../_components/NotFound/NotFoundCatch"
import {findIncludedById, findManyIncludedById} from "../_helpers/included"
import {
    TypeAppellation,
    TypeCategory,
    TypeGrape,
    TypeManufacturer,
    TypePropertyValue,
    TypeWine,
} from "../_types/new-types"
import {serviceGetPromise} from "../_services/api.public.service"
import {WineShowcase} from "../_components/WineShowcase/WineShowcase"
import ProductPriceToCart from "../_components/products/ProductPriceToCart"
import LazyLoad from "react-lazyload"
import ProductProperty from "./_components/ProductProperty"
import {useParams} from "react-router-dom"

const MAPS_URL = "https://www.google.com/maps/embed/v1/place"
const DOC_URL = process.env.REACT_APP_DOC_URL || ""

export const RelatedWines: FC<{ url: string, exclude: string, list?: boolean, children?: React.ReactNode }> = ({
                                                                                                                   url,
                                                                                                                   exclude,
                                                                                                                   children,
                                                                                                                   list,
                                                                                                               }) => {
    const [results, setResults] = useState([])
    const [error, setError] = useState()

    useEffect(() => {
        let cancelled = false
        serviceGetPromise(url)
            .then((r: any) => {
                if (!cancelled) {
                    setResults(r["@graph"])
                }
            })
            .catch(e => {
                if (!cancelled) {
                    setError(e)
                }
            })
        return () => {
            cancelled = true
        }
    }, [url])

    if (error) {
        return null
    }

    if (results.length === 0 || (results.length === 1 && exclude)) {
        return null
    }

    return (
        <div className="product-related">
            {children}
            <WineShowcase list={list} items={results} excluded={[{"@id": exclude}]}/>
        </div>
    )
}

const WineProductPage: FC = () => {

    const {t, i18n} = useTranslation("store")
    const {id} = useParams()

    const [favorite, setFavorite] = useState<boolean>(false)
    const {getAccessTokenSilently, user, isAuthenticated, isLoading} = useAuth0()
    const [wine, setWine] = useState<TypeWine>()
    const [isFound, setFound] = useState<boolean>(true)
    const [isRegionBlocked, setRegionBlocked] = useState<boolean>(false)

    const wineOptions = useMemo<MercuryOptions>(() => ({
        "filter": {"identifier": id},
        "include": {
            "": "aging,agriculture,appellation,bottleCap,category,manufacturer,material",
            "appellation": "addressCountry",
        },
    }), [id])
    const {
        "items": wines,
        "isLoading": busy,
        included,
        error,
    } = useManyDocuments<TypeWine>("/wines", wineOptions)

    useEffect(() => {
        if (busy) {
            return
        }
        if (wines.length > 0) {
            setWine(wines[0])
        } else {
            setFound(false)
        }
    }, [busy, wines])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [id])

    useEffect(() => {
        if (isLoading) return
        if (!isAuthenticated) return
        if (!wine) return
        getAccessTokenSilently()
            .then(t => {
                ky.get(`${API_URL}/accounts/${encodeBase64(user?.email || "")}/favorites/${wine?.["@id"]?.split("/").pop()}`,
                    {"headers": customHeader(t)})
                    .then(() => setFavorite(true))
                    .catch(() => setFavorite(false))
            })
    }, [getAccessTokenSilently, user, wine, isLoading, isAuthenticated])

    // region block certain wines
    useEffect(() => {
        if (busy || error || !wine) return
        if (wine.name.indexOf("Finca Fabian") === -1) return
        ky.get("https://api.matthys-wines.com/localization/geo").json<{ countryCode: string }>()
            .then(res => {
                if (res.countryCode === "BE") {
                    setRegionBlocked(false)
                } else {
                    setRegionBlocked(true)
                }
            })
            .catch(err => console.log(err))
    }, [wine, busy, error])

    const toggleFavorite = useCallback(() => {
        if (isLoading) return
        setFavorite(f => {
            getAccessTokenSilently()
                .then(t => {
                    if (!f) {
                        ky.post(`${API_URL}/accounts/${encodeBase64(user?.email || "")}/favorites`,
                            {"headers": customHeader(t), "json": {"product": wine?.["@id"]?.split("/").pop()}})
                            .then(() => console.log("success"))
                    } else {
                        ky.delete(`${API_URL}/accounts/${encodeBase64(user?.email || "")}/favorites/${wine?.["@id"]?.split("/").pop()}`,
                            {"headers": customHeader(t)})
                            .then(() => console.log("success"))
                    }
                })
            return !f
        })
    }, [wine, getAccessTokenSilently, user, isLoading])

    useEffect(() => {
        if (busy || error || !wine) return
        const manufacturer = findIncludedById<TypeManufacturer>(wine.manufacturer, included)
        const category = findIncludedById<TypeCategory>(wine.category, included)
        window.dataLayer.push({
            "event": "productImpression",
            "ecommerce": {
                "currencyCode": "EUR",
                "impressions": [{
                    "name": wine.name,
                    "id": wine["@id"]?.split("/").pop(),
                    "price": wine.offers?.integerPrice ? (wine.offers.integerPrice / 100) : undefined,
                    "brand": manufacturer?.name,
                    "category": category?.name,
                }],
            },
        })
    }, [included, busy, wine, error])

    if (error) return <ErrorPage error={error}/>
    if (!isFound) return <NotFoundCatch/>
    if (busy || !wine) return <Loader/>

    const WineBottle = wine.image ? <img alt={wine.image.name} src={wine.image.contentUrl}/> : null

    const manufacturer = findIncludedById<TypeManufacturer>(wine.manufacturer, included)
    const appellation = findIncludedById<TypeAppellation>(wine.appellation, included)
    const country = findIncludedById<TypeAppellation>(appellation?.addressCountry, included)
    const agriculture = findIncludedById<TypePropertyValue>(wine.agriculture, included)
    const bottleCap = findIncludedById<TypePropertyValue>(wine.bottleCap, included)
    const aging = findIncludedById<TypePropertyValue>(wine.aging, included)
    const category = findIncludedById<TypeCategory>(wine.category, included)
    const grapes = findManyIncludedById<TypeGrape>(wine.material, included)

    const hasWineStory = manufacturer &&
        manufacturer.description &&
        manufacturer.founder?.name

    let availability = wine.offers?.availability.split("-").map(v => v.charAt(0).toUpperCase() + v.slice(1)).join("") || "OutOfStock"
    availability = "https://schema.org/" + availability

    return (
        <div className="product-page product-wine grid-xl container header-margin" id="productPage">
            <Snippet data={{
                "@context": "https://schema.org/",
                "@type": "Product",
                "name": wine.name,
                "image": wine.image?.thumbnail,
                "description": wine.description?.substring(0, 120),
                "sku": wine["@id"]?.split("/").pop(),
                "mpn": wine.mpn,
                "gtin": wine.gtin,
                "brand": {
                    "@type": "Brand",
                    "name": manufacturer?.name,
                },
                "offers": {
                    "@type": "Offer",
                    "url": `${BASE_URL}/${i18next.language}/wines/${wine.identifier}`,
                    "priceCurrency": "EUR",
                    "price": wine.offers.integerPrice ? (wine.offers.integerPrice / 100).toFixed(2) : "",
                    "availability": wine.isArchived ? "https://schema.org/OutOfStock" : availability,
                    "seller": {
                        "@type": "Organization",
                        "name": "Matthys Wines",
                    },
                },
            }}/>
            <Helmet>
                <title>{wine.name}{wine.vintage ? (` ${wine.vintage}`) : ""}</title>
                <meta name="description" content={wine.description?.substring(0, 120)}/>
                <link rel="canonical" href={`${BASE_URL}/${i18next.language}/wines/${wine.identifier}`}/>
                {languageReferences(`/wines/${wine.identifier}`)}
                <meta property="og:site_name" content="matthys-wines.com"/>
                <meta property="og:title" content={wine.name}/>
                <meta property="og:description" content={wine.description?.substring(0, 120)}/>
                <meta property="og:image" content={wine?.image?.contentUrl}/>
            </Helmet>
            <div className="product-print-header">
                <img alt={"CO2 Neutral"} height="40px" src={logoCO2}/>
                <img height="40px" src={logoMatthysWines} alt={"Matthys Wines"}/>
            </div>
            <section className="columns product-detail">
                <div className="column hide-lg col-4 col-xl-5 col-lg-4 product-presentation">
                    <div className="product-image">{WineBottle}</div>
                </div>
                <div className="column col-8 col-xl-7 col-lg-8 col-sm-12 col-mx-auto product-details">
                    <div className="product-title-container">
                        <h1 className="product-title">{wine.name}</h1>
                        {(isAuthenticated && !isLoading) ? (
                            <div className="product-favorite">
                                <button className={"btn-favorite " + (favorite ? "active" : "")}
                                        onClick={() => toggleFavorite()}/>
                            </div>
                        ) : null}
                    </div>
                    <div className="product-chips">
                        <div className="product-chip product-country">
                            <i style={{"backgroundImage": `url("/icons/countries/${country?.identifier}.svg")`}}/>
                            <span>{country?.name}</span>
                        </div>
                        <div className="product-chip">
                            <i className="grapes"/>
                            <span>{grapes.map(grape => grape.name).join(", ")}</span>
                        </div>
                    </div>
                    <div className="show-lg product-image product-image-mobile">{WineBottle}</div>
                    <div>
                        <table className='product-properties'>
                            <tbody>
                            <ProductProperty title={t("wine/manufacturer")}>
                                {manufacturer?.url ? (
                                    <a href={manufacturer?.url} rel="noopener noreferrer" target="_blank">
                                        {manufacturer?.name}
                                    </a>
                                ) : (manufacturer?.name)}
                            </ProductProperty>
                            <ProductProperty title={t("wine/appellation")}>
                                {appellation?.name}
                            </ProductProperty>
                            <ProductProperty
                                title={t("wine/wineStyle")}
                                icon={"/wine-categories/" + category?.identifier + ".svg"}
                            >
                                {category?.name?.split("/").pop()}
                            </ProductProperty>
                            <ProductProperty title={t("wine/vintage")}>
                                {wine.vintage !== 0 ? wine.vintage : t("wine/noVintage")}
                            </ProductProperty>
                            <ProductProperty title={t("wine/bottleClosure")}>
                                {bottleCap?.value}
                            </ProductProperty>
                            <ProductProperty
                                icon={"/agriculture/" + agriculture?.identifier + ".svg"}
                                title={t("wine/agriculture")}
                            >
                                {agriculture?.value}
                            </ProductProperty>
                            <ProductProperty title={t("wine/alcoholByVolume")}>
                                {wine.alcoholByVolume}%
                            </ProductProperty>
                            <ProductProperty title={t("wine/volume")}>
                                {wine.volume}ml
                            </ProductProperty>
                            <ProductProperty title={t("wine/ageingPotential")}>
                                {t("wine/beforeYears_YEARS").replace("%YEARS", wine.agingPotential + "")}
                            </ProductProperty>
                            <ProductProperty title={t("wine/ageing")}>
                                {aging?.value}
                            </ProductProperty>
                            </tbody>
                        </table>
                    </div>
                    <div className="product-pricing">
                        <ProductPriceToCart
                            productId={wine["@id"]}
                            isArchived={wine.isArchived}
                            offer={wine.offers}
                            defaultQuantity={6}
                            regionBlock={isRegionBlocked}
                        />
                    </div>
                    <div className="product-sale-features">
                        <div className="columns">
                            <div className="column col-sm-12 col-6">
                                <FontAwesomeIcon icon={faAngleDoubleRight} className="mr-2"/>
                                {t("sale/feature/delivery")}
                            </div>
                            <div className="column col-sm-12 col-6">
                                <FontAwesomeIcon icon={faLeaf} className="mr-2"/>
                                {t("sale/feature/environment")}
                            </div>
                            <div className="column col-sm-12 col-6">
                                <FontAwesomeIcon icon={faBox} className="mr-2"/>
                                {t("sale/feature/shipping")}
                            </div>
                            <div className="column col-sm-12 col-6  sale-shipping">
                                <LocaleLink to={"/articles/delivery"} className="text-dark">
                                    <FontAwesomeIcon icon={faStar} className="mr-2"/>
                                    {t("sale/feature/cost")}
                                </LocaleLink>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="product-description columns">
                {wine.description ? (
                    <>
                        <div className="column col-6 col-lg-8 col-sm-12 col-mx-auto">
                            <div className='product-tasting-notes'>
                                <h2>{t("tastingNotes/title")}</h2>
                                <p>{wine.description}</p>
                                <div>{(manufacturer?.url && manufacturer.name) ? (
                                    <div className="product-manufacturer-url-print">
                                        <FontAwesomeIcon icon={faLink} className="mr-2"/>
                                        {manufacturer.url}
                                    </div>
                                ) : null}</div>
                                <div className="text-right">
                                    <a
                                        rel="noreferrer"
                                        target="_blank"
                                        className="btn mt-2 btn-print"
                                        href={`${DOC_URL}/${i18n.language}/wines/${wine["@id"].split("/").pop()}`}
                                        style={{"fontSize": "0.8rem"}}
                                    >
                                        <FontAwesomeIcon icon={faPrint} className="mr-2"/>{t("wine/print", "Print")}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-lg-8 col-sm-12 col-mx-auto column">
                            {appellation ? (
                                <iframe className='product-location-map' title="Product Map"
                                        src={MAPS_URL + "?q=place_id:" + appellation?.identifier + "&key=" + process.env.REACT_APP_MAPS_API_KEY}
                                />
                            ) : null}
                        </div>
                    </>
                ) : (
                    <div className="col-12 col-lg-8 col-sm-12 col-mx-auto column">
                        {appellation ? (
                            <iframe className='product-location-map' title="Product Map"
                                    src={MAPS_URL + "?q=place_id:" + appellation?.identifier + "&key=" + process.env.REACT_APP_MAPS_API_KEY}
                            />
                        ) : null}
                    </div>
                )}
            </section>

            {(manufacturer && hasWineStory) ? (!manufacturer.layout ? (
                <WineProductStory manufacturer={manufacturer} wine={wine}/>
            ) : (
                <WineProductStoryVertical manufacturer={manufacturer} wine={wine}/>
            )) : (
                <LazyLoad once height={800}>
                    <div className="columns">
                        <div className="column col-6 col-mx-auto col-lg-8 col-sm-12">
                            <RelatedWines
                                url={`${API_URL}/suggestions/manufacturers/${encodeBase64(manufacturer?.["@id"] || "")}`}
                                exclude={wine["@id"]}
                                list={true}
                            >
                                <div className="product-related-head">
                                    <div className="product-related-title">
                                        {t("showcase/moreFrom/title_MANUFACTURER").replace("%MANUFACTURER", manufacturer?.name || "")}
                                    </div>
                                    <div className="product-related-lead">{t("showcase/moreFrom/lead")}</div>
                                </div>
                            </RelatedWines>
                        </div>
                        <div className="column col-6 col-mx-auto col-lg-8 col-sm-12">
                            <RelatedWines list={true}
                                          url={`${API_URL}/suggestions/wines/${wine["@id"].split("/").pop()}`}
                                          exclude={wine["@id"]}>
                                <div className="product-related-head">
                                    <div className="product-related-title">{t("showcase/related/title")}</div>
                                    <div className="product-related-lead">{t("showcase/related/lead")}</div>
                                </div>
                            </RelatedWines>
                        </div>
                    </div>
                </LazyLoad>
            )}
        </div>
    )


}

export default WineProductPage
