import React, {FC, useEffect, useMemo, useState} from "react"
import "./SiteSearch.scss"
import {useTranslation} from "react-i18next"
import {SchemaCountry, SchemaWine} from "../../_types/types"
import LocaleLink from "../localization/LocaleLink"
import {SpinnerSmall} from "../Loader/SpinnerSmall"
import {MercuryOptions, useManyDocuments} from "../../_services/mercury.service"
import {TypeAppellation, TypeCountry} from "../../_types/new-types"

const WineRow: FC<{ wine: SchemaWine, country?: SchemaCountry }> = ({wine, country}) => {

    return (
        <LocaleLink to={"/wines/" + wine.identifier} className="site-search-result-row">
            {country && (
                <span className={"result-country icon-countries-" + country.identifier}/>
            )}
            <span className="result-name">
                {wine.name}
            </span>
            <span className="result-price">
                &euro;{wine.offers.integerPrice / 100}
            </span>
        </LocaleLink>
    )
}

export const logSearch = (text: string) => {
    if (text === "") return
    window.dataLayer.push({
        "event": "search",
        "query": text,
    })
}

let logTimeout: any
let searchTimeout: any

const findCountry = (included: any[], id: string): TypeCountry | undefined => {

    let appellation: TypeAppellation | undefined = undefined
    included.forEach(a => {
        if (a["@id"] === id) {
            appellation = a
        }
    })

    if (!appellation) {
        return undefined
    }

    let country = undefined
    included.forEach(c => {
        if (c["@id"] === appellation?.addressCountry?.["@id"]) {
            country = c
        }
    })

    return country
}

const SearchWineResults: FC<{ value: string, focused: boolean }> = ({value, focused}) => {

    const {t} = useTranslation("common")
    const [query, setQuery] = useState<string>(value)
    const [wait, setWait] = useState<boolean>(false)

    useEffect(() => {
        clearTimeout(searchTimeout)
        setWait(true)
        searchTimeout = setTimeout(() => {
            setQuery(value)
        }, 500)
        return () => {
            clearTimeout(searchTimeout)
        }
    }, [value, query])

    const wineOptions = useMemo<MercuryOptions>(() => ({
        "project": {"": "name,appellation,identifier,offers"},
        "filter": {"isArchived": false, "name": ".*" + query + ".*"},
        "include": {
            "": "appellation",
            "appellation": "addressCountry",
        },
        "limit": 10,
    }), [query])

    const {
        "items": wines,
        included,
        "isLoading": busy, // todo handle error
    } = useManyDocuments<SchemaWine>("/wines", wineOptions)

    useEffect(() => {
        setWait(false)
    }, [wines])

    const [hover, setHover] = useState<boolean>(false)

    return (
        <div
            className="site-search-result-container"
            style={{"display": (value.length >= 1 && (focused || hover)) ? "block" : "none"}}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <div className="site-search-result">
                {(busy || wait) ? (
                    <div className="site-search-result-row result-row-all">
                        <SpinnerSmall/>
                    </div>
                ) : (
                    <>
                        {wines.map(wine => <WineRow
                            key={wine["@id"] || ""}
                            wine={wine}
                            country={findCountry(included, wine.appellation?.["@id"] || "")}
                        />)}
                        <LocaleLink to={"/wines"} className="site-search-result-row result-row-all">
                            {t("btn/viewAllWines")}
                        </LocaleLink>
                    </>
                )}
            </div>
        </div>
    )
}

const SiteSearch: FC = () => {

    const {t} = useTranslation("common")

    const [value, setValue] = useState<string>("")
    const [loaded, setLoaded] = useState<boolean>(false)
    const [focused, setFocused] = useState<boolean>(false)

    useEffect(() => {
        if (value.length > 0)
            setLoaded(true)
    }, [value])

    return (
        <div className="site-search-container">
            <div className="search-input-group">
                <input
                    onBlur={() => setFocused(false)}
                    onFocus={() => setFocused(true)}
                    className="site-search-input"
                    placeholder={t("placeholder/findAWine")}
                    type="text"
                    name="wine search"
                    onChange={e => {
                        setValue(e.target.value)
                        clearTimeout(logTimeout)
                        logTimeout = setTimeout(() => logSearch(value), 1500)
                    }}
                />
            </div>
            {loaded ? (
                <SearchWineResults
                    value={value}
                    focused={focused}
                />
            ) : null}
        </div>
    )

}

export default SiteSearch
