import React, {FC, useEffect, useState} from "react"
import Loader from "../_components/Loader/Loader"
import {useTranslation} from "react-i18next"
import {CheckoutWithOrderProps} from "./CheckoutRoutes"
import LocaleRedirect from "../_components/localization/LocaleRedirect"
import {useCart, useCartDispatch} from "../_contexts/CartContext"
import {cartClear} from "../_services/cart.service"

const CheckoutProcessingPage: FC<CheckoutWithOrderProps> = ({order, token}) => {

    const {t, i18n} = useTranslation("checkout")
    const cart = useCart()
    const dispatch = useCartDispatch()
    const [loading, setLoading] = useState<boolean>(true)

    useEffect(() => {
        if (cart.busy) return
        if (cart.items.length > 0 && order.paymentMethod?.identifier === "cash") {
            dispatch(cartClear())
            setLoading(false)
        } else {
            setLoading(false)
        }
    }, [cart, order, dispatch])

    if (loading || cart.busy) return <Loader/>

    if (order.paymentMethod?.identifier === "cash" || order.confirmationNumber) {
        return (
            <LocaleRedirect to={"/orders/" + order.orderNumber + "?t=" + token}/>
        )
    }

    // Automatic submission of form
    setTimeout(() => {
        const form = document.getElementById("paymentForm")
        if (form) {
            // @ts-ignore
            form.submit()
        }
    }, 1000)

    return (
        <form action={process.env.REACT_APP_PAYMENT_URL} method="POST" id="paymentForm">
            {t("loader/redirectingToPayment")}<br/>
            <button type="submit" className="btn"
                    style={{"display": "block", "margin": "10px auto 0 auto"}}>Continue
            </button>
            <input type="hidden" name="Uid" value={process.env.REACT_APP_MERCHANT_ID}/>
            <input type="hidden" name="Orderid" value={order.orderNumber}/>
            <input type="hidden" name="Amount"
                   value={order.acceptedOffer?.price?.replace(".", "")}/>
            <input type="hidden" name="Description" value={order.description}/>
            <input type="hidden" name="Hash" value={order.identifier}/>
            <input type="hidden" name="Beneficiary" value="Matthys Wines Web"/>
            <input type="hidden" name="Redirecturl"
                   value={`${process.env.REACT_APP_CANONICAL_URL}/${i18n.language}/checkout/callback?t=${token}`}/>
            <input type="hidden" name="Redirecttype" value="INDIRECTGET"/>
            {process.env.REACT_APP_PAYMENT_FEEDBACK ? (
                <>
                    <input type="hidden" name="Feedbackurl" value={process.env.REACT_APP_PAYMENT_FEEDBACK}/>
                    <input type="hidden" name="Feedbacktype" value="SEMIONLINE"/>
                </>
            ) : null}
            <input type="hidden" name="Chname"
                   value={order.customer?.givenName + " " + order.customer?.familyName}/>
        </form>
    )
}

export default CheckoutProcessingPage
