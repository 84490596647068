import React, {FC} from "react"
import {SchemaOrderPerson} from "../../../_types/types"
import {useTranslation} from "react-i18next"
import FormInput from "../../../_components/form-inputs/FormInput"
import {useAuth0} from "@auth0/auth0-react"
import {encodeBase64} from "../../../_helpers/encoding"

interface Props {
    person: SchemaOrderPerson,
    onChange: (modifier: (prev: SchemaOrderPerson) => SchemaOrderPerson) => void
    disabled?: boolean
    token: string
    orderNumber: number
}

const CheckoutEmailForm: FC<Props> = ({person, onChange, disabled, token, orderNumber}) => {

    const {t, i18n} = useTranslation("form")
    const {isAuthenticated, loginWithRedirect, logout} = useAuth0()

    return (
        <div className="card-unit card-mb">
            <FormInput
                id={"billingEmail"}
                label={t("label/email")}
                placeholder={t("placeholder/email", "")}
                type={"email"}
                value={person.email}
                disabled={disabled}
                onChange={v => (!isAuthenticated && !disabled) && onChange(prev => ({...prev, "email": v}))}
                required={true}
            />
            {isAuthenticated ? (
                <span className="text-secondary">
                    <span className="pt-1" style={{"verticalAlign": "-2px"}}>
                        {t("form/loginDifferentEmail")}
                    </span>
                    <button
                        type="button"
                        className="ml-1 btn btn-link btn-text"
                        onClick={() => logout({"returnTo": `${window.location.origin}/sign-out?lng=${i18n.language}&t=${token}&o=${orderNumber}&r=${encodeBase64("/checkout")}`})}
                    >
                        {t("btn/signOut", {"ns": "common"})}
                    </button>
                </span>
            ) : (
                <div className="text-secondary">
                    <span className="pt-1" style={{"verticalAlign": "-2px"}}>
                        {t("form/loginHaveAccount")}
                    </span>
                    <button
                        type="button"
                        className="ml-1 btn btn-link btn-text"
                        onClick={() => loginWithRedirect({
                            "ui_locales": i18n.language,
                            "redirectUri": `${window.location.origin}/authorize?lng=${i18n.language}&t=${token}&o=${orderNumber}&r=${encodeBase64("/checkout")}`,
                        })}
                    >
                        {t("btn/login", {"ns": "common"})}
                    </button>
                </div>
            )}
        </div>
    )

}

export default CheckoutEmailForm