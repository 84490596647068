import React, {FC} from "react"

const ErrorToast: FC<{ error: any }> = ({error}) => {
    if (!error) {
        return null
    }
    return (
        <div className="toast toast-error mt-2">{error.toString()}</div>
    )
}

export default ErrorToast
