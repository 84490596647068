import React, {FC} from "react"
import {Route, Routes} from "react-router-dom"
import PrivateRoute from "../_components/authentication/PrivateRoute"
import AccountAddress from "./AccountAddress"
import AccountPage from "./AccountPage"
import NotFoundCatch from "../_components/NotFound/NotFoundCatch"
import OrderPage from "../Order/OrderPage"
import OrderListPage from "../Order/OrderListPage"

const AccountRoutes: FC = () => (
    <PrivateRoute>
        <Routes>
            <Route path={"/billing-and-shipping"} element={<AccountAddress/>}/>
            {/*<Route path={"/preferences*"} element={<AccountPreferencesPage}/>*/}
            <Route path="/orders/:id" element={<OrderPage/>}/>
            <Route path="/orders" element={<OrderListPage/>}/>
            <Route path={"/"} element={<AccountPage/>}/>
            <Route element={<NotFoundCatch/>}/>
        </Routes>
    </PrivateRoute>
)

export default AccountRoutes