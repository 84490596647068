import React, {FC} from "react"
import {UserCheckoutOptions} from "../../_types/types"
import {useTranslation} from "react-i18next"

interface Props {
    setOptions: (mod: (options: UserCheckoutOptions) => UserCheckoutOptions) => void
    options: UserCheckoutOptions
}

const FormOptions: FC<Props> = ({setOptions, options}) => {
    const {t} = useTranslation("form")
    return (
        <div>
            <div className="form-group">
                <label className="form-checkbox">
                    <input
                        checked={Boolean(options.useInvoice)}
                        onChange={e => {
                            const v = e.target.checked
                            setOptions(s => ({...s, "useInvoice": v ? 1 : 0}))
                        }}
                        type="checkbox"/>
                    <i className="form-icon"/>
                    {t("label/useInvoice")}
                </label>
            </div>
            <div className="form-group">
                <label className="form-checkbox">
                    <input
                        checked={Boolean(options.useBillingAsShipping)}
                        onChange={e => {
                            const v = e.target.checked
                            setOptions(s => ({...s, "useBillingAsShipping": v ? 1 : 0}))
                        }}
                        type="checkbox"/>
                    <i className="form-icon"/>
                    {t("label/useBillingAsShipping")}
                </label>
            </div>
        </div>
    )
}

export default FormOptions