import {
    SchemaCartItem,
    SchemaOrder,
    SchemaOrderDraft,
    SchemaOrderedItem,
    SchemaParcelDelivery,
    SchemaPaymentMethod,
    SchemaPerson,
    SchemaPostalAddress,
    SchemaThing,
    UserCheckoutOptions,
} from "../_types/types"
import {API_URL} from "../_helpers/environment"
import ky from "ky"
import {customHeader, localizationHeaders} from "../_helpers/auth-header"

/**
 * Creates initial order request, fields may be overwritten or changed by backend
 * @param items list of cart items
 * @param countryCode Either a ISO country code or PICKUP to indicate a pickup by customer
 */
export const serviceOrderCreate = (items: SchemaCartItem[], countryCode: string | "PICKUP") => {

    // Construct delivery object
    const orderDelivery: SchemaParcelDelivery = {
        "@type": "ParcelDelivery",
        "deliveryAddress": countryCode !== "PICKUP" ? {
            "addressCountry": countryCode,
        } as SchemaPostalAddress : undefined,
        "hasDeliveryMethod": {
            "@type": countryCode !== "PICKUP" ? "ParcelService" : "OnSitePickup",
        },
    }

    // Convert cart items to order items
    const orderedItems: SchemaOrderedItem[] = items.map(cartItem => ({
        "@type": "OrderItem",
        "orderQuantity": cartItem.quantity,
        "orderedItem": cartItem.product,
    } as SchemaOrderedItem))

    // Compose order draft
    // Note that draft is not complete, but we'll let the server complete empty object
    const orderDocument: SchemaOrderDraft = {
        "@context": "http://schema.org",
        "@type": "Order",
        "orderedItem": orderedItems,
        "orderDelivery": orderDelivery,
        "orderNumber": -1,
    }

    return ky.post(`${API_URL}/orders`, {"json": orderDocument, "headers": localizationHeaders()})
}

/**
 * Retrieves an order with a token
 * @param orderNumber
 * @param token
 */
export const serviceOrderFetch = (orderNumber: number, token: string) => {
    return ky.get(`${API_URL}/orders/${orderNumber}`, {"headers": customHeader(token)})
}

/**
 * Retrieves an order for a user without token
 * @param userIdentifier base64 encoded user email
 * @param orderNumber
 * @param token
 */
export const serviceUserOrderFetch = (userIdentifier: string, orderNumber: number, token: string) => {
    return ky.get(`${API_URL}/accounts/${userIdentifier}/orders/${orderNumber}`, {"headers": customHeader(token)})
}

/**
 * Retrieves shipping cost for order
 * @param number
 * @param token
 */
export const serviceOrderShippingCost = (number: number, token: string) => {
    return ky.get(`${API_URL}/orders/${number}/shipping`, {"headers": customHeader(token)})
        .json<{ shippingCost: number, shippingAvailable: boolean }>()
}

export interface OrderRequestResult {
    orderNumber: number,
    orderToken: string
}

/**
 * Sets whether or not the package should be delivered, price will be added on overview page
 * Original order is modified to include shipping method
 * @param order
 * @param token
 * @param deliver
 */
export const serviceOrderSetDeliveryMethod = (order: SchemaOrder, token: string, deliver: boolean) => {

    const deliveryMethod: SchemaThing = {"@type": deliver ? "ParcelService" : "OnSitePickup"}
    if (!order.orderDelivery)
        throw Error("Bad order")
    order.orderDelivery.hasDeliveryMethod = deliveryMethod

    return ky.put(`${API_URL}/orders/${order.orderNumber}`, {"json": order, "headers": customHeader(token)})
}

export const serviceOrderSetPaymentMethod = (order: SchemaOrder, token: string, pm: SchemaPaymentMethod) => {

    // Set payment method
    order.paymentMethod = pm

    return ky.put(`${API_URL}/orders/${order.orderNumber}`, {"json": order, "headers": customHeader(token)})
}

export const serviceOrderUpdate = (order: SchemaOrder, token: string) => {
    return ky.put(`${API_URL}/orders/${order.orderNumber}`, {"json": order, "headers": customHeader(token)})
}

export const serviceOrderConfirm = (order: SchemaOrder, token: string) => {
    return ky.post(`${API_URL}/orders/${order.orderNumber}/confirm`, {"headers": customHeader(token)})
}

/**
 * Updates an order draft to includes address information
 * Original order is modified to include shipping and billing address
 * @param order Existing order
 * @param token Authorization token for order
 * @param billing Person that is ordering and responsible for billing
 * @param shipping Address to which the order should be shipped
 * @param options List of preferences
 */
export const serviceOrderSetAddress = (order: SchemaOrder, token: string, billing: SchemaPerson, options: UserCheckoutOptions, shipping?: SchemaPostalAddress) => {

    // Set shipping address if order is to be delivered
    if (order.orderDelivery?.hasDeliveryMethod?.["@type"] === "ParcelService") {
        order.orderDelivery.deliveryAddress = (options.useBillingAsShipping || !shipping) ? billing.address : shipping
    }

    // Set billing address
    order.billingAddress = billing.address

    // Set customer without any address
    order.customer = {
        "affiliation": billing.affiliation,
        "givenName": billing.givenName,
        "familyName": billing.familyName,
        "telephone": billing.telephone,
        "email": billing.email,
    }

    // Send request out
    return ky.put(`${API_URL}/orders/${order.orderNumber}`, {"json": order, "headers": customHeader(token)})

}
