import React, {FunctionComponent} from "react"
import "./ContactPage.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faCalendarAlt, faClock, faEnvelope, faPhoneAlt} from "@fortawesome/free-solid-svg-icons"
import {useTranslation} from "react-i18next"
import {Helmet} from "react-helmet"
import {BASE_URL} from "../_helpers/environment"
import i18next from "i18next"
import Snippet from "../_components/annotations/Snippet"

const MAPS_URI = "https://www.google.com/maps/embed/v1/place"
const PLACE_ID = "ChIJ8WD_GD1Rw0cRt4oc631OXdE"

const ContactPage: FunctionComponent = () => {

    const {t} = useTranslation("common")
    return (
        <div id="contactPage">
            <Helmet>
                <title>{t("contact/title", {"defaultValue": "Matthys Wines: Excellence in wines"})}</title>
                <link rel="canonical" href={`${BASE_URL}/${i18next.language}/articles/contact`}/>
            </Helmet>
            <Snippet data={{
                "@context": "https://schema.org",
                "@type": "LiquorStore",
                "image": [
                    "https://static-beta.matthys-wines.com/images/store/brugge/matthys-wines-glass.jpg",
                    "https://static-beta.matthys-wines.com/images/store/brugge/matthys-wines-store-front.jpg",
                    "https://static-beta.matthys-wines.com/images/store/brugge/matthys-wines-store-street.jpg",
                    "https://static-beta.matthys-wines.com/images/store/brugge/matthys-wines-store.jpg",
                ],
                "@id": "https://www.matthys-wines.com#store",
                "name": "Matthys Wines",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "Gistelsesteenweg 228",
                    "addressLocality": "Brugge",
                    "addressRegion": "West-Vlaanderen",
                    "postalCode": "8200",
                    "addressCountry": "BE",
                },
                "geo": {
                    "@type": "GeoCoordinates",
                    "latitude": 51.200899,
                    "longitude": 3.197738,
                },
                "url": "https://www.matthys-wines.com",
                "telephone": "+3250386380",
                "priceRange": "$$",
                "openingHoursSpecification": [
                    {
                        "@type": "OpeningHoursSpecification",
                        "dayOfWeek": [
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                        ],
                        "opens": "10:00",
                        "closes": "12:00",
                    },
                    {
                        "@type": "OpeningHoursSpecification",
                        "dayOfWeek": [
                            "Monday",
                            "Tuesday",
                            "Wednesday",
                            "Thursday",
                            "Friday",
                            "Saturday",
                        ],
                        "opens": "14:00",
                        "closes": "18:00",
                    },
                ],
            }}/>
            <div className="container grid-xl header-margin">
                <h1 className="text-center">{t("contact/title")}</h1>
                <div className="columns">
                    <div className="column col-6 col-lg-12 contact-features">
                        <div className="contact-feature">
                            <h2>{t("contact/storeAddress")}</h2>
                            {t("contact/form/name")}<br/>
                            {t("contact/form/streetAddress")}<br/>
                            {t("contact/form/postalCode")} {t("contact/form/city")}<br/>
                            {t("contact/form/country")}<br/>
                        </div>
                        <div className="contact-feature">
                            <h2>{t("contact/contactInfo")}</h2>
                            <FontAwesomeIcon className="mr-2" icon={faPhoneAlt}/>
                            <a className="text-dark" href="tel:+32050386380">+32 (0)50 38 63 80</a><br/>
                            <FontAwesomeIcon className="mr-2" icon={faEnvelope}/>
                            <a className="text-dark" href="mailto:frank@matthys-wines.be">frank@matthys-wines.be</a>
                        </div>
                        <div className="contact-feature">
                            <h2>{t("contact/openingHours")}</h2>
                            <div><FontAwesomeIcon icon={faCalendarAlt} className="mr-2"/>{t("contact/open/day")}
                            </div>
                            <div><FontAwesomeIcon icon={faClock} className="mr-2"/>{t("contact/open/hour")}</div>
                        </div>
                        <div className="contact-feature">
                            <h2>{t("contact/company")}</h2>
                            {t("contact/form/legalName")}<br/>
                            {t("contact/form/vat")}<br/>
                        </div>
                    </div>
                    <div className="column col-6 col-lg-12">
                        <div className="contact-features">
                            <div className="contact-feature-map">
                                <iframe
                                    className='contact-map'
                                    title="Matthys Wines Map"
                                    src={`${MAPS_URI}?q=place_id:${PLACE_ID}&key=${process.env.REACT_APP_MAPS_API_KEY}`}
                                />
                            </div>
                            <div className="contact-feature">
                                <h2>{t("contact/parking")}</h2>
                                {t("contact/parking/how")}
                                <br/>
                                <br/>
                                {t("contact/parking/where")}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactPage