import React, {FunctionComponent, useState} from "react"
import {useTranslation} from "react-i18next"
import FormInput from "../form-inputs/FormInput"
import {API_URL} from "../../_helpers/environment"
import ky from "ky"
import {localizationHeaders} from "../../_helpers/auth-header"
import {SpinnerSmall} from "../Loader/SpinnerSmall"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faAngleRight, faCheck} from "@fortawesome/free-solid-svg-icons"
import * as Sentry from "@sentry/browser"

const FormNewsletter: FunctionComponent = () => {

    const [email, setEmail] = useState<string>("")
    const [busy, setBusy] = useState<boolean>(false)
    const [success, setSuccess] = useState<boolean>()
    const [error, setError] = useState<any>()
    const {t} = useTranslation("common")

    if (busy) {
        return <SpinnerSmall/>
    }

    if (success) {
        return (
            <div className="text-light mt-2">
                <b>{t("newsletter/success/title")}</b><FontAwesomeIcon icon={faCheck} className="ml-2"/><br/>
                {t("newsletter/success/lead")}
            </div>
        )
    }

    return (
        <form
            className="newsletter-form"
            onSubmit={e => {
                e.preventDefault()
                ky.post(API_URL + "/forms/newsletter", {"json": {email}, "headers": localizationHeaders()})
                    .then(() => setSuccess(true))
                    .catch(error => {
                        Sentry.captureException(error)
                        setError(error)
                    })
                    .finally(() => setBusy(false))
            }}
        >
            <FormInput
                label={t("label/newsletter")}
                placeholder={t("newsletter/email")}
                type={"email"}
                id={"newsletterEmail"}
                value={email}
                onChange={v => setEmail(v)}
                invalid={!!error}
                required={true}
                hint={error ? t("error/newsletterForm", {"ns": "error"}) : undefined}
            />
            <div className="newsletter-button">
                <button className='btn btn-primary' type='submit' title="Submit">
                    <FontAwesomeIcon icon={faAngleRight}/>
                </button>
            </div>
        </form>
    )

}

export default FormNewsletter
