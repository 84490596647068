import React, {FC} from "react"
import {CartWrapper} from "./CartContext"

// Wraps app with user and cart contexts
const AppContext: FC<{ children?: React.ReactNode }> = ({children}) => (
    <CartWrapper>
        {children}
    </CartWrapper>
)

export default AppContext