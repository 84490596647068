import React, {FC} from "react"

const ArticleImageBanner: FC<{ image: string, title: string, author: string }> = ({image, title, author}) => (
    <div
        className={"article-banner"}
        style={{"backgroundImage": `url('${image}')`}}
    >
        <div className="banner-wrapper"/>
        <div className="banner-content">
            <div className="container grid-sm">
                <div className='article-title'>{title}</div>
                <div className="article-author">{author}</div>
            </div>
        </div>
    </div>
)

export default ArticleImageBanner
