import React, {FC, useState} from "react"

interface Props {
    title: string
    bounds?: [number, number]
    values: [number | undefined, number | undefined]
    onChange: (range: [number | undefined, number | undefined]) => void
}

const PropertyRangeFilter: FC<Props> = props => {
    const [values, setValues] = useState<[number | undefined, number | undefined]>([undefined, undefined])
    return (
        <div className="single-filter">
            <div className="filter-title">{props.title}</div>
            <div className="columns container filter-range">
                <div className="column col-1 range-text">
                    <span>&euro;</span>
                </div>
                <div className="column col-4 form-group range-input">
                    <input
                        value={values[0] !== undefined ? values[0] / 100 : ""}
                        type="number"
                        min={props.bounds ? props.bounds[0] : 0}
                        onChange={e => {
                            const s = e.target.value
                            setValues(p => (s === "") ? ([undefined, p[1]]) : ([parseInt(s) * 100, p[1]]))
                        }}
                        onBlur={() => {
                            let v = values[0]
                            if (v !== undefined && props.bounds !== undefined)
                                v = Math.max(v, props.bounds[0])
                            props.onChange([v, values[1]])
                            setValues([v, values[1]])
                        }}
                        className="form-input"
                        placeholder={props.bounds ? `${props.bounds[0] / 100}` : ""}
                    />
                </div>
                <div className="column col-2 range-text">
                    <span>to</span>
                </div>
                <div className="column col-1 range-text">
                    <span>&euro;</span>
                </div>
                <div className="column col-4 form-group range-input">
                    <input
                        value={values[1] !== undefined ? values[1] / 100 : ""}
                        type="number"
                        max={props.bounds ? props.bounds[1] : 0}
                        onChange={e => {
                            const s = e.target.value
                            setValues(p => (s === "") ? ([p[0], undefined]) : ([p[0], parseInt(s) * 100]))
                        }}
                        onBlur={() => {
                            let v = values[1]
                            if (v !== undefined && props.bounds !== undefined)
                                v = Math.min(v, props.bounds[1])
                            props.onChange([values[0], v])
                            setValues([values[0], v])
                        }}
                        className="form-input"
                        placeholder={props.bounds ? `${props.bounds[1] / 100}` : ""}
                    />
                </div>
            </div>
        </div>
    )
}

export default PropertyRangeFilter