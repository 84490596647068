import React, {FC, useEffect, useState} from "react"
import {SchemaOrder, SchemaPaymentMethod} from "../../../_types/types"
import {serviceGetPromise} from "../../../_services/api.public.service"
import {API_URL} from "../../../_helpers/environment"
import {useTranslation} from "react-i18next"
import "./CheckoutPayment.scss"
import LoaderSection from "../../../_components/Loader/LoaderSection"

export const PaymentMethodCash: SchemaPaymentMethod = {
    "@type": "PaymentMethod",
    "name": "Cash",
    "alternateName": "",
    "identifier": "cash",
}

export const PaymentMethodOnline: SchemaPaymentMethod = {
    "@type": "PaymentMethod",
    "name": "Online",
    "alternateName": "",
    "identifier": "online",
}

interface Props {
    order: SchemaOrder,
    setPaymentMethod: (pm: SchemaPaymentMethod) => void
}

const CheckoutPayment: FC<Props> = ({order, setPaymentMethod}) => {

    const {t} = useTranslation("checkout")

    const [error, setError] = useState<any>()
    const [busy, setBusy] = useState<boolean>(true)
    const [paymentMethods, setPaymentMethods] = useState<SchemaPaymentMethod[]>([])

    useEffect(() => {
        serviceGetPromise<{ "@graph": SchemaPaymentMethod[] }>(API_URL + "/documents/payment-methods")
            .then(result => setPaymentMethods(result["@graph"]))
            .catch(error => setError(error))
            .finally(() => setBusy(false))
    }, [])

    if (error) return error.toString()

    if (busy) return <LoaderSection/>

    if (order.orderDelivery?.hasDeliveryMethod?.["@type"] !== "OnSitePickup") return null

    const payInStore = order.paymentMethod === PaymentMethodCash

    return (
        <div className="card-unit card-mb">
            <h4>{t("payment/title", "Payment")}</h4>
            {order.orderDelivery?.hasDeliveryMethod?.["@type"] === "OnSitePickup" ? (
                <div className="form-group">
                    <label className="form-radio">
                        <input
                            type="radio"
                            name="payOnline"
                            checked={order.paymentMethod === PaymentMethodOnline}
                            onChange={e => {
                                const checked = e.target.checked
                                if (checked) {
                                    setPaymentMethod(PaymentMethodOnline)
                                }
                            }}
                        />
                        <i className="form-icon"/> {t("payment/onlineTitle")}
                    </label>
                    <label className={`form-radio ${payInStore ? "" : "mb-0"}`}>
                        <input
                            type="radio"
                            name="payOnline"
                            checked={order.paymentMethod === PaymentMethodCash}
                            onChange={e => {
                                const checked = e.target.checked
                                if (checked) {
                                    setPaymentMethod(PaymentMethodCash)
                                }
                            }}
                        />
                        <i className="form-icon"/> {t("payment/inShopTitle")}
                    </label>
                </div>
            ) : null}
            {!payInStore ? (
                <>
                    <p>{t("payment/onlineLead")}</p>
                    <div className="payment-methods-list">
                        {paymentMethods.map(pm => (
                            <div
                                key={pm.identifier}
                                data-tooltip={pm.name}
                                className={`tooltip payment-method-item pm-${pm.identifier}`}
                            />
                        ))}
                    </div>
                </>
            ) : (
                <p className="mb-2">{t("payment/inShipLead")}</p>
            )}
        </div>
    )
}

export default CheckoutPayment