import {useEffect, useState} from "react"
import {serviceGetPromise} from "./api.public.service"
import {SchemaShippingCost} from "../_types/types"
import {API_URL} from "../_helpers/environment"

// export const useShippingMethodFromSearch = (search: string) => {
//
//     const [queryParams, setQueryParams] = useState<{ [key: string]: string | string[] | null | undefined }>();
//     const [shipping, setShipping] = useState<string>();
//     const [invalid, setInvalid] = useState<boolean>(false);
//
//     useEffect(() => {
//         if (search !== undefined) setQueryParams(queryString.parse(search));
//     }, [search]);
//
//     useEffect(() => {
//         if (!queryParams) return;
//         let country = queryParams["c"];
//         if (typeof country !== "string") {
//             setInvalid(true);
//             return;
//         }
//         try {
//             country = base64.decode(country);
//         } catch (e) {
//             setInvalid(true);
//         }
//         if (country.length === 2 || country === "PICKUP") {
//             setShipping(country);
//         } else {
//             setInvalid(true);
//         }
//     }, [queryParams]);
//
//     return {shipping, invalid};
// }

interface ShippingCalculationResult {
    busy: boolean
    shippingCost: number
    available: boolean
}

export const useShipping = (country: string = "", bottles: number, total: number): ShippingCalculationResult => {

    const [options, setOptions] = useState<SchemaShippingCost[]>([])
    const [busy, setBusy] = useState<boolean>(true)

    useEffect(() => {
        serviceGetPromise<{ "@graph": SchemaShippingCost[] }>(`${API_URL}/documents/shipping-costs`)
            .then(options => setOptions(options["@graph"]))
            .then(() => setBusy(false))
    }, [])

    let shippingCost: undefined | number = undefined
    if (!busy && country !== "") {
        let candidate = null
        for (let i = 0; i < options.length; i++) {
            if (options[i].countries.includes(country)) {
                candidate = options[i]
                break
            }
        }
        if (candidate) {
            if (candidate.inclusiveFrom && candidate.inclusiveFrom <= total) {
                shippingCost = 0
            } else if (candidate.flatFee > 0) {
                if (country === "BE" && bottles === 1) {
                    shippingCost = 500
                } else {
                    shippingCost = candidate.flatFee
                }
            } else if (candidate.packageLarge && candidate.packageExtraLarge) {
                let packingCost: number
                if (Math.floor(bottles / 6) * 6 === bottles) {
                    packingCost = Math.floor(bottles / 6) * candidate.packingFee
                } else {
                    packingCost = (Math.floor(bottles / 6) + 1) * candidate.packingFee
                }

                // Calculate based on packages
                let unitCost = 0
                while (bottles > 6) {
                    bottles -= 18
                    unitCost += candidate.packageExtraLarge
                }
                while (bottles > 0) {
                    bottles -= 6
                    unitCost += candidate.packageLarge
                }

                shippingCost = packingCost + unitCost
            }
        }
    }

    return {"shippingCost": shippingCost || 0, "available": shippingCost !== undefined, busy}
}