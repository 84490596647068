import React, {FC} from "react"
import Loader from "../_components/Loader/Loader"
import moment from "moment"
import LocaleLink from "../_components/localization/LocaleLink"
import ErrorPage from "../Error/ErrorPage"
import {useUserOrders} from "../_hooks/user.hook"

const OrderListPage: FC = () => {

    const {error, busy, orders} = useUserOrders()

    if (error) return <ErrorPage error={error}/>

    if (busy) return <Loader/>

    return (
        <div className="container grid-lg header-margin container-main">
            <h1 className="mb-0">Orders</h1>
            <ul className="breadcrumb m-breadcrumbs">
                <li className="breadcrumb-item">
                    <LocaleLink to="/account">Account</LocaleLink>
                </li>
                <li className="breadcrumb-item">
                    <LocaleLink to="/account/orders">Orders</LocaleLink>
                </li>
            </ul>
            <table className="table">
                <thead>
                <tr>
                    <th>Order</th>
                    <th>Items</th>
                    <th>Date</th>
                    <th>Amount</th>
                </tr>
                </thead>
                <tbody>
                {orders.map(o => (
                    <tr key={o.orderNumber}>
                        <td><LocaleLink to={"/account/orders/" + o.orderNumber}>{o.orderNumber}</LocaleLink></td>
                        <td>{o.orderedItem.length} item{o.orderedItem.length !== 1 && "s"}</td>
                        <td><span className="tooltip" data-tooltip={moment(o.orderDate).format("HH:mm")}>
                                {moment(o.orderDate).format("DD/MM/YYYY")}
                            </span></td>
                        <td className="text-right">&euro;{o.partOfInvoice && o.partOfInvoice.totalPaymentDue.value}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
}

export default OrderListPage