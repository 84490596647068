import React, {FC} from "react"
import EmptyPage from "../EmptyPage"
import {useTranslation} from "react-i18next"
import "../EmptyPage.scss"
import "./NotFoundPage.scss"

const NotFoundPage: FC = () => {

    const {t, i18n} = useTranslation("error")

    return (
        <div id="app">
            <div id="notFound">
                <EmptyPage
                    title={t("notFound/title")}
                    lead={t("notFound/lead")}
                    reload={true}
                    action={`/${i18n.language}`}
                    actionText={t("btn/home", {"ns": "common"})}
                />
            </div>
        </div>
    )

}

export default NotFoundPage