import * as React from "react"
import {FC, useCallback, useEffect, useMemo, useState} from "react"
import "./CartPage.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faAngleRight, faExclamationCircle, faQuestionCircle} from "@fortawesome/free-solid-svg-icons"
import LocaleLink from "../_components/localization/LocaleLink"
import Loader from "../_components/Loader/Loader"
import {useTranslation} from "react-i18next"
import CartProduct from "../_components/cart/CartProduct"
import FormSelectCountry from "../_components/form-inputs/FormSelectCountry"
import {useShipping} from "../_services/shipping.service"
import {useCart, useCartDispatch} from "../_contexts/CartContext"
import {cartAddItem} from "../_services/cart.service"
import {serviceOrderCreate} from "../_services/order.service"
import * as Sentry from "@sentry/browser"
import ErrorPage from "../Error/ErrorPage"
import {Helmet} from "react-helmet"
import CartEmptyPage from "../Empty/CartEmptyPage"
import {useDocument} from "../_services/mercury.service"
import {TypeProduct} from "../_types/new-types"
import ErrorToast from "../Error/ErrorToast"
import LoaderSection from "../_components/Loader/LoaderSection"
import {useNavigate} from "react-router-dom"

const OrderModule: FC<{ total: number, bottles: number }> = ({total, bottles}) => {

    const {t, i18n} = useTranslation("common")

    const navigate = useNavigate()
    const [country, setCountry] = useState<string>("BE")
    const [deliver, setDeliver] = useState<boolean>(true)
    const [busy1, setBusy] = useState<boolean>(false)
    const [error, setError] = useState<any>()

    const {shippingCost, "busy": busy2, available} = useShipping(country, bottles, total)
    const {"items": cartItems, "busy": busy3} = useCart()

    // Creates an order that can be modified during the checkout process
    // TODO: fix no-op update when cart is empty
    const onSubmit = useCallback(() => {
        setBusy(true)
        serviceOrderCreate(cartItems, deliver ? country : "PICKUP")
            .json<{ orderNumber: number, orderToken: string }>()
            .then(result => {
                navigate(`/${i18n.language}/checkout?t=${result.orderToken}&o=${result.orderNumber}`)
            })
            .catch(error => {
                Sentry.captureException(error)
                setError(error)
            })
            .finally(() => setBusy(false))
    }, [cartItems, country, deliver])

    // Show loaders
    if (busy1 || busy2 || busy3) return <Loader/>

    if (error) return <ErrorPage error={error}/>

    return (
        <form onSubmit={e => {
            e.preventDefault()
            onSubmit()
        }} className={"cart-shipping-cost"}>
            <div className="cart-shipping-option">
                <div className="form-label h5">{t("shipping/title")}</div>
                <div className="form-group">
                    <label className="form-radio">
                        <input
                            type="radio"
                            name="deliver"
                            checked={deliver}
                            onChange={e => setDeliver(e.target.checked)}
                        />
                        <i className="form-icon"/>
                        {(country === "" || !available) ? (
                            t("shipping/deliver")
                        ) : (
                            t("shipping/deliverFor_COST").replace("%COST", (shippingCost === 0) ? t("shipping/free") : ("€" + (shippingCost / 100)))
                        )}
                    </label>
                </div>
                <div className="shipping-country-select">
                    <FormSelectCountry
                        id={"shippingCountry"}
                        value={country}
                        onChange={v => setCountry(v)}
                    />
                    {(country && !available) && (
                        <div>
                            <div><FontAwesomeIcon icon={faExclamationCircle} className="mr-2 text-error"
                                                  style={{"fontSize": "0.7rem"}}/>{t("shipping/noShipping")}</div>
                            <div className="ml-2"><a
                                href="mailto:frank@matthys-wines.be">{t("shipping/contactForQuote")}</a></div>
                        </div>
                    )}
                </div>
                <div className="form-group">
                    <label className="form-radio">
                        <input
                            type="radio"
                            name="deliver"
                            checked={!deliver}
                            onChange={e => setDeliver(!e.target.checked)}
                        />
                        <i className="form-icon"/> {t("shipping/pickUp")}
                        <span className="ml-2 tooltip" data-tooltip={t("shipping/pickUpTooltip")}>
                            <FontAwesomeIcon icon={faQuestionCircle}/>
                        </span>
                    </label>
                </div>
            </div>
            <div className="p-2">
                <LocaleLink to={"/articles/delivery"} target="_blank">{t("shipping/howShippingCalculated")}</LocaleLink>
            </div>
            <div style={{"textAlign": "right"}}>
                <button type="submit"
                        className={"btn btn-success" + ((((country && !available) && deliver)) ? " disabled" : "")}>{t("btn/placeOrder")}
                    <FontAwesomeIcon className="ml-2" icon={faAngleRight}/>
                </button>
            </div>
        </form>
    )
}

interface CartItemProps {
    id: string
    qty: number
    setPrice: (mod: (prev: { [key: string]: number }) => { [key: string]: number }) => void
    setBottles: (mod: (prev: { [key: string]: number }) => { [key: string]: number }) => void
}

const CartItem = React.memo<CartItemProps>(({id, qty, setPrice, setBottles}) => {

    const dispatch = useCartDispatch()
    const query = useMemo(() => ["fields=name,image,offers,identifier,weight"], [])
    const {isLoading, error, "document": product} = useDocument<TypeProduct>(id, query)

    useEffect(() => {
        if (product !== null) {
            setPrice(prev => ({...prev, [id]: (product.offers.integerPrice * qty)}))

            let bottles = qty
            if (product.weight && (product.weight.unitText === "XBO") && parseInt(product.weight.value)) {
                bottles = qty * parseInt(product.weight.value)
            }
            setBottles(prev => ({...prev, [id]: bottles}))
        }
    }, [setBottles, qty, id, setPrice, product])

    if (error) {
        return <ErrorToast error={error}/>
    }

    if (isLoading || product === null) {
        return <LoaderSection height={164}/>
    }

    return (
        <CartProduct
            product={product}
            addToCart={(qty: number) => dispatch(cartAddItem({"@id": product["@id"]}, qty))}
            quantity={qty}
        />
    )
})

const CartPage: FC = () => {

    const {t} = useTranslation("common")

    const [itemPrices, setItemPrices] = useState<{ [key: string]: number }>({})
    const [itemBottles, setItemBottles] = useState<{ [key: string]: number }>({})

    // useCheckoutAnalytics(1); TODO: fix analytics

    const cart = useCart()

    if (cart.items.length === 0) {
        return <CartEmptyPage/>
    }

    let total = 0
    let bottles = 0
    cart.items.forEach(item => {
        const id = item.product["@id"]
        if (itemPrices[id] !== undefined && itemBottles[id] !== undefined) {
            total += itemPrices[id]
            bottles += itemBottles[id]
        }
    })

    return (
        <div className='container grid-xl header-margin' id='cart'>
            {(cart.busy || Object.values(itemPrices).length < cart.items.length) ? <Loader/> : null}
            <h1>{t("cart/title")}</h1>
            <Helmet>
                <meta name="robots" content="noindex"/>
            </Helmet>
            <div className="columns">
                <div className='order-overview column col-7 col-lg-12'>
                    <div className='cart'>
                        <div className='cart-items'>
                            {cart.items.map(cartItem => (
                                <CartItem
                                    setBottles={setItemBottles}
                                    setPrice={setItemPrices}
                                    key={cartItem.product["@id"]}
                                    id={cartItem.product["@id"]}
                                    qty={cartItem.quantity}
                                />
                            ))}
                        </div>
                        <div className='cart-sum-price'>
                            <span>{t("cart/total")}</span>
                            <span>&euro;{(total / 100)}</span>
                        </div>
                    </div>
                </div>
                <div className='column col-5 col-lg-12'>
                    <OrderModule total={total} bottles={bottles}/>
                </div>
            </div>
        </div>
    )

}

export default CartPage
