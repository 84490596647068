import React, {FC} from "react"
import {useAuth0} from "@auth0/auth0-react"
import Loader from "../Loader/Loader"

const WaitForAuthRoute: FC<{ children?: React.ReactNode }> = ({children}) => {
    const {isLoading} = useAuth0()
    if (isLoading) {
        return <Loader/>
    } else {
        return <>{children}</>
    }
}

export default WaitForAuthRoute