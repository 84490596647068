import React, {FC} from "react"

const ProductProperty: FC<{ icon?: string, title: string, children?: React.ReactNode }> = props => {
    let icon = null
    if (props.icon) {
        icon = <i
            style={{"backgroundImage": `url("/icons${props.icon}")`}}
        />
    }
    return (
        <tr className="product-property">
            <td className="property-title">
                {props.title}
            </td>
            <td className="property-value">
                {props.children}
                {icon}
            </td>
        </tr>
    )
}

export default ProductProperty
