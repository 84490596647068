import React, {FC} from "react"
import {useLocation} from "react-router-dom"
import LocaleLink from "../_components/localization/LocaleLink"
import WinePairingStepper from "./WinePairingStepper"
import {useTranslation} from "react-i18next"

const WinePairingPriceRange: FC = () => {
    const {t} = useTranslation("store")
    const location = useLocation()
    let nextLink = "/food-match/food-style" + location.search
    if (!location.search) {
        nextLink += "?"
    }
    return (
        <>
            <div className="header-title">{t("foodMatch/headerPriceRange")}</div>
            <div className="quiz-actions">
                <LocaleLink to={nextLink + "&priceMin=0&priceMax=1599"} className="btn-generic">
                    {t("foodMatch/upTo_PRICE").replace("%PRICE", "€15")}
                </LocaleLink>
                <LocaleLink to={nextLink + "&priceMin=1600&priceMax=2599"} className="btn-generic">
                    &euro;16 - &euro;25
                </LocaleLink>
                <LocaleLink to={nextLink + "&priceMin=2600&priceMax=5000"} className="btn-generic">
                    &euro;26 - &euro;50
                </LocaleLink>
            </div>
            <WinePairingStepper step={1}/>
        </>
    )
}

export default WinePairingPriceRange
