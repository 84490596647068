import * as React from "react"
import {FC, useMemo} from "react"
import {useParams} from "react-router-dom"
import ReactMarkdown from "react-markdown"
import "./ArticlePage.scss"
import Loader from "../_components/Loader/Loader"
import {useTranslation} from "react-i18next"
import Snippet from "../_components/annotations/Snippet"
import {Helmet} from "react-helmet"
import {BASE_URL} from "../_helpers/environment"
import i18next from "i18next"
import {languageReferences} from "../_components/annotations/Languages"
import ErrorPage from "../Error/ErrorPage"
import {MercuryOptions, useManyDocuments} from "../_services/mercury.service"
import {TypeArticle} from "../_types/new-types"
import NotFoundCatch from "../_components/NotFound/NotFoundCatch"
import remarkGfm from "remark-gfm"

const ArticlePage: FC = () => {

    const {id} = useParams<{ id: string }>()
    const {t} = useTranslation("common")

    // fetch article by identifier
    const articleOptions = useMemo<MercuryOptions>(() => ({
        "filter": {"identifier": id},
    }), [id])
    const {
        items,
        "isLoading": busy,
        error,
    } = useManyDocuments<TypeArticle>("/wiki-articles", articleOptions)

    // loading and error handling
    if (busy) return <Loader/>
    if (error) return <ErrorPage error={error}/>
    if (items.length === 0) return <NotFoundCatch/>

    // extract article from result set
    const article = items[0]

    // create valid schema.org article obejct
    const articleFiltered: { [key: string]: any } = {...article}
    delete articleFiltered["category"]
    delete articleFiltered["articleBody"]

    return (
        <div className='blog-article'>
            <Helmet>
                <title>{article.headline}</title>
                <meta name="description" content={article.articleBody.substring(0, 100)}/>
                <link rel="canonical" href={`${BASE_URL}/${i18next.language}/articles/${article.identifier}`}/>
                {languageReferences(`/wiki-articles/${article.identifier}`)}
            </Helmet>
            <Snippet data={{
                ...articleFiltered,
                "@context": "http://schema.org",
                "url": window.location.href.split("?")[0],
            }}/>
            <div className='container grid-sm article-body'>
                <ReactMarkdown
                    children={article.articleBody}
                    remarkPlugins={[remarkGfm]}
                />
            </div>
        </div>
    )
}

export default ArticlePage
