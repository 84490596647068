import React, {FC, useEffect} from "react"
import Loader from "../_components/Loader/Loader"
import LocaleRedirect from "../_components/localization/LocaleRedirect"
import {useTranslation} from "react-i18next"
import queryString from "query-string"
import EmptyPage from "../Empty/EmptyPage"
import {useCart, useCartDispatch} from "../_contexts/CartContext"
import {cartClear} from "../_services/cart.service"
import {useLocation} from "react-router-dom"

const CheckoutCallbackPage: FC = () => {

    const {t} = useTranslation("checkout")

    const cart = useCart()
    const dispatch = useCartDispatch()

    const {search} = useLocation()
    const query = queryString.parse(search)

    useEffect(() => {
        if (query.Status === "AU" && cart.items.length > 0) {
            dispatch(cartClear())
        }
    }, [dispatch, cart, query])

    if (cart.busy) return <Loader/>

    if (query.Status === "AU") {
        return <LocaleRedirect to={`/orders/${query.Orderid}?t=${query.t}`}/>
    } else if (query.Status === "DE") {
        return (
            <EmptyPage
                title={t("payment/denied/title")}
                lead={t("payment/denied/lead")}
                iconClass={""}
                actionText={t("btn/retry", {"ns": "common"})}
                action={`/checkout/confirm?o=${query.Orderid}&t=${query.t}`}
            />
        )
    } else if (query.Status === "CA") {
        return (
            <EmptyPage
                title={t("payment/cancelled/title")}
                lead={t("payment/cancelled/lead")}
                iconClass={""}
            />
        )
    }
    return (
        <EmptyPage title={t("payment/error/title")} lead={t("payment/error/lead")} iconClass={""}/>
    )
}

export default CheckoutCallbackPage