import React, {FC, useEffect, useState} from "react"
import Loader from "../_components/Loader/Loader"
import {SchemaOrder, SchemaProduct} from "../_types/types"
import {serviceGetPromise} from "../_services/api.public.service"
import "./OrderPage.scss"
import {serviceOrderFetch, serviceUserOrderFetch} from "../_services/order.service"
import queryString from "query-string"
import {Link, useLocation, useParams} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {useProducts} from "../_hooks/cart.hook"
import * as Sentry from "@sentry/browser"
import ErrorPage from "../Error/ErrorPage"
import {useAuth0} from "@auth0/auth0-react"
import {encodeBase64} from "../_helpers/encoding"

interface OrderedItemProps {
    id: string
    quantity: number
}

const OrderedItem: FC<OrderedItemProps> = ({id, quantity}) => {

    const [busy, setBusy] = useState<boolean>(true)
    const [error, setError] = useState<any>()
    const [product, setProduct] = useState<SchemaProduct>()

    useEffect(() => {
        serviceGetPromise<SchemaProduct>(id)
            .then(product => setProduct(product))
            .catch(error => {
                Sentry.captureException(error)
                setError(error)
            })
            .finally(() => setBusy(false))

    }, [id])

    if (busy) return <Loader/>

    if (error || !product) return <ErrorPage error={error}/>

    const {image} = product

    return (
        <div className="order-item">
            <figure className="order-item-image"
                    style={{"backgroundImage": `url("${(image?.thumbnail.contentUrl)}")`}}/>
            <div className="order-item-body columns">
                <div className="column col-auto">
                    <div className="order-item-name">{quantity >= 1 ? (quantity + "x") : ""} {product?.name}</div>
                    <div className="order-item-quantity">&euro;{(product.offers.integerPrice / 100) * quantity}</div>
                </div>
            </div>
        </div>
    )

}

const OrderPage: FC = () => {

    const [busy, setBusy] = useState<boolean>(true)
    const [order, setOrder] = useState<SchemaOrder>()
    const [error, setError] = useState<any>()

    let {id} = useParams<{ id: string }>()
    const {search} = useLocation()

    const {t} = useTranslation("checkout")
    const {getAccessTokenSilently, isAuthenticated, isLoading, user} = useAuth0()

    useEffect(() => {
        if (isLoading) return
        if (id === undefined) return
        const query = queryString.parse(search)
        let token = (typeof query["t"] !== "string") ? "" : query["t"]
        if (token !== "") {
            serviceOrderFetch(parseInt(id), token)
                .json<SchemaOrder>()
                .then(order => setOrder(order))
                .catch(error => {
                    setError(error)
                    if (!(error.response && [401, 403, 404].includes(error.response.status)))
                        Sentry.captureException(error)
                })
                .finally(() => setBusy(false))
        } else if (isAuthenticated) {
            getAccessTokenSilently()
                .then(token => {
                    if (id === undefined) return
                    serviceUserOrderFetch(encodeBase64(user?.email || ""), parseInt(id), token + "")
                        .json<SchemaOrder>()
                        .then(order => setOrder(order))
                        .catch(error => {
                            if (!(error.response && [401, 403, 404].includes(error.response.status)))
                                Sentry.captureException(error)
                            setError(error)
                        })
                        .finally(() => setBusy(false))
                })
                .catch(error => {
                    setError(error)
                    setBusy(false)
                })
        } else {
            setBusy(false)
            setError(404)
        }
    }, [user, isLoading, isAuthenticated, getAccessTokenSilently, search, id])

    const {"map": productMap, "busy": productsLoading} = useProducts(order?.orderedItem || [])

    useEffect(() => {
        if (busy || error || !order || productsLoading) return
        if (order.orderDelivery?.deliveryCost === undefined) return
        window.dataLayer.push({
            "event": "conversion",
            "price": order.acceptedOffer?.price,
            "orderNumber": order.orderNumber,
            "currencyCode": "EUR",
        })
        window.dataLayer.push({
            "event": "purchase",
            "ecommerce": {
                "currencyCode": "EUR",
                "purchase": {
                    "actionField": {
                        "id": order.orderNumber,
                        "affiliation": "Online Store",
                        "revenue": order.acceptedOffer?.price,
                        "shipping": (order.orderDelivery?.hasDeliveryMethod?.["@type"] !== "ParcelService") ? undefined : (order.orderDelivery.deliveryCost / 100).toFixed(2),
                    },
                    "products": order.orderedItem.map(item => {
                        const price = productMap[item.orderedItem["@id"] || ""]?.offers?.integerPrice
                        return ({
                            "name": productMap[item.orderedItem["@id"] || ""]?.name,
                            "id": item.orderedItem["@id"]?.split("/").pop(),
                            "price": price ? (price / 100).toFixed(2) : undefined,
                            "quantity": item.orderQuantity,
                        })
                    }),
                },
            },
        })
    }, [productMap, productsLoading, busy, error, order])

    if (error) return <ErrorPage error={error}/>

    if (busy || !order) return <Loader/>

    return (
        <div className="container grid-lg header-margin" id="orderPage">
            <h1>{t("confirm/orderTitle")} #{order.orderNumber}</h1>
            <h2>{t("confirm/thankYou")}</h2>
            <p>{t("confirm/checkYourMail")}</p>
            <div className="columns">
                <div className="column col-6 col-md-12">
                    <div className="card-unit card-mb">
                        <div className="h4">{t("confirm/billingInfo")}</div>
                        <div>{order.customer?.givenName} {order.customer?.familyName}</div>
                        <div>{order.customer?.email}</div>
                        <div>{order.customer?.telephone}</div>
                        <div>{order.customer?.affiliation?.name} {order.customer?.affiliation?.vatID}</div>
                        <div>{order.billingAddress?.streetAddress}</div>
                        <div>{order.billingAddress?.addressLocality} {order.billingAddress?.postalCode}</div>
                        <div>{order.billingAddress?.addressCountry}</div>
                    </div>
                    {order.orderDelivery?.deliveryAddress ? (
                        <div className="card-unit card-mb">
                            <div className="h4">{t("confirm/shippingInfo")}</div>
                            <div>{order.orderDelivery.deliveryAddress.name}</div>
                            <div>{order.orderDelivery.deliveryAddress.alternateName}</div>
                            <div>{order.orderDelivery.deliveryAddress.streetAddress}</div>
                            <div>{order.orderDelivery.deliveryAddress.addressLocality} {order.orderDelivery.deliveryAddress.postalCode}</div>
                            <div>{order.orderDelivery.deliveryAddress.addressCountry}</div>
                            <div>
                                {t("confirm/shippingCost")}:
                                &euro;{((order.orderDelivery?.deliveryCost !== undefined) ? order.orderDelivery.deliveryCost : 1) / 100}
                            </div>
                        </div>
                    ) : (
                        <div className="card-unit card-mb">
                            <div className="h4">{t("confirm/pickupInfo")}</div>
                            <div className="mb-2">{t("confirm/billingInfoLead")}</div>
                            <Link to={"/contact"} target="_blank">{t("confirm/openingHours")}</Link>
                        </div>
                    )}
                    <div className="card-unit card-mb">
                        <div className="h4">{t("confirm/payment")}</div>
                        <div>{t("confirm/total")}: &euro;{order.acceptedOffer?.price}</div>
                        {order.paymentMethod?.identifier === "cash" ? (
                            <div>{t("confirm/paymentOnPickup")}</div>
                        ) : (
                            <div>
                                {order.partOfInvoice?.paymentStatus?.["@type"] === "PaymentComplete" ? (
                                    <>
                                        {t("confirm/paymentOnline")}<br/>
                                        {t("confirm/paymentReference")} {order.confirmationNumber}
                                    </>
                                ) : t("confirm/paymentProcessing")}
                            </div>
                        )}
                    </div>
                </div>
                <div className="column col-6 col-md-12">
                    {order?.orderedItem.filter(o => o.orderedItem).map(item => (
                        <OrderedItem
                            key={item.name}
                            id={item.orderedItem["@id"]}
                            quantity={item.orderQuantity}
                        />
                    ))}
                </div>
            </div>

        </div>
    )
}

export default OrderPage
