import {SchemaThing} from "../_types/types"
import {useEffect, useState} from "react"
import ky from "ky"
import {API_URL} from "../_helpers/environment"
import i18next from "i18next"
import {Mutex} from "async-mutex"
import {TypeThing} from "../_types/new-types"

const serialRequest = new Mutex()

const safeFetchList = async <T extends SchemaThing>(endpoint: string, limit: number): Promise<MercuryCollection<T>> => {
    return new Promise<MercuryCollection<T>>(((resolve, reject) => {
        serialRequest.acquire()
            .then(release => {
                fetchList<T>(endpoint, limit)
                    .then(result => resolve(result))
                    .catch(e => reject(e))
                    .finally(() => release())
            })
            .catch(e => reject(e))
    }))
}

type MercuryCollection<T> = { graph: T[], included: any[] }

const localCache: { [key: string]: MercuryCollection<any> } = {}

const fetchList = async <T extends SchemaThing>(endpoint: string, limit: number): Promise<MercuryCollection<T>> => {
    if (localCache[endpoint]) {
        console.log(`cache hit: ${localCache[endpoint].graph.length} documents (${endpoint})`)
        return localCache[endpoint]
    }
    let page = 0
    let results: T[] = []
    let included: any[] = []
    let size = 100
    if (limit !== -1) {
        size = limit
    }
    while (true) {
        const result = await ky.get(`${API_URL}/documents${endpoint}&page[size]=${size}&page[number]=${page}`)
        const linkHeader = result.headers.get("Link")
        const body: { "@graph": T[], "@included"?: any[] } = await result.json()
        results = [...results, ...body["@graph"]]
        if (body["@included"]) {
            included = [...included, ...body["@included"]]
        }
        if (limit !== -1) {
            break
        } else if (linkHeader !== null && linkHeader.indexOf("next") !== -1) {
            page += 1
        } else {
            break
        }
    }
    const response = {"graph": results, included}
    localCache[endpoint] = response
    return response
}

export interface MercuryOptions {
    onlySearchOnQuery?: boolean
    filter?: { [key: string]: any }
    project?: { [key: string]: string }
    include?: { [key: string]: string }
    delay?: number
    sort?: string[]
    limit?: number
}

export const useManyDocuments = <T extends SchemaThing>(endpoint: string, options?: MercuryOptions): {
    isLoading: boolean
    items: T[]
    error: any
    included: any[]
} => {

    const [sequence, setSequence] = useState<number>(-1)
    const [documents, setResults] = useState<T[]>([])
    const [included, setIncluded] = useState<T[]>([])
    const [isLoading, setLoading] = useState(true)
    const [error, setError] = useState<any>()

    useEffect(() => {
        setSequence(p => p + 1)
    }, [options, endpoint])

    useEffect(() => {
        if (sequence < 0) {
            return
        }

        // Only Search On Query rule
        if (options && options.onlySearchOnQuery) {
            if (options.filter && Object.values(options.filter).filter(v => v.length > 0).length === 0) {
                setLoading(false)
                setError(undefined)
                setResults([])
                return
            }
        }

        let query: string[] = [
            `language=${i18next.language}`,
        ]

        // prepare filter query
        if (options && options.filter) {
            const items: string[] = []
            for (let key in options.filter) {
                if (!options.filter.hasOwnProperty(key)) {
                    continue
                }
                if (options.filter[key].length === 0) {
                    continue
                }
                if (typeof options.filter[key] === "string") {
                    items.push(`filter[${key}]=/${options.filter[key].toLowerCase()}/`)
                } else {
                    items.push(`filter[${key}]=${options.filter[key]}`)
                }
            }
            query.push(items.join("&"))
        }

        // prepare filter query
        if (options && options.project) {
            const items: string[] = []
            for (let key in options.project) {
                if (!options.project.hasOwnProperty(key)) {
                    continue
                }
                if (options.project[key].length === 0) {
                    continue
                }
                if (key === "") {
                    items.push(`fields=${options.project[key]}`)
                } else {
                    items.push(`fields[${key}]=${options.project[key]}`)
                }
            }
            query.push(items.join("&"))
        }

        // prepare include query
        if (options && options.include) {
            const items = includesToQueries(options.include)
            query.push(items.join("&"))
        }

        // prepare sorting query
        if (options && options.sort) {
            query.push(`sort=${options.sort.join(",")}`)
        }

        let cancelled = false
        let current = sequence
        setLoading(true)
        let e = endpoint + "?" + encodeURI(query.join("&"))

        let delay = 100
        if (options && options.delay) {
            delay = options.delay
        }

        setTimeout(() => {
            if (cancelled || current !== sequence) {
                return
            }
            safeFetchList<T>(e, options?.limit || -1)
                .then(res => {
                    if (!cancelled && current === sequence) {
                        setError(null)
                        setResults(res.graph)
                        setIncluded(res.included)
                        setLoading(false)
                    }
                })
                .catch(e => {
                    if (!cancelled && current === sequence) {
                        setError(e)
                        setLoading(false)
                    }
                })
        }, delay)

        return () => {
            cancelled = true
        }
    }, [sequence, endpoint, options])

    return {
        isLoading,
        "items": documents,
        included,
        error,
    }
}

export const includesToQueries = (includes: { [key: string]: string }): string[] => {
    const items: string[] = []
    for (let key in includes) {
        if (!includes.hasOwnProperty(key)) {
            continue
        }
        if (includes[key].length === 0) {
            continue
        }
        if (key === "") {
            items.push(`include=${includes[key]}`)
        } else {
            items.push(`include[${key}]=${includes[key]}`)
        }
    }
    return items
}

export const useDocument = <T extends TypeThing>(id: string, query?: string[]): {
    isLoading: boolean
    document: T | null
    error: any
} => {

    const [sequence, setSequence] = useState<number>(-1)
    const [document, setResult] = useState<T | null>(null)
    const [isLoading, setLoading] = useState(true)
    const [error, setError] = useState<any>()

    useEffect(() => {
        setSequence(p => p + 1)
    }, [id])

    useEffect(() => {
        if (sequence < 0) {
            return
        }
        if (!id) {
            setLoading(false)
            setError(undefined)
            setResult(null)
        }
        let cancelled = false
        let current = sequence
        setLoading(true)
        let queryString = ""
        if (query) {
            queryString = "&" + query.join("&")
        }
        ky.get(`${id}?language=${i18next.language}${queryString}`).json<T>()
            .then(res => {
                if (!cancelled && current === sequence) {
                    setError(null)
                    setResult(res)
                    setLoading(false)
                }
            })
            .catch(e => {
                if (!cancelled && current === sequence) {
                    setError(e)
                    setLoading(false)
                }
            })
        return () => {
            cancelled = true
        }
    }, [sequence, id, query])

    return {
        isLoading,
        document,
        error,
    }
}
