import React, {FC} from "react"

interface ProgressBarProps {
    step: number
}

const WinePairingStepper: FC<ProgressBarProps> = ({step}) => {
    const before = []
    const after = []
    for (let i = 0; i < step; i++) {
        before.push(<div key={`b${i}`} className="step-empty"/>)
    }
    for (let i = step; i < 5; i++) {
        after.push(<div key={`a${i}`} className="step-empty"/>)
    }
    return (
        <div className="quiz-steps">
            {before}
            <div className="step-fill"/>
            {after}
        </div>
    )
}

export default WinePairingStepper
