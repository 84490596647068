import * as React from "react"
import {FC} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faMinus, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons"
import {TypeProduct} from "../../_types/new-types"

interface CartItemProps {
    product: TypeProduct
    addToCart?: (qty: number) => void
    quantity: number
}

const CartProduct: FC<CartItemProps> = props => {

    const product = props.product
    const {image} = product
    const {addToCart} = props

    let modifyQuantity = null
    if (addToCart !== undefined) {
        modifyQuantity = (
            <div className="offer-pricing-group">
                <button
                    style={{"borderRight": "none"}}
                    onClick={() => addToCart(-props.quantity)}
                    className="offer-cart-quantity-button"
                ><FontAwesomeIcon icon={faTrash}/></button>
                <button
                    onClick={() => addToCart(-1)}
                    className="offer-cart-quantity-button"
                ><FontAwesomeIcon icon={faMinus}/></button>
                <input
                    value={props.quantity}
                    onChange={e => {
                        if (e.target.value.length > 0) {
                            addToCart(parseInt(e.target.value) - props.quantity)
                        }
                    }}
                    className="offer-cart-quantity"
                    type="number"
                />
                <button
                    onClick={() => addToCart(1)}
                    className="offer-cart-quantity-button"
                ><FontAwesomeIcon icon={faPlus}/>
                </button>
            </div>
        )
    }

    return (
        <div className="cart-item">
            {
                image && (
                    <div className="cart-item-image">
                        <img
                            alt={product.name}
                            src={image.thumbnail.contentUrl}
                            height={120}
                        />
                    </div>
                )
            }
            <div className="cart-item-container">
                <div className="cart-item-body">
                    <div className="cart-item-title">{
                        (modifyQuantity ? "" : props.quantity + "x ") +
                        props.product.name
                    }</div>
                    {modifyQuantity}
                </div>
                <div className="cart-item-price">
                    &euro;{props.quantity * props.product.offers.integerPrice / 100}
                </div>
            </div>
        </div>
    )
}

export default CartProduct
