import React, {FC} from "react"
import {
    AgingsFilter,
    AgricultureFilter,
    BottleCapsFilter,
    CountryFilter,
    GrapesFilter,
    InStockFilter,
    PriceFilter,
    StyleFilter,
    TypeFilter,
    VintageFilter,
} from "./WineFilters"
import {CatalogFilterTree} from "../../_types/requests"

export interface WineListFilter {
    grapes: string[]
    wineStyles: string[]
    wineTypes: string[]
    countries: string[]
    agings: string[]
    bottleCaps: string[]
    agriculture: string[]
    price: [number | undefined, number | undefined]
    vintage: string[]
    inStock: string[]
}

interface FilterColumnProps {
    setFilters: (mod: (prev: WineListFilter) => WineListFilter) => void
    filters: WineListFilter
    options: CatalogFilterTree
    initial: CatalogFilterTree
}

const CatalogFilterColumn: FC<FilterColumnProps> = ({setFilters, filters, options, initial}) => (
    <div className="filters-container">
        <TypeFilter
            show={initial.wineTypes}
            count={options.wineTypes}
            values={filters.wineTypes}
            onChange={wineTypes => setFilters(prev => ({...prev, wineTypes}))}
        />
        <InStockFilter
            show={initial.inStock}
            count={options.inStock}
            values={filters.inStock}
            onChange={inStock => setFilters(prev => ({...prev, inStock}))}
        />
        <PriceFilter
            values={filters.price}
            onChange={price => setFilters(prev => ({...prev, price}))}
            bounds={options.priceRange}
        />
        <CountryFilter
            show={initial.countries}
            count={options.countries}
            values={filters.countries}
            onChange={countries => setFilters(prev => ({...prev, countries}))}
        />
        <AgricultureFilter
            show={initial.agriculture}
            count={options.agriculture}
            values={filters.agriculture}
            onChange={agriculture => setFilters(prev => ({...prev, agriculture}))}
        />
        <StyleFilter
            show={initial.wineStyles}
            count={options.wineStyles}
            values={filters.wineStyles}
            onChange={wineStyles => setFilters(prev => ({...prev, wineStyles}))}
        />
        <GrapesFilter
            show={initial.grapes}
            count={options.grapes}
            values={filters.grapes}
            onChange={grapes => setFilters(prev => ({...prev, grapes}))}
        />
        <BottleCapsFilter
            show={initial.bottleCaps}
            count={options.bottleCaps}
            values={filters.bottleCaps}
            onChange={bottleCaps => setFilters(prev => ({...prev, bottleCaps}))}
        />
        <AgingsFilter
            show={initial.agings}
            count={options.agings}
            values={filters.agings}
            onChange={agings => setFilters(prev => ({...prev, agings}))}
        />
        <VintageFilter
            show={initial.vintage}
            count={options.vintage}
            values={filters.vintage}
            onChange={vintage => setFilters(prev => ({...prev, vintage}))}
        />
    </div>
)

export default CatalogFilterColumn