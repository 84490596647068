import React, {FC, useState} from "react"
import "./CookiesDisclaimer.scss"
import {useTranslation} from "react-i18next"
import ReactMarkdown from "react-markdown"

// Displays cookie notice at bottom of the page
const CookiesDisclaimer: FC = () => {

    const [value, setValue] = useState(localStorage && localStorage.getItem("cookiesDisclaimerHide") !== null)
    const {t} = useTranslation("common")

    if (value) return null

    return (
        <div className="cookies-disclaimer">
            <div className="container grid-xl">
                <div>
                    <ReactMarkdown
                        unwrapDisallowed={true}
                        disallowedElements={["paragraph"]}
                        children={t("cookieNotice") || ""}
                    />
                </div>
                <button
                    title="Close"
                    className="btn btn-clear"
                    onClick={() => {
                        localStorage && localStorage.setItem("cookiesDisclaimerHide", "true")
                        setValue(true)
                    }}
                />
            </div>
        </div>
    )
}

export default CookiesDisclaimer
