import React, {FC} from "react"
import "./EmptyPage.scss"
import LocaleLink from "../_components/localization/LocaleLink"

interface Props {
    title: string
    lead: string
    iconClass?: string
    action?: string
    onClick?: () => void
    actionText?: string
    reload?: boolean
}

const EmptyPage: FC<Props> = ({reload, title, lead, onClick, iconClass, action, actionText}) => (
    <div className="container grid-sm empty-page">
        <div className="empty-page-container">
            {iconClass ? (
                <div className={`empty-page-icon ${iconClass}`}/>
            ) : null}
            <div className="empty-page-title h1">{title}</div>
            <p className="empty-page-lead">{lead}</p>
            {action && actionText && reload ? (
                <a href={action} className="btn btn-primary">{actionText}</a>
            ) : null}
            {action && actionText && !reload ? (
                <LocaleLink to={action} className="btn btn-primary">{actionText}</LocaleLink>
            ) : null}
            {onClick && actionText ? (
                <button onClick={onClick} className="btn btn-primary">{actionText}</button>
            ) : null}
        </div>

    </div>
)

export default EmptyPage