import React, {FC, useEffect} from "react"
import {useNavigate} from "react-router-dom"
import {useTranslation} from "react-i18next"

interface Props {
    to: string
    push?: boolean
}

const LocaleRedirect: FC<Props> = ({to, push}) => {
    const {i18n} = useTranslation()
    const navigate = useNavigate()
    useEffect(() => {
        navigate(`/${i18n.language}${to}`, {"replace": true})
    }, [])
    return null
}

export default LocaleRedirect