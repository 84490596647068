import React, {FC, FunctionComponent, ReactNode, useEffect, useState} from "react"

interface FilterOptionProps {
    iconGroup?: string
    identifier?: string
    visible: boolean
    checked: boolean
    onChange: (checked: boolean) => void
    title: string
    count?: number
}

const FilterOption: FunctionComponent<FilterOptionProps> = props => {

    let icon = null
    if (props.iconGroup) {
        icon = <i className={`title-icon icon-${props.iconGroup}-${props.identifier}`}/>
    }

    return (
        <tr style={{"display": props.visible ? "" : "none"}}>
            <td>
                <label className="form-checkbox">
                    <input
                        type="checkbox"
                        checked={props.checked}
                        onChange={e => props.onChange(e.target.checked)}
                    />
                    <i className="form-icon"/>
                    {icon ? icon : null}
                    <span
                        className="checkbox-label">{props.title} {(props.count && props.count > 0) ? "(" + props.count + ")" : ""}</span>
                </label>
            </td>
        </tr>
    )
}

type OptionValue = { "@id": string, identifier?: string } | string

interface Props {
    count?: { [key: string]: number }
    values: string[]
    show?: { [key: string]: number }
    onChange: (values: string[]) => void
    options: OptionValue[]
    title: string
    name: string
    search?: boolean
    iconGroup?: string
    getName: (thing: any) => string
    comparator?: (a: any, b: any) => number
    excluded?: string[]
}

const ProductFilterComponent: FC<Props> = props => {

    const optionCompsSelected: ReactNode[] = []
    let optionComps: ReactNode[] = []

    const [placeholder, setPlaceholder] = useState<string>("")
    const [search, setSearch] = useState<string>("")

    const uniqueLength = (props.show !== undefined && Object.keys(props.show).length > 0) ? Object.keys(props.show)[0].length : -1
    const shortId = (o: OptionValue): string => {
        if (typeof o === "string")
            return o
        else
            return o["@id"].substring(o["@id"].length - uniqueLength, o["@id"].length)
    }
    const filteredOptions = props.options.filter(o => {
        if (props.show === undefined)
            return true
        const name = props.getName(o)
        if (search && name.toLowerCase().indexOf(search.toLowerCase()) === -1) {
            return false
        }
        return (props.show[shortId(o)] !== undefined)
    })

    const sortedOptions = filteredOptions.sort((a, b) => {
        if (props.comparator)
            return props.comparator(a, b)
        if (props.getName(a) < props.getName(b))
            return -1
        return 1
    })

    sortedOptions.forEach((thing: OptionValue) => {
        const optionId = shortId(thing)
        const thingId = (typeof thing === "string") ? thing : thing["@id"]
        if (props.excluded && props.excluded.includes(thingId.split("/").pop() || ""))
            return
        const included = props.values.includes(optionId)
        let count: number | undefined = (props.count !== undefined) ? props.count[optionId] : undefined
        const option = (
            <FilterOption
                key={thingId}
                iconGroup={props.iconGroup}
                title={`${props.getName(thing)}${count !== undefined ? ` (${count})` : "(0)"}`}
                checked={included}
                onChange={checked => {
                    if (checked) {
                        props.onChange([...props.values, optionId])
                    } else {
                        const values = [...props.values]
                        values.splice(values.indexOf(optionId), 1)
                        props.onChange(values)
                    }
                }}
                identifier={(props.iconGroup && (typeof thing !== "string")) ? thing.identifier : ""}
                visible={true}
            />
        )
        if (included) {
            optionCompsSelected.push(option)
        } else if (count === 0 || count === undefined) {
            // pass
        } else {
            optionComps.push(option)
        }
    })

    useEffect(() => {
        if (placeholder !== "")
            return
        const randomOption = (props.options.length > 0) ? props.options[Math.floor(Math.random() * props.options.length)] : undefined
        if (randomOption && props.options.length > 0) {
            setPlaceholder(props.getName(randomOption))
        }
    }, [props.options])

    return (
        <div className="single-filter">
            <div className="filter-title">{props.title}</div>
            {props.search ? (
                <>
                    <div className="filter-property-search filter-search">
                        <input
                            className="search-input form-input"
                            placeholder={placeholder ? `${placeholder}` : ""}
                            value={search}
                            onChange={e => setSearch(e.target.value)}
                        />
                    </div>
                    <div className="filter-options-divider"/>
                </>
            ) : null}
            <div className="filter-options options-selected">
                <table>
                    <tbody>
                    {optionCompsSelected}
                    </tbody>
                </table>
            </div>
            {(optionCompsSelected.length && optionComps.length) ? (
                <div className="filter-options-divider"/>
            ) : null}
            <div className="filter-options">
                <table>
                    <tbody>
                    {optionComps}
                    </tbody>
                </table>
            </div>
        </div>
    )

}

const compareProps = (prev: Props, next: Props) => {
    return (prev.values.length === next.values.length) && (JSON.stringify(prev.count) === JSON.stringify(next.count))
}

const PropertyFilter = React.memo<Props>(ProductFilterComponent, compareProps)

export default PropertyFilter
