import React, {FC, useMemo} from "react"
import {Helmet} from "react-helmet"
import {API_URL, BASE_URL} from "../_helpers/environment"
import i18next from "i18next"
import {languageReferences} from "../_components/annotations/Languages"
import Snippet from "../_components/annotations/Snippet"
import ArticleImageBanner from "./_components/ArticleImageBanner"
import ReactMarkdown from "react-markdown"
import {useParams} from "react-router-dom"
import ErrorPage from "../Error/ErrorPage"
import Loader from "../_components/Loader/Loader"
import NotFoundCatch from "../_components/NotFound/NotFoundCatch"
import {useTranslation} from "react-i18next"
import {SchemaWineManufacturer} from "../_types/types"
import {MercuryOptions, useManyDocuments} from "../_services/mercury.service"
import LazyLoad from "react-lazyload"
import {RelatedWines} from "../Wine/WineProductPage"
import {encodeBase64} from "../_helpers/encoding"

const EXPAND_KEY = "[Expand]"
const ORIGINAL_KEY = "[Original]"

const WineStoryTitle: FC<{ title: string }> = ({title}) => (
    <div>
        <h1 className="mb-2">
            {title}
        </h1>
        <h4 className="mt-0" style={{"marginBottom": "1.5rem"}}>Wine Story</h4>
    </div>
)

const WineStorySignature: FC<{ manufacturer: SchemaWineManufacturer }> = ({manufacturer}) => {
    return (
        <div className="wine-story-founder">
            <div className="wine-story-signature">
                {manufacturer?.founder?.image ? (
                    <img
                        src={manufacturer.founder.image?.contentUrl}
                        alt={manufacturer?.founder?.name}
                    />
                ) : null}
            </div>
            <div className="wine-story-signature-name">{manufacturer?.founder?.name}</div>
        </div>
    )
}

const WineStoryPage: FC = () => {

    const {t} = useTranslation("store")

    const {id} = useParams<{ id: string }>()

    const articleOptions = useMemo<MercuryOptions>(() => ({
        "filter": {"identifier": id},
    }), [id])
    const {
        items,
        "isLoading": busy,
        error,
    } = useManyDocuments<SchemaWineManufacturer>("/manufacturers", articleOptions)

    if (busy) return <Loader/>
    if (error) return <ErrorPage error={error}/>
    if (items.length === 0) return <NotFoundCatch/>

    const manufacturer = items[0]

    const body: string = manufacturer.description

    let lead = ""
    let more = ""
    let orig = ""

    const expandIndex = body.indexOf(EXPAND_KEY)
    if (expandIndex !== -1) {
        lead = body.substring(0, expandIndex)
        more = body.substring(expandIndex + EXPAND_KEY.length)
    } else {
        more = body
    }

    const originalIndex = more.indexOf(ORIGINAL_KEY)
    if (originalIndex !== -1) {
        orig = more.substring(originalIndex + ORIGINAL_KEY.length)
        orig = orig.replace(EXPAND_KEY, "")
        more = more.substring(0, originalIndex)
    }

    return (
        <div className='blog-article'>
            <Helmet>
                <title>{manufacturer?.name} - Wine Story</title>
                <meta name="description" content={lead}/>
                <link rel="canonical"
                      href={`${BASE_URL}/${i18next.language}/wine-stories/${manufacturer?.identifier}`}/>
                {languageReferences(`/articles/${manufacturer?.identifier}`)}
            </Helmet>
            <Snippet data={{
                "@context": "http://schema.org",
                "url": window.location.href.split("?")[0],
                "type": "Article",
                "description": lead,
                "author": manufacturer.founder?.name,
                "name": manufacturer.name,
            }}/>
            {(manufacturer?.image && manufacturer.image.contentUrl) ? (
                <ArticleImageBanner
                    title={manufacturer.name || ""}
                    author={manufacturer.founder?.name || ""}
                    image={manufacturer.image.contentUrl}
                />
            ) : null}
            {orig ? (
                <div className='container grid-lg article-body'>
                    <WineStoryTitle title={manufacturer.name || ""}/>
                    <div className="columns">
                        <div className="column col-6 col-md-12">
                            <ReactMarkdown children={orig}/>
                            <WineStorySignature manufacturer={manufacturer}/>
                        </div>
                        <div className="column col-6 col-md-12">
                            <ReactMarkdown children={lead + more}/>
                        </div>
                    </div>
                </div>
            ) : (
                <div className='container grid-sm article-body'>
                    <WineStoryTitle title={manufacturer.name || ""}/>
                    <ReactMarkdown children={lead + more}/>
                    <WineStorySignature manufacturer={manufacturer}/>
                </div>
            )}
            <section className="wine-story-wines container grid-xl">
                {manufacturer && manufacturer["@id"] && (
                    <LazyLoad once height={400}>
                        <RelatedWines
                            url={`${API_URL}/suggestions/manufacturers/${encodeBase64(manufacturer["@id"])}`}
                            exclude={""}
                        />
                    </LazyLoad>
                )}
            </section>
        </div>
    )
}

export default WineStoryPage
