import React, {FC, useEffect, useState} from "react"
import {useLocation} from "react-router-dom"
import ky from "ky"
import {useManyDocuments} from "../_services/mercury.service"
import {TypeFoodType} from "../_types/new-types"
import Loader from "../_components/Loader/Loader"
import ErrorPage from "../Error/ErrorPage"
import LocaleLink from "../_components/localization/LocaleLink"
import WinePairingStepper from "./WinePairingStepper"
import {useTranslation} from "react-i18next"
import {PAIRING_URL} from "../_helpers/environment"

const WinePairingFoodStyle: FC = () => {

    const {t} = useTranslation("store")
    const {search} = useLocation()
    const [loading, setLoading] = useState<boolean>(true)
    const [valid, setValid] = useState<string[]>([])
    const [error, setError] = useState<any>()
    let nextLink = "/food-match/dish" + search
    if (!search) {
        nextLink += "?"
    }
    useEffect(() => {
        ky.get(`${PAIRING_URL}/food-styles${search}`).json<{ "foodStyles": string[] }>()
            .then(result => setValid(result.foodStyles))
            .catch(err => setError(err))
            .finally(() => setLoading(false))
    }, [search])
    const foodTypes = useManyDocuments<TypeFoodType>("/food-types")
    if (loading || foodTypes.isLoading) {
        return <Loader/>
    }
    if (foodTypes.error || error) {
        return <ErrorPage error={error || foodTypes.error}/>
    }
    return (
        <>
            <div className="header-title">{t("foodMatch/headerFoodStyle")}</div>
            <div className="food-style-grid">
                {foodTypes.items.filter(ft => {
                    return valid.includes(ft["@id"])
                }).map(ft => (
                    <LocaleLink
                        className="food-style-tile"
                        key={ft.name}
                        to={nextLink + "&foodStyle=" + ft["@id"].split("/").pop()}
                    >
                        <span
                            className="tile-icon"
                            style={{"backgroundImage": `url("https://static.matthys-wines.com/images/prod/food-match/food-styles-v2/${ft.identifier}.svg")`}}
                        />
                        <span className="tile-label">{ft.name}</span>
                    </LocaleLink>
                ))}
            </div>
            <WinePairingStepper step={2}/>
        </>
    )
}

export default WinePairingFoodStyle
