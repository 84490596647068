import React, {FC, useEffect, useState} from "react"
import "./Footer.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faEnvelope, faParking, faPhone, faPhoneAlt} from "@fortawesome/free-solid-svg-icons"
import {getLanguages} from "../../_services/api.public.service"
import {useTranslation} from "react-i18next"
import LocaleLink from "../localization/LocaleLink"
import {faFacebookF, faInstagram} from "@fortawesome/free-brands-svg-icons"
import FormNewsletter from "../forms/FormNewsletter"
import {SchemaLanguage} from "../../_types/types"
import {useWindowWidth} from "@react-hook/window-size"

interface CollapsedState {
    collapsed1: boolean
    collapsed2: boolean
    collapsed3: boolean
    collapsed4: boolean
}

const Footer: FC = () => {

    const [collapsed, setCollapsed] = useState<CollapsedState>({
        "collapsed1": true,
        "collapsed2": true,
        "collapsed3": true,
        "collapsed4": true,
    })
    const {t} = useTranslation("common")

    const width = useWindowWidth()

    let isMobile = width < 840

    return (
        <footer>
            <div className={"container grid-xl"}>
                <div className='columns mb-2'>
                    <div className='column col-3 col-md-12 accordion'>
                        <input id="accordion-footer-1" type="checkbox" name="accordion-checkbox" hidden/>
                        <div
                            className={"h4 " + (isMobile && collapsed.collapsed1 ? "collapsed" : "")}
                            onClick={() => setCollapsed(s => ({...s, "collapsed1": !collapsed.collapsed1}))}
                        >Matthys Wines
                        </div>
                        <ul className={"nav collapsible " + (isMobile && collapsed.collapsed1 ? "collapsed" : "")}>
                            <li className='nav-item'><LocaleLink
                                to={"/articles/matthys-wines"}>{t("footer/matthysWines")}</LocaleLink></li>
                            <li className='nav-item'><LocaleLink to={"/horeca"}>{t("nav/horeca")}</LocaleLink></li>
                            <li className='nav-item'><LocaleLink
                                to={"/articles/glasses-service"}>{t("nav/glasses")}</LocaleLink>
                            </li>
                            <li className='nav-item'><LocaleLink to={"/articles/press"}>{t("nav/press")}</LocaleLink>
                            </li>
                            <li className='nav-item'><LocaleLink
                                to={"/articles/delivery"}>{t("nav/delivery")}</LocaleLink></li>
                            <li className='nav-item'><LocaleLink
                                to={"/articles/environment"}>{t("nav/environment")}</LocaleLink></li>
                            <li className='nav-item'><LocaleLink to={"/articles/faq"}>{t("nav/faq")}</LocaleLink></li>
                            <li className='nav-item'><LocaleLink to={"/contact"}>{t("nav/contact")}</LocaleLink>
                            </li>
                        </ul>
                    </div>
                    <div className='column col-3 col-md-12'>
                        <div
                            className={"h4 " + (isMobile && collapsed.collapsed2 ? "collapsed" : "")}
                            onClick={() => setCollapsed(s => ({...s, "collapsed2": !collapsed.collapsed2}))}
                        >{t("footer/discover")}</div>
                        <ul className={"nav collapsible " + (isMobile && collapsed.collapsed2 ? "collapsed" : "")}>
                            <li className='nav-item'><LocaleLink to={"/wines"}>{t("nav/wines")}</LocaleLink></li>
                            <li className='nav-item'><LocaleLink
                                to={"/food-match/start"}>{t("nav/foodMatch")}</LocaleLink>
                            </li>
                            <li className='nav-item'><LocaleLink
                                to={"/discovery-cases"}>{t("nav/discoveryCases")}</LocaleLink></li>
                            <li className='nav-item'><LocaleLink to={"/articles/events"}>{t("nav/events")}</LocaleLink>
                            </li>
                        </ul>
                    </div>
                    <div className='column col-3 col-md-12'>
                        <div
                            className={"h4 " + (isMobile && collapsed.collapsed3 ? "collapsed" : "")}
                            onClick={() => setCollapsed(s => ({...s, "collapsed3": !collapsed.collapsed3}))}
                        >{t("footer/contact")}</div>
                        <div
                            className={"collapsible " + (isMobile && collapsed.collapsed3 ? "collapsed" : "")}>
                            <p>{t("contact/form/name")}<br/>{t("contact/form/vat")}</p>
                            <p>
                                {t("contact/form/streetAddress")}
                                <FontAwesomeIcon icon={faParking} style={{"marginLeft": "0.2rem"}}/>
                                <br/>
                                {t("contact/form/postalCode")} {t("contact/form/city")}<br/>
                                {t("contact/form/country")}<br/>
                            </p>
                            <p>
                                <FontAwesomeIcon className="mr-2" icon={faPhoneAlt}/>
                                <a className="text-light" href="tel:+32050386380">+32 (0)50 38 63 80</a><br/>
                                <FontAwesomeIcon className="mr-2" icon={faEnvelope}/>
                                <a className="text-light"
                                   href="mailto:frank@matthys-wines.be">frank@matthys-wines.be</a>
                            </p>
                        </div>
                    </div>
                    <div className='column col-3 col-md-12'>
                        <div
                            className={"h4 " + (isMobile && collapsed.collapsed4 ? "collapsed" : "")}
                            onClick={() => setCollapsed(s => ({...s, "collapsed4": !collapsed.collapsed4}))}
                        >{t("footer/newsletter")}</div>
                        <div className={"collapsible " + (isMobile && collapsed.collapsed4 ? "collapsed" : "")}>
                            <FormNewsletter/>
                            <div className="text-light text-small mt-2">{t("footer/socialMedia")}</div>
                            <div className="foot-social">
                                <a
                                    href={t("link/facebook")}
                                    className="btn-social btn-facebook"
                                    title="Facebook"
                                ><FontAwesomeIcon icon={faFacebookF}/></a>
                                <a
                                    href={t("link/instagram")}
                                    className="btn-social btn-instagram"
                                    title="Instagram"
                                ><FontAwesomeIcon icon={faInstagram}/></a>
                                <a
                                    href={t("link/email")}
                                    className='btn-social btn-email'
                                    title="E-mail"
                                ><FontAwesomeIcon icon={faEnvelope}/></a>
                                <a
                                    href={t("link/phone")}
                                    className='btn-social btn-phone'
                                    title="Phone"
                                ><FontAwesomeIcon icon={faPhone}/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <LanguagePicker/>
                <hr style={{"margin": "0.75rem 0", "opacity": 0.4}}/>
                <div className='foot-text'>
                    <span style={{"paddingRight": "1.5rem"}}>© Copyright {(new Date()).getFullYear()} Matthys Wijnimport nv</span>
                    {isMobile ? <br/> : ""}
                    <LocaleLink to={"/articles/disclaimer"}>{t("footer/tc")}</LocaleLink>
                    <span className='foot-text-divider'>|</span>
                    <LocaleLink to={"/articles/privacy"}>{t("footer/privacy")}</LocaleLink>
                </div>
            </div>
        </footer>
    )
}

const LanguagePicker: FC = () => {

    const {i18n} = useTranslation()
    const [languages, setLanguages] = useState<SchemaLanguage[]>([])

    useEffect(() => {
        getLanguages().then(languages => setLanguages(languages))
    }, [])

    return (
        <div style={{"marginTop": "1rem"}}>
            {languages.filter(language => i18n.language !== language.alternateName).map(language => (
                <a className="text-gray mr-2" key={language.alternateName}
                   href={`/${language.alternateName}`}>{language.name}</a>
            ))}
        </div>
    )
}

export default Footer
