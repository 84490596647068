import {useEffect, useState} from "react"
import {SchemaOrderedItem, SchemaProduct} from "../_types/types"
import {serviceGetPromise} from "../_services/api.public.service"
import {useCart, useCartDispatch} from "../_contexts/CartContext"
import {cartRemoveItem} from "../_services/cart.service"

export type CartProductsWithTotal = {
    map: { [key: string]: SchemaProduct }
    total: number
    bottles: number,
    busy: boolean
    isValid: boolean
}

export const useProducts = (orderedItems: SchemaOrderedItem[]): { map: { [key: string]: SchemaProduct }, busy: boolean } => {

    const [loading, setLoading] = useState<boolean>(true)
    const [products, setProducts] = useState<SchemaProduct[]>([])
    const [productMap, setProductMap] = useState<{ [key: string]: SchemaProduct }>({})

    // Retrieve cart products
    useEffect(() => {
        if (orderedItems.length === 0) return
        setLoading(true)
        Promise.all<SchemaProduct>(orderedItems.map(item => serviceGetPromise<SchemaProduct>(item.orderedItem["@id"] || "")))
            .then(value => setProducts(value))
            .then(() => setLoading(false))
    }, [orderedItems])

    useEffect(() => {
        const pMap: { [key: string]: SchemaProduct } = {}
        products.forEach(product => {
            if (product["@id"])
                pMap[product["@id"]] = product
        })
        setProductMap(pMap)
    }, [products])

    return {
        "map": productMap,
        "busy": loading,
    }
}

export const useCartProducts = (): CartProductsWithTotal => {

    const cart = useCart()
    const dispatch = useCartDispatch()
    const [isValid, setIsValid] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)
    const [products, setProducts] = useState<SchemaProduct[] | null>(null)
    const [productMap, setProductMap] = useState<{ [key: string]: SchemaProduct }>({})

    // Retrieve cart products
    useEffect(() => {
        if (!cart.busy && !loading && products === null) {
            setLoading(true)
            Promise.all<SchemaProduct>(cart.items.map(item => serviceGetPromise<SchemaProduct>(item.product["@id"])))
                .then(value => setProducts(value))
                .then(() => setLoading(false))
        }
    }, [cart, loading, products])

    useEffect(() => {
        if (products === null) {
            return
        }
        const pMap: { [key: string]: SchemaProduct } = {}
        products.forEach(product => {
            if (product["@id"])
                pMap[product["@id"]] = product
        })
        setProductMap(pMap)
    }, [products])

    if (loading || products === null) {
        return {"total": 0, "busy": true, "bottles": 0, "map": {}, "isValid": true}
    }

    let total = 0
    let bottles = 0
    cart.items.forEach(item => {
        if (!item.product["@id"])
            return
        const product = productMap[item.product["@id"]]
        if (!product.offers || !product.offers.integerPrice || product.offers.availability !== "in-stock" || product.isArchived) {
            setIsValid(false)
            dispatch(cartRemoveItem({"@id": product["@id"] || ""}))
        } else {
            total += product.offers.integerPrice * item.quantity
        }

        if (product.weight && (product.weight.unitText === "XBO") && parseInt(product.weight.value)) {
            bottles += item.quantity * parseInt(product.weight.value)
        } else {
            bottles += item.quantity
        }
    })

    return {
        isValid,
        total,
        "map": productMap,
        bottles,
        "busy": loading,
    }
}
