import React, {FC, FunctionComponent} from "react"
import "./Account.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faBox, faDoorOpen, faUser} from "@fortawesome/free-solid-svg-icons"
import LocaleLink from "../_components/localization/LocaleLink"
import {IconProp} from "@fortawesome/fontawesome-svg-core"
import {useTranslation} from "react-i18next"
import {useAuth0} from "@auth0/auth0-react"
import i18next from "i18next"
import AccountWorldMap from "./_components/AccountWorldMap"
import AccountFavorites from "./_components/AccountFavorites"
import AccountHistory from "./_components/AccountHistory"
import "../WineCatalog/WineCatalogPage.scss"
import {useUserOrders} from "../_hooks/user.hook"

interface AccountTileProps {
    to: string
    icon: IconProp
    title: string
    text?: string
    onClick?: () => void
}

const AccountTile: FunctionComponent<AccountTileProps> = props => {

    const body = (
        <>
            <div className="acc-tile-icon text-center">
                <FontAwesomeIcon
                    className="acc-icon"
                    icon={props.icon}
                />
            </div>
            <div className="acc-tile-title text-center">
                {props.title}
            </div>
        </>
    )

    if (props.onClick) {
        return (
            <button className="account-tile" onClick={() => props.onClick ? props.onClick() : true}>
                {body}
            </button>
        )
    } else {
        return (
            <LocaleLink to={props.to} className="account-tile">
                {body}
            </LocaleLink>
        )
    }
}

const AccountPage: FC = () => {

    const {orders} = useUserOrders()
    const {t} = useTranslation("account")
    const {logout} = useAuth0()

    return (
        <div className="container grid-lg container-main header-margin" id="account">
            <h1 className="text-center">{t("account/title")}</h1>
            <AccountWorldMap orders={orders}/>
            <div className="account-tiles">
                <AccountTile
                    title={t("nav/orders")}
                    text={t("nav/orders/lead")}
                    icon={faBox}
                    to="/account/orders"
                />
                <AccountTile
                    title={t("nav/billingAndShipping")}
                    text={t("nav/billingAndShipping/lead")}
                    icon={faUser}
                    to="/account/billing-and-shipping"
                />
                <AccountTile
                    title={t("nav/signOut")}
                    icon={faDoorOpen}
                    to=""
                    onClick={() => logout({"returnTo": `${window.location.origin}/sign-out?lng=${i18next.language}`})}
                />
            </div>
            <AccountFavorites/>
            <AccountHistory orders={orders}/>
        </div>
    )
}

export default AccountPage
