import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import React, {FC} from "react"
import {useTranslation} from "react-i18next"
import FormInput from "../../../_components/form-inputs/FormInput"
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons"

interface Props {
    isGift: boolean,
    setGift: (isGift: boolean) => void
    giftText?: string
    setGiftText: (giftText: string) => void
    giftTelephone?: string
    setGiftTelephone: (giftTelephone: string) => void
}

const CheckoutGiftForm: FC<Props> = (props) => {
    const {t} = useTranslation("form")
    return (
        <div className="card-unit card-mb">
            <h4>{t("form/gift/title")}</h4>
            <div className="form-group">
                <label className="form-checkbox">
                    <input
                        type="checkbox"
                        onChange={() => props.setGift(!props.isGift)}
                        checked={props.isGift}
                    />
                    <i className="form-icon"/>
                    {t("label/sendAsGift")}
                </label>
            </div>
            {props.isGift ? (
                <>
                    <FormInput
                        id={"giftText"}
                        label={t("label/giftText")}
                        type={"textarea"}
                        maxlength={140}
                        className={"no-resize"}
                        value={props.giftText || ""}
                        onChange={v => props.setGiftText(v)}
                    />
                    <FormInput
                        id={"giftTelephone"}
                        label={t("label/telephone")}
                        placeholder={t("placeholder/telephone", "")}
                        type={"tel"}
                        value={props.giftTelephone}
                        onChange={v => props.setGiftTelephone(v)}
                        required={true}
                    />
                </>
            ) : null}
            <div style={{"color": "#777777"}}>
                <FontAwesomeIcon icon={faInfoCircle} className="mr-2"/>
                {t("label/giftDescription")}
            </div>
        </div>
    )

}

export default CheckoutGiftForm
