import React, {FC, useEffect, useState} from "react"
import {ComposableMap, Geographies, Geography} from "react-simple-maps"
import geo from "./geography.json"
import {useAppellations, useCountries, useWines} from "../../_hooks/product.hook"
import {SchemaGeoCoordinates, SchemaOrder} from "../../_types/types"
import LoaderSection from "../../_components/Loader/LoaderSection"

interface WorldMapChartProps {
    ids: string[]
}

const WorldMapChart: FC<WorldMapChartProps> = ({ids}) => {

    const {"list": wines} = useWines(200, ids)
    const {"map": appellationMap} = useAppellations()
    const {"map": countryMap} = useCountries()

    const [countryIds, setCountryIds] = useState<any[]>([])

    // retrieve countries
    useEffect(() => {
        const countryIds = new Set()
        wines.forEach(wine => {
            if (typeof wine.appellation?.["@id"] !== "undefined" && appellationMap[wine.appellation["@id"]]) {
                const appellation: SchemaGeoCoordinates = appellationMap[wine.appellation["@id"]]
                if (typeof appellation.addressCountry?.["@id"] === "string") {
                    countryIds.add(appellation.addressCountry["@id"])
                }
            }
        })
        setCountryIds(Array.from(countryIds))
    }, [appellationMap, wines, ids])

    const countryCodes: string[] = []
    countryIds.forEach(c => {
        if (countryMap[c]) {
            countryCodes.push(countryMap[c].alternateName)
        }
    })

    return (
        <div className="wine-map">
            <ComposableMap projection="geoMercator" width={1200} height={600}>
                <Geographies geography={geo} stroke="#FFF" strokeWidth={0.5}>
                    {({geographies}) =>
                        geographies.map(geo => {
                            const isHighlighted = countryCodes.indexOf(geo.properties.ISO_A2) !== -1
                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    fill={isHighlighted ? "#c04c18" : "#e6e6e6"}
                                />
                            )
                        })
                    }
                </Geographies>
            </ComposableMap>
        </div>
    )


}

const AccountWorldMap: FC<{ orders: SchemaOrder[] }> = ({orders}) => {

    const [wineHistory, setWineHistory] = useState<any[]>([])

    // collect wines
    useEffect(() => {
        const wineIds = new Set()
        orders.forEach(o => {
            o.orderedItem.forEach(item => {
                wineIds.add(item.orderedItem["@id"])
            })
        })
        setWineHistory(Array.from(wineIds).map((s) => typeof s === "string" ? s.split("/").pop() : ""))
    }, [orders])

    if (wineHistory.length === 0) return <LoaderSection height={480}/>

    return (
        <WorldMapChart ids={wineHistory}/>
    )
}

export default AccountWorldMap
