import React, {FC} from "react"

const LoaderSection: FC<{ height?: number, transparent?: boolean }> = ({height, transparent}) => {
    height = height || 320
    return (
        <div className="loader-section"
             style={{"height": height + "px", "backgroundColor": (transparent ? "rgba(0,0,0,0)" : undefined)}}>
            <div className="loader-breathing darker"/>
        </div>
    )
}

export default LoaderSection