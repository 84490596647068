import "react-app-polyfill/stable"
import React, {FC, Suspense, useEffect} from "react"
import ErrorBoundary from "./ErrorBoundary"
import {BrowserRouter as Router, NavigateFunction, Route, Routes, useNavigate} from "react-router-dom"
import Auth0App from "./Auth0App"
import Loader from "../_components/Loader/Loader"
import App from "./App"
import * as Sentry from "@sentry/browser"
import queryString from "query-string"
import {decodeBase64} from "../_helpers/encoding"
import "../index.scss"
import "../i18n"
import "spectre.css/dist/spectre.min.css"
import "spectre.css/dist/spectre-icons.min.css"
import "spectre.css/dist/spectre-exp.min.css"
import NotFoundCatch from "../_components/NotFound/NotFoundCatch"

// Use Sentry for error tracking
Sentry.init({"dsn": process.env.REACT_APP_SENTRY_DNS})

// Declare gtm dataLayer
declare global {
    interface Window {
        dataLayer: any;
    }
}

const onAuthenticatedCallback: (navigate: NavigateFunction) => void = (navigate) => {

    const query = queryString.parse(window.location.search)

    // Do redirect to previous flow
    let path = ""
    if (typeof query["r"] === "string") {
        path = decodeBase64(query["r"])
        delete query["r"]
    }

    const lng = (typeof query["lng"] === "string") ? query["lng"] : "en"

    delete query["state"]
    delete query["lng"]
    delete query["code"]

    // Set correct language
    navigate(`/${lng}${path}?${queryString.stringify(query)}`)
}

const AppSignOut: FC = () => {
    const navigate = useNavigate()
    useEffect(() => {
        onAuthenticatedCallback(navigate)
    }, [navigate])
    return <Loader/>
}

const AppRoot: FC = () => (
    <ErrorBoundary>
        <Router>
            <Auth0App callback={onAuthenticatedCallback}>
                <Suspense fallback={<Loader delay={2000}/>}>
                    <Routes>
                        <Route path={"/authorize"} element={<Loader/>}/>
                        <Route path={"/sign-out"} element={<AppSignOut/>}/>
                        <Route path={"/:lng/*"} element={<App/>}/>
                        <Route path={"/"} element={<NotFoundCatch/>}/>
                    </Routes>
                </Suspense>
            </Auth0App>
        </Router>
    </ErrorBoundary>
)

export default AppRoot
