// Retrieves browser languages based on user's system/browser language
import {SchemaLanguage} from "../_types/types"

export const getBrowserLanguage = (whitelist: SchemaLanguage[]) => {

    //@ts-ignore
    let detected = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage

    if (whitelist.map(l => l.alternateName).includes(detected.substring(0, 2).toLowerCase())) {
        return detected.substring(0, 2).toLowerCase()
    } else {
        return "en"
    }
}
