import {SchemaPostalAddress, SchemaPostalAddressExpanded} from "../_types/types"

export const compressAddress = (expanded: SchemaPostalAddressExpanded) => {
    return {
        "@type": expanded["@type"],
        "name": expanded.name,
        "alternateName": expanded.alternateName,
        "streetAddress": expanded.streetAddress + "    " + expanded.streetNumber,
        "addressLocality": expanded.addressLocality,
        "postalCode": expanded.postalCode,
        "addressCountry": expanded.addressCountry,
    } as SchemaPostalAddress
}

export const expandAddress = (compressed: SchemaPostalAddress) => {
    const streetParts = compressed.streetAddress.split("    ")
    return {
        "@type": compressed["@type"],
        "name": compressed.name,
        "alternateName": compressed.alternateName,
        "streetAddress": streetParts[0],
        "streetNumber": streetParts.length > 1 ? streetParts[1] : "",
        "addressLocality": compressed.addressLocality,
        "postalCode": compressed.postalCode,
        "addressCountry": compressed.addressCountry,
    } as SchemaPostalAddressExpanded
}

const normalizeSpace = (input: string): string => input.replace(/\s+/g, " ")

export const splitAddress = (address: string): { streetName: string, streetNumber: string } => {
    // Find the last occurrence of three consecutive spaces
    const lastIndex = address.lastIndexOf("   ") // Finds the last index of three spaces
    if (lastIndex === -1) {
        // No triple spaces found, treat entire address as street name
        return {"streetName": address, "streetNumber": ""}
    } else {
        // Split the address at the last occurrence of three spaces
        const streetName = address.substring(0, lastIndex)
        const streetNumber = address.substring(lastIndex + 3)
        return {streetName, streetNumber}
    }
}

export const replaceStreetName = (address: string, newStreetName: string): string => {
    const normalizedNewStreetName = normalizeSpace(newStreetName)
    const {streetNumber} = splitAddress(address)
    return `${normalizedNewStreetName}   ${streetNumber}`
}

export const replaceStreetNumber = (address: string, newStreetNumber: string): string => {
    const normalizedNewStreetNumber = normalizeSpace(newStreetNumber)
    const {streetName} = splitAddress(address)
    return `${streetName}   ${normalizedNewStreetNumber}`
}
