import React, {FC, useEffect, useState} from "react"
import {useLocation} from "react-router-dom"
import ky from "ky"
import {useManyDocuments} from "../_services/mercury.service"
import {TypePropertyValue} from "../_types/new-types"
import Loader from "../_components/Loader/Loader"
import ErrorPage from "../Error/ErrorPage"
import LocaleLink from "../_components/localization/LocaleLink"
import WinePairingStepper from "./WinePairingStepper"
import {useTranslation} from "react-i18next"
import LocaleRedirect from "../_components/localization/LocaleRedirect"
import {PAIRING_URL} from "../_helpers/environment"

const WinePairingAgriculture: FC = () => {

    const {t} = useTranslation("store")
    const {search} = useLocation()
    const [loading, setLoading] = useState<boolean>(true)
    const [valid, setValid] = useState<string[]>([])
    const [error, setError] = useState<any>()
    let nextLink = "/food-match/results" + search
    if (!search) {
        nextLink += "?"
    }

    useEffect(() => {
        ky.get(`${PAIRING_URL}/agriculture${search}`).json<{ "agriculture": string[] }>()
            .then(result => setValid(result.agriculture))
            .catch(err => setError(err))
            .finally(() => setLoading(false))
    }, [search])

    const foodTypes = useManyDocuments<TypePropertyValue>("/agriculture")

    if (loading || foodTypes.isLoading) {
        return <Loader/>
    }

    if (foodTypes.error || error) {
        return <ErrorPage error={error || foodTypes.error}/>
    }

    if (valid.length === 0) {
        return <LocaleRedirect to={"/food-match/start"}/>
    }

    return (
        <>
            <div className="header-title">{t("foodMatch/headerAgriculture")}</div>
            <div className="food-match-button-list agriculture-list">
                <LocaleLink className="btn-generic btn-inline" to={nextLink}>
                    {t("foodMatch/noPreference")}
                </LocaleLink>
                {foodTypes.items.filter(ft => {
                    return valid.includes(ft["@id"])
                }).map(ft => (
                    <LocaleLink
                        className="btn-generic btn-inline"
                        key={ft.value}
                        to={nextLink + "&agriculture=" + ft["@id"].split("/").pop()}
                    >
                        {ft.value}
                        <span className={"icon-agriculture-" + ft.identifier + " icon-agriculture"}/>
                    </LocaleLink>
                ))}
            </div>
            <WinePairingStepper step={5}/>
        </>
    )
}

export default WinePairingAgriculture
