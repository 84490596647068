import React, {FC, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import "./ErrorPage.scss"
import NotFoundCatch from "../_components/NotFound/NotFoundCatch"
import EmptyPage from "../Empty/EmptyPage"
import * as Sentry from "@sentry/browser"
import ExpectedError from "../Empty/ExpectedError"

const ErrorPage: FC<{ error: any, message?: string }> = ({error, message}) => {

    const {t} = useTranslation("error")
    const [bodyText, setBodyText] = useState<string>(t("UNKNOWN"))

    useEffect(() => {
        Sentry.captureException(error)
        if (error?.response?.body) {
            error.response.text()
                .then((message: string) => setBodyText(message))
        }
    }, [error])

    let text = message
    if (!text) {
        if (bodyText) {
            text = t(bodyText, t("UNKNOWN"))
        } else if (error) {
            text = error.toString()
        }
    }

    if (process.env.NODE_ENV === "development") {
        return (
            <ExpectedError title={"Generic"} description={text || "No message"}/>
        )
    }

    if ((error.response && error.response.status === 404) || error === 404)
        return <NotFoundCatch/>

    return (
        <EmptyPage
            title={t("page/title")}
            lead={text || t("UNKNOWN")}
            action={"/"}
            actionText={t("btn/home", {"ns": "common"})}
        />
    )
}

export default ErrorPage