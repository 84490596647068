import React, {FC, useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {SchemaOrder} from "../../_types/types"

const AccountHistory: FC<{ orders: SchemaOrder[] }> = ({orders}) => {

    const {t} = useTranslation("account")

    const [wineHistory, setWineHistory] = useState<any[]>([])

    // collect wines
    useEffect(() => {
        const wineIds = new Set()
        orders.forEach(o => {
            o.orderedItem.forEach(item => {
                wineIds.add(item.orderedItem["@id"])
            })
        })
        setWineHistory(Array.from(wineIds).map((s) => typeof s === "string" ? s.split("/").pop() : ""))
    }, [orders])

    if (orders.length === 0) return null

    return (
        <div>
            <h2 className="text-center" style={{"marginTop": "1rem"}}>{t("account/wineHistory", "Wine History")}</h2>
            <div>
                {/* TODO: add order history */}
            </div>
        </div>
    )
}

export default AccountHistory
