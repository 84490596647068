import React, {FunctionComponent} from "react"
import {Route, Routes} from "react-router-dom"
import NotFoundCatch from "../_components/NotFound/NotFoundCatch"
import CartEmptyPage from "../Empty/CartEmptyPage"
import AccountRoutes from "../Account/AccountRoutes"
import OrderPage from "../Order/OrderPage"
import HorecaRoute from "../Horeca/HorecaRoute"
import ContactPage from "../Contact/ContactPage"
import CartPage from "../Cart/CartPage"
import ArticlePage from "../Article/ArticlePage"
import CheckoutRoutes from "../Checkout/CheckoutRoutes"
import WineStoryPage from "../Article/WineStoryPage"
import EventRoutes from "../Event/EventRoutes"
import WikiPage from "../Article/WikiPage"
import Home from "../Home/Home"
import WineProductPage from "../Wine/WineProductPage"
import WineCasePage from "../Case/WineCasePage"
import WineCaseListPage from "../Case/WineCaseListPage"
import WinePairingPage from "../FoodMatch/WinePairingPage"
import WineCatalogPage from "../WineCatalog/WineCatalogPage"

const AppRoutes: FunctionComponent = () => (
    <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/account/*" element={<AccountRoutes/>}/>
        <Route path="/orders/:id" element={<OrderPage/>}/>
        <Route path={"/wines/:id"} element={<WineProductPage/>}/>
        <Route path={"/wines"} element={<WineCatalogPage/>}/>
        <Route path={"/discovery-cases/:id"} element={<WineCasePage/>}/>
        <Route path={"/discovery-cases"} element={<WineCaseListPage/>}/>
        <Route path="/food-match/*" element={<WinePairingPage/>}/>
        <Route path="/horeca/*" element={<HorecaRoute/>}/>
        <Route path="/contact" element={<ContactPage/>}/>
        <Route path="/articles/:id" element={<ArticlePage/>}/>
        <Route path="/wiki/:id" element={<WikiPage/>}/>
        <Route path="/events/*" element={<EventRoutes/>}/>
        <Route path="/wine-stories/:id" element={<WineStoryPage/>}/>
        <Route path="/checkout/*" element={<CheckoutRoutes/>}/>
        <Route path="/cart/empty" element={<CartEmptyPage/>}/>
        <Route path="/cart" element={<CartPage/>}/>
        <Route path="/*" element={<NotFoundCatch/>}/>
    </Routes>
)

export default AppRoutes
