import {API_URL} from "../_helpers/environment"
import ky from "ky"
import {SchemaLanguage} from "../_types/types"
import {localizationHeaders} from "../_helpers/auth-header"
import {Mutex} from "async-mutex"
import i18next from "i18next"
import {TypeCollection, TypeThing} from "../_types/new-types"

const documentCache: { [key: string]: any } = {}
const requestQueue: { [key: string]: Mutex } = {}

/**
 *
 * @param endpoint
 * @param pending
 * @param success
 * @param failure
 */
export const serviceGet = <T extends any>(endpoint: string, pending: (() => void) | null, success: (data: any) => void, failure: (error: any) => void) => {
    if (endpoint.indexOf("/v1/documents") !== -1) {
        endpoint = endpoint.replace("/v1/documents", "/documents")
    }
    if (endpoint.indexOf("https://api.matthys-wines.com") !== -1 && API_URL.indexOf("api.matthys-wines.com") === -1) {
        endpoint = endpoint.replace("https://api.matthys-wines.com", API_URL)
    }

    if (requestQueue[endpoint] === undefined)
        requestQueue[endpoint] = new Mutex()

    requestQueue[endpoint].acquire()
        .then(release => {
            pending && pending()
            mercuryGet(endpoint)
                .then(result => success(result))
                .catch(error => failure(error))
                .finally(() => release())
        })
        .catch(error => failure(error))
}

export const mercuryGet = async <T extends TypeThing>(endpoint: string): Promise<TypeCollection<T>> => {

    // Use cached document endpoints and check if cached version exists
    const useCache = endpoint.indexOf(`${API_URL}/documents`) !== -1 || endpoint === `${API_URL}/localization/languages`
    const uniqueEndpoint = endpoint
    if (useCache && documentCache[endpoint]) {
        return documentCache[endpoint]
    }

    if (endpoint.indexOf("?") !== -1) {
        endpoint = endpoint + `&language=${i18next.language}`
    } else {
        endpoint = endpoint + `?language=${i18next.language}`
    }

    if (useCache) {
        let page = 0
        let results: T[] = []
        let included: any[] = []
        while (true) {
            const result = await ky.get(`${endpoint}&page[size]=100&page[number]=${page}`)
            const linkHeader = result.headers.get("Link")
            const body = await result.json<any>()
            if (body["@graph"]) {
                results = [...results, ...body["@graph"]]
                if (body["@included"] !== undefined)
                    included = [...included, ...body["@included"]]
            } else {
                documentCache[endpoint] = body
                return body
            }
            if (linkHeader !== null && linkHeader.indexOf("next") !== -1) {
                page += 1
            } else {
                break
            }
        }
        documentCache[uniqueEndpoint] = {"@graph": results, "@included": included}
        return {"@graph": results, "@included": included}
    } else {
        return await ky.get(endpoint, {"headers": localizationHeaders()}).json()
    }

}

export const serviceGetPromise = async <T>(endpoint: string) => {
    return new Promise<T>(((resolve, reject) => {
        serviceGet<T>(endpoint, null, resolve, reject)
    }))
}

interface LanguagesResponse {
    "@graph": SchemaLanguage[]
}

export const getLanguages = (): Promise<SchemaLanguage[]> => {
    return serviceGetPromise<LanguagesResponse>(`${API_URL}/localization/languages`).then(a => a["@graph"])
}
