import React, {FC, useState} from "react"
import {SchemaOrder} from "../../../_types/types"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons"
import {useTranslation} from "react-i18next"

const CheckoutSummary: FC<{ order: SchemaOrder }> = ({order}) => {

    const {t} = useTranslation("checkout")

    const shippingCost = order.orderDelivery.deliveryCost || 0
    const totalCost = parseInt(order.acceptedOffer?.price.replace(".", "") || "0")

    const [collapsed, setCollapsed] = useState<boolean>(true)

    return (
        <div className="card-unit card-mb">
            <h4>{t("overview/title")}</h4>
            <table className="order-overview">
                <tbody>
                <tr>
                    <td>
                        {t("overview/items")}
                        <button className="btn btn-link btn-text" style={{"fontSize": "0.6rem", "verticalAlign": "1px"}}
                                onClick={() => setCollapsed(!collapsed)} type="button">
                            {collapsed ? (
                                <FontAwesomeIcon icon={faPlus} className="ml-2"/>
                            ) : (
                                <FontAwesomeIcon icon={faMinus} className="ml-2"/>
                            )}
                        </button>
                    </td>
                    <td>&euro; {((totalCost - shippingCost) / 100).toFixed(2)}</td>
                </tr>
                {!collapsed ? (
                    <>
                        {order.orderedItem.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td className="pl-2">{item.name} {item.orderQuantity}x</td>
                                    <td/>
                                </tr>
                            )
                        })}
                    </>
                ) : null}
                {order.orderDelivery?.hasDeliveryMethod?.["@type"] !== "OnSitePickup" ? (
                    <tr>
                        <td>{t("price/shipping")}</td>
                        {shippingCost ? (
                            <td>&euro; {(shippingCost / 100).toFixed(2)}</td>
                        ) : (
                            <td>{t("price/free")}</td>
                        )}
                    </tr>
                ) : null}
                <tr>
                    <td>{t("price/total")}</td>
                    <td>&euro; {(totalCost / 100).toFixed(2)}</td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default CheckoutSummary