import React, {FC} from "react"
import {Route, Routes} from "react-router-dom"
import HorecaPage from "./HorecaPage"
import HorecaFormPage from "./HorecaFormPage"
import NotFoundCatch from "../_components/NotFound/NotFoundCatch"

const HorecaRoute: FC = () => (
    <Routes>
        <Route path="/form" element={<HorecaFormPage/>}/>
        <Route path="/*" element={<HorecaPage/>}/>
        <Route element={<NotFoundCatch/>}/>
    </Routes>
)

export default HorecaRoute