import React, {FC} from "react"
import {SchemaPostalAddress} from "../../../_types/types"
import {useTranslation} from "react-i18next"
import FormInput from "../../../_components/form-inputs/FormInput"
import {getName} from "country-list"
import {replaceStreetName, replaceStreetNumber, splitAddress} from "../../../_helpers/address-format"

interface Props {
    address: SchemaPostalAddress,
    onChange: (modifier: (prev: SchemaPostalAddress) => SchemaPostalAddress) => void
    hidden: number
    setHidden: (v: number) => void
}

const CheckoutShippingForm: FC<Props> = ({address, onChange, hidden, setHidden}) => {

    const {t} = useTranslation("form")

    return (
        <div className="card-unit card-mb">
            <h4>{t("form/shipping/title")}</h4>
            <div className="form-group">
                <label className="form-radio">
                    <input
                        type="radio"
                        name="shipping"
                        checked={Boolean(hidden)}
                        onChange={e => setHidden(e.target.checked ? 1 : 0)}
                    />
                    <i className="form-icon"/> {t("label/shipToMyBillingAddress")}
                </label>
                <label className={`form-radio ${hidden ? "" : "mb-0"}`}>
                    <input
                        type="radio"
                        name="shipping"
                        checked={Boolean(!hidden)}
                        onChange={e => setHidden(!e.target.checked ? 1 : 0)}
                    />
                    <i className="form-icon"/> {t("label/useADifferentAddressForShipping")}
                </label>
            </div>
            {!hidden ? (
                <>
                    <FormInput
                        label={t("label/addressee")}
                        type={"text"}
                        id={"shippingAddressee"}
                        value={address.name}
                        onChange={v => onChange(prev => ({...prev, "name": v}))}
                        required={true}
                        placeholder={t("placeholder/addressee", "")}
                    />
                    <FormInput
                        label={t("label/organization")}
                        type={"text"}
                        id={"shippingOrganization"}
                        value={address.alternateName}
                        onChange={v => onChange(prev => ({...prev, "alternateName": v}))}
                        placeholder={`${t("placeholder/company", "")} (${t("placeholder/optional")})`}
                    />
                    <div className="columns">
                        <div className="column col-8 col-md-12">
                            <FormInput
                                label={t("label/streetName")}
                                type={"text"}
                                id={"shippingStreetName"}
                                value={splitAddress(address.streetAddress).streetName}
                                onChange={v => onChange(prev => ({
                                    ...prev,
                                    "streetAddress": replaceStreetName(prev.streetAddress, v),
                                }))}
                                required={true}
                                placeholder={t("placeholder/streetName", "")}
                            />
                        </div>
                        <div className="column col-4 col-md-12">
                            <FormInput
                                label={t("label/streetNumber")}
                                type={"text"}
                                id={"shippingStreetNumber"}
                                value={splitAddress(address.streetAddress).streetNumber}
                                onChange={v => onChange(prev => ({
                                    ...prev,
                                    "streetAddress": replaceStreetNumber(prev.streetAddress, v),
                                }))}
                                required={true}
                                placeholder={t("placeholder/streetNumber", "")}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column col-6 col-md-12">
                            <FormInput
                                label={t("label/city")}
                                type={"text"}
                                id={"shippingCity"}
                                value={address.addressLocality}
                                onChange={v => onChange(prev => ({...prev, "addressLocality": v}))}
                                required={true}
                                placeholder={t("placeholder/addressLocality", "")}
                            />
                        </div>
                        <div className="column col-6 col-md-12">
                            <FormInput
                                label={t("label/postalCode")}
                                type={"text"}
                                id={"shippingPostalCode"}
                                value={address.postalCode}
                                onChange={v => onChange(prev => ({...prev, "postalCode": v}))}
                                required={true}
                                placeholder={t("placeholder/postalCode", "")}
                            />
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column col-6 col-md-12">
                            <div className="form-group">
                                <label className="form-label" htmlFor="shippingCountry">
                                    {t("label/country")}
                                </label>
                                <input
                                    className="form-input disabled"
                                    type="text"
                                    disabled={true}
                                    value={getName(address.addressCountry) || ""}
                                    id={"shippingCountry"}
                                />
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </div>
    )

}

export default CheckoutShippingForm