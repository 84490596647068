import React, {FC, useContext, useEffect, useState} from "react"
import {serviceCartFetchItems, serviceCartGetId, serviceCartUploadItem} from "../_services/cart.service"
import {dispatchPlaceholder, DispatchType} from "./dispatch"
import {SchemaCartItem} from "../_types/types"
import * as Sentry from "@sentry/browser"

export interface CartState {
    busy: boolean
    waiting: boolean
    items: SchemaCartItem[]
    queue: SchemaCartItem[]
    error?: any
}

export const defaultCartState: CartState = {
    "busy": false,
    "waiting": false,
    "items": [],
    "queue": [],
}

export const CartStateContext = React.createContext<CartState>(defaultCartState)
export const CartDispatchContext = React.createContext<DispatchType<CartState>>(dispatchPlaceholder)

export const useCart = () => useContext(CartStateContext)
export const useCartDispatch = () => useContext(CartDispatchContext)

export const CartWrapper: FC<{ children?: React.ReactNode }> = ({children}) => {

    // Define states
    const [cartState, cartDispatch] = useState<CartState>({...defaultCartState, "busy": true})

    // Fetch existing cart if exists in storage
    useEffect(() => {
        serviceCartFetchItems().then(items => cartDispatch(s => ({...s, items, "busy": false})))
    }, [])

    // Update server side cart when items change
    useEffect(() => {
        if (cartState.queue.length === 0) return
        serviceCartGetId()
            .then(id => {
                const queue = [...cartState.queue]
                serviceCartUploadItem(id, queue.splice(0, 1)[0])
                    .catch(error => {
                        cartDispatch(s => ({...s, error}))
                        Sentry.captureException(error)
                    })
                cartDispatch(s => ({...s, queue}))
            })
            .catch(error => {
                cartDispatch(state => ({...state, error}))
                Sentry.captureException(error)
            })
    }, [cartState.queue])

    return (
        <CartDispatchContext.Provider value={cartDispatch}>
            <CartStateContext.Provider value={cartState}>
                {children}
            </CartStateContext.Provider>
        </CartDispatchContext.Provider>
    )
}