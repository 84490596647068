import React, {FC} from "react"
import {useManyDocuments} from "../_services/mercury.service"
import {TypeCategory} from "../_types/new-types"
import Loader from "../_components/Loader/Loader"
import ErrorPage from "../Error/ErrorPage"
import LocaleLink from "../_components/localization/LocaleLink"
import WinePairingStepper from "./WinePairingStepper"
import {useTranslation} from "react-i18next"

interface WineStyleButtonProps {
    label: string
    target: string
    className: string
}

const WineStyleButton: FC<WineStyleButtonProps> = ({label, target, className}) => (
    <LocaleLink to={target} className={`btn-wine-style ${className}`}>
        {label}
    </LocaleLink>
)

const WinePairingWineStyle: FC = () => {

    const {t} = useTranslation("store")

    // fetch categories
    const categories = useManyDocuments<TypeCategory>("/categories")

    if (categories.isLoading) {
        return <Loader/>
    }

    if (categories.error) {
        return <ErrorPage error={categories.error}/>
    }

    let white: TypeCategory | null = null
    let red: TypeCategory | null = null

    categories.items.forEach(cat => {
        if (cat.identifier === "white") {
            white = cat
        } else if (cat.identifier === "red") {
            red = cat
        }
    })

    const whiteIncludes: string[] = []
    const redIncludes: string[] = []
    categories.items.forEach(cat => {
        if (!cat.parent) {
            return
        }
        if (cat.parent["@id"] === white?.["@id"]) {
            whiteIncludes.push(cat["@id"].split("/").pop() || "")
        } else if (cat.parent["@id"] === red?.["@id"]) {
            redIncludes.push(cat["@id"].split("/").pop() || "")
        }

    })

    return (
        <>
            <div className="header-title">{t("foodMatch/headerWineStyle")}</div>
            <div className="quiz-actions">
                <WineStyleButton
                    label={t("foodMatch/noPreference")}
                    className={"style-any"}
                    target={"/food-match/price-range"}
                />
                <WineStyleButton
                    label={t("foodMatch/whiteWine")}
                    className={"style-white"}
                    target={"/food-match/price-range?wineStyle=" + whiteIncludes.join(",")}
                />
                <WineStyleButton
                    label={t("foodMatch/redWine")}
                    className={"style-red"}
                    target={"/food-match/price-range?wineStyle=" + redIncludes.join(",")}
                />
            </div>
            <WinePairingStepper step={0}/>
        </>
    )
}

export default WinePairingWineStyle
