import React, {FunctionComponent} from "react"

interface Props {
    label: string
    value: boolean
    onChange: (value: boolean) => void
    required?: boolean
}

/**
 * Basic checkbox component
 */
const FormCheckbox: FunctionComponent<Props> = props => (
    <label className="form-checkbox">
        <input
            required={props.required}
            type="checkbox"
            checked={props.value}
            onChange={e => props.onChange(e.target.checked)}
        />
        <i className="form-icon"/> {props.label}
    </label>
)

export default FormCheckbox